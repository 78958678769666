import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';
import {
  or,
  equal,
  alias
} from 'ember-decorators/object/computed';
import {
  toTitleCase,
  lowerCaseAndDasherized
} from 'b5b/utils';
import Self from 'b5b/models/experience';
import {
  memberAction
} from 'ember-api-actions';

export default DS.Model.extend(HasKodakImage, {
  name: DS.attr(),
  internalDescription: DS.attr('string'),
  shortName: DS.attr(),
  published: DS.attr(),
  publishedForWaybird: DS.attr(),
  iconName: DS.attr(),
  super: DS.attr(),
  metaImagesCount: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),
  destinationName: DS.attr(),
  metaImages: DS.attr(),
  region: DS.belongsTo('region', {
    inverse: null,
    async: false
  }),
  country: DS.belongsTo('country', {
    inverse: null,
    async: false
  }),
  experienceInfo: DS.belongsTo('experienceInfo', {
    async: true,
    inverse: null
  }),
  //originalEntity currently not serialised back from server. This is used to save relationship to server
  originalEntity: DS.belongsTo('experience', {
    inverse: null,
    async: false
  }),
  @alias('experienceInfo') entityInfo: null,
  @alias('name') displayName: null,
  teaserText: DS.attr(),
  friendlyId: DS.attr(),
  standaloneQuote: DS.attr(),
  whenToGo: DS.attr(),

  polishLevel: DS.attr('string'),
  userOwnerId: DS.attr('string'),
  userOwnerName: DS.attr('string'),
  shareWithAgency: DS.attr(),
  isArchived: DS.attr(),

  isRegional: DS.attr('boolean'),
  isCategory: DS.attr('boolean'),
  isActivity: DS.attr('boolean'),
  isJustImage: DS.attr('boolean'),
  isTripType: DS.attr('boolean'),
  isLodgeType: DS.attr('boolean'),
  experiences: DS.hasMany('experience', {
    inverse: null
  }),

  metaRegionalExperiencesImagesCount: DS.attr(),

  experienceTags: DS.attr(),

  longitude: DS.attr('string'),
  latitude: DS.attr('string'),
  fromPrice: DS.attr(),
  fromPriceCurrency: DS.attr(),
  fromPriceRateType: DS.attr(),

  replaceImages: DS.attr(),
  replaceImagesVersion: DS.attr(),
  metaImageUsedAsKodak: DS.attr(),

  specifyReplaceImages: memberAction({
    path: 'specify_replace_images'
  }),

  @equal('polishLevel', 'polished') isPolished: false,
  @equal('polishLevel', 'custom') isCustom: false,

  @computed('friendlyId')
  seoName(friendlyId) {
    return toTitleCase(friendlyId.replace(/-/g, " "));
  },

  @computed('region')
  isRegionalExperience() {
    return this.belongsTo('region').id() && true;
  },

  @computed('iconName')
  iconClassName(iconName) {
    return lowerCaseAndDasherized(`activities--${iconName}`);
  },

  @computed('friendlyId', 'id')
  experienceFriendlyId(friendlyId, id) {
    return friendlyId || id;
  },

  @computed('region', 'kodakOriginalUrl', 'country')
  heroEntity(region, kodakOriginalUrl, country) {
    if (kodakOriginalUrl) {
      return this;
    }

    if (region && region.get('hasKodakImage')) {
      return region;
    }

    if (country && country.get('hasKodakImage')) {
      return country;
    }

  },

  @computed('latitudeWithFallback', 'longitudeWithFallback')
  mapMarker(latitude, longitude) {
    if (!latitude || !longitude) {
      return;
    }

    return [
      [parseFloat(longitude).toFixed(6), parseFloat(latitude).toFixed(6)]
    ];
  },

  @computed()
  mapEntity(latitude, longitude) {
    return this;
  },

  @computed('latitude', 'longitude')
  isValidMapLocation(lat, long) {
    return !isNaN(lat) && !isNaN(long)
  },

  @or('latitude', 'region.latitude') latitudeWithFallback: null,
  @or('longitude', 'region.longitude') longitudeWithFallback: null,

  makeCopy() {

    let copy = this.get('store').createRecord('experience');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })

    copy.set('region', this.get('region'))
    copy.set('country', this.get('country'))
    return copy;
  }

});
