import {
  inject as service
} from '@ember/service';
import EmberRouter from '@ember/routing/router';
import config from 'b5b/config/environment';
import HistoryLocation from '@ember/routing/history-location';


HistoryLocation.reopen({
  historyService: service(),
  router: service(),

  pushState() {
    this._super(...arguments);

    this.get('historyService').onPushState(this._historyState.uuid);

  },

  onUpdateURL(callback) {
    this._removeEventListener();

    this._popstateHandler = (e) => {
      // Ignore initial page load popstate event in Chrome
      if (!this.popstateFired) {
        this.popstateFired = true;
        if (this.getURL() === this._previousURL) {
          return;
        }
      }

      this.get('historyService').onUpdateURL(e, this.getURL());
      callback(this.getURL());
    };

    window.addEventListener('popstate', this._popstateHandler);
  }

});


const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,

  ui: service(),
  historyService: service(),

  haveCheckedPermanentSuspensionRoutes: false,

  /*
    If you start on a suspension route we just need to initiate it, eg trip.index.editor
  */

  checkSuspensionRoute: function() {
    if (!this.haveCheckedPermanentSuspensionRoutes) {
      this.historyService.onPushState();
      this.set('haveCheckedPermanentSuspensionRoutes', true);
    }
  }.on('didTransition')

});

Router.map(function() {
  this.route('inspire');
  this.route('start');
  this.route('about');
  this.route('create');
  this.route('africa-safari');

  this.route('wizard', function() {
    this.route('start');
    this.route('index', {
      path: ''
    });
    this.route('step', {
      path: '/step/:step_id'
    })
    this.route('success', {
      path: '/:wizard_submission_id/success'
    });
  });

  this.route('trip', function() {
    this.route('new');
    this.route('index', {
      path: '/:tripFriendlyId'
    }, function() {
      this.route('when-to-go');
      this.route('editor');
      this.route('video');
      this.route('gift');
      this.route('reserve');
      this.route('login');
      this.route('itinerary', {
        path: '/itinerary/:itinerary_id'
      }, function() {});
      this.route('save-itinerary');

      this.route('enquire');
      this.route('enquiry-success');
      this.route('quote');
      this.route('login');
      this.route('deposit-success');
      // for redirect only
      this.route('status', function() {
        this.route('receipt', {
          path: '/receipt/:charge_id'
        });
        this.route('invoice');
      });
      this.route('dashboard', function() {
        this.route('guest-details', function() {
          this.route('guest', {
            path: '/guest/:guest_id'
          });
        });
        this.route('receipt', {
          path: '/receipt/:charge_id'
        });
        this.route('invoice');
        this.route('documents');
      });
      this.route('edit', function() {
        this.route('brochure');
        this.route('images');
        this.route('video');
        this.route('analytics');
        this.route('itinerary');
        this.route('guests');
        this.route('quote-details');
        this.route('pricing', function() {
          this.route('line-items');
          this.route('discounts');
          this.route('payments');
          this.route('summary');
        });
        this.route('summary');
        this.route('rates');
        this.route('internal-notes');
        this.route('tools');
      });
      this.route('brochure');
      this.route('pay', function() {
        this.route('success');
        this.route('error');
        this.route('processing');
        this.route('stripe-payment-processed');
      });
      this.route('contribute', function() {
        this.route('success');
        this.route('error');
        this.route('processing');
      });

      ;
      this.route('payment-processed', {
        path: '/payment-processed/:payment_id'
      }, function() {
        this.route('success');
        this.route('cancel');
        this.route('error');
      });
      this.route('charge-processed', {
        path: '/charge-processed/:charge_id'
      });

    });
  });


  this.route('country', {
    path: '/country/:countryFriendlyId'
  }, function() {
    this.route('index', {
      path: ''
    }, function() {
    });
    this.route('regions');
    this.route('when-to-go');
    this.route('lodges');
    this.route('routes');
    this.route('experiences');
    this.route('articles');
    this.route('start');
  });


  this.route('region', {
    path: '/region/:regionFriendlyId'
  }, function() {
    this.route('index', {
      path: ''
    }, function() {
    });
    this.route('start');
    this.route('regions');
    this.route('lodges');
    this.route('routes');
    this.route('experiences');
    this.route('when-to-go');
    this.route('articles');
  });


  this.route('lodge', {
    path: '/lodge/:lodgeFriendlyId'
  }, function() {
    this.route('index', {
      path: ''
    });
    this.route('when-to-go');
    this.route('routes');
    this.route('experiences');
    this.route('offers');
    this.route('start');
  });

  this.route('when-to-go', function() {
    this.route('index', {
      path: ''
    });
    this.route('month', {
      path: '/month/:monthFriendlyId'
    }, function() {
      this.route('index', {
        path: ''
      });
      this.route('countries');
      this.route('routes');
      this.route('start');
    });
  });

  this.route('experience', {
    path: '/experience/:experienceFriendlyId'
  }, function() {
    this.route('index', {
      path: ''
    });
    this.route('routes');
    this.route('experiences');
    this.route('articles');
    this.route('start');
  });

  this.route('regions');

  this.route('users', function() {
    this.route('profile');
    this.route('wish-list');
    this.route('my-trips');
  });

  // this.route('trip', function() {
  //   this.route('new');
  //   this.route('index', {
  //     path: '/:tripFriendlyId'
  //   }, function() {
  //     this.route('when-to-go');

  this.route('deal', function() {
    this.route('new');
    this.route('index', {
      path: '/:deal_id'
    }, function() {
        this.route('edit', function() {
          this.route('details');
          this.route('trips');
          this.route('guests');
        });
      });

  });
  this.route('deals');

  this.route('library', {
    path: '/library'
  }, function() {
    this.route('index', {
      path: ''
    });
    this.route('countries');
    this.route('experiences');
    this.route('regions');
    this.route('lodges');
    this.route('templates');
  });

  this.route('login');
  this.route('sign-up');
  this.route('join');
  this.route('password-reset');
  this.route('change-password');

  this.route('unknown-url', {
    path: '/*unknown-url'
  });

  this.route('site-map');

  this.route('region-experiences');
  this.route('experiences');

  this.route('reset');
  // this.route('compare');
  this.route('ask');

  this.route('trips');
  this.route('lodges');
  this.route('countries');

  this.route('info');
  this.route('email-signatures');
  this.route('phone-numbers');
  this.route('message-success');
  this.route('contact-us');
  this.route('open-for-travel');
  this.route('reviews');
  this.route('iframe-embed');
  this.route('join-newsletter');
  this.route('waybird', function() {
    this.route('register-account');
    this.route('account', {
      path: '/account/:domain'
    }, function() {
      this.route('register-user')
      this.route('created')
    })
  });

});

export default Router;
