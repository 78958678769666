import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import {
  getCookieValue,
  trackEvent,
  captureEmail,
  getErrorsHashFromServerResponse
} from 'b5b/utils';
import TripSaver from 'b5b/mixins/trip-saver';


/*
  Note:

  Should probably refactor this component into a base component and extended /general and /trip
  subcomponents like wizard.
*/


export default Component.extend(TripSaver, {
  classNames: ['t-enquire'],

  settings: service(),
  store: service(),
  session: service(),
  ui: service(),
  router: service(),
  config: service(),

  trip: null,
  @alias('config.enquiryData') enquiryData: null,

  showErrors: false,
  showingChildrenOptions: false,

  bookedFlights: null,
  possibleBudget: null,

  note: null,
  destination: null,

  startDate: null,
  startMonth: null,
  startYear: null,

  // Power-selects seem to be unable to use integer 0 as a selected value by default
  guestAdults: '2',
  guestChildren: '0',
  guestInfants: '0',

  possibleAdults: ['1', '2', '3', '4', '5', '6'],
  possibleRooms: [1, 2, 3, 4, 5, 6],
  possibleYoungAdults: ['0', '1', '2', '3', '4', '5', '6'],
  possibleChildren: ['0', '1', '2', '3', '4', '5', '6'],
  possibleInfants: ['0', '1', '2', '3', '4', '5', '6'],
  possibleNights: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  possibleMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

  @computed()
  possibleYears() {
    let currentYear = new Date().getFullYear(),
      years = [];

    for (var i = currentYear; i <= (currentYear + 5); i++) {
      years.pushObject(i);
    }

    return years;
  },

  didReceiveAttrs(){
    this._super(...arguments);

    if (this.trip) {
      this.set('startDate', this.trip.itinerary.startDate);
      // None of these can be set anywhere in the app ... Only the q
      // this.set('startMonth', this.trip.itinerary.startMonth);
      // this.set('startYear', this.trip.itinerary.startYear);
      this.set('guestAdults', this.trip.itinerary.numAdults);
      this.set('guestChildren', this.trip.itinerary.numChildren);
      this.set('guestInfants', this.trip.itinerary.numInfants);
    }
  },

  // could potentially differentiate between a trip and general enquiry but not setting the possible guests and dates in a trip enquiry since it is already set on the trip object
  setUpEnquiry(){
    var email = this.email ? this.email : this.session.get('currentUser.email');
    var firstName = this.firstName ? this.firstName : this.session.get('currentUser.name');
    var surname = this.surname ? this.surname : this.session.get('currentUser.surname');

    // HubspotTripEnquiryWorker assumes that enquiry is populated with user details, won't check for user creating enquiry

    var enquiry = this.store.createRecord('enquiry', {
      email: email,
      firstName: firstName,
      surname: surname,
      phoneNumber: this.phoneNumber,
      bookedFlights: this.bookedFlights,
      budget: this.possibleBudget,
      startDate: this.startDate,
      startMonth: this.startMonth,
      startYear: this.startYear,
      numAdults: parseInt(this.guestAdults),
      numChildren: parseInt(this.guestChildren),
      numInfants: parseInt(this.guestInfants),
      user: this.session.get('currentUser'),
      message: this.note,
      moreInfo: this.moreInfo,
      destination: this.destination,
      geoCode: getCookieValue('Timbuktu-Country-Code'),
      enquiryType: this.enquiryType,
    });

    if (this.enquiryData) {
      if (this.enquiryData.url) {
        enquiry.set('originUrl', this.enquiryData.url);
      }
      if (this.enquiryData.info) {
        enquiry.set('info', this.enquiryData.info);
      }
    }

    return enquiry;
  },

  enquireTrip(){
    var enquiry = this.setUpEnquiry();
    captureEmail(enquiry.email, 'trip.index.enquire', 'enquire', enquiry.firstName);

    var trip = this.trip;

    trip.set('itinerary.startDate', this.startDate);
    trip.set('itinerary.startMonth', this.startMonth);
    trip.set('itinerary.startYear', this.startYear);

    trip.set('itinerary.numAdults', parseInt(this.guestAdults));
    trip.set('itinerary.numChildren', parseInt(this.guestChildren));
    trip.set('itinerary.numInfants', parseInt(this.guestInfants));

    trip.set('itinerary.enquiry', enquiry);
    enquiry.set('itinerary', trip.get('itinerary'));

    trip.get('itinerary').set('state', 'enquiry');

    this.set('showErrors', false);
    this.set('errors', null);
    this.set('submittingEnquiry', true);

    return this.saveTrip(trip, {
      blockFailureToSaveReporting: true
    }).then((trip) => {
      if (window && typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Lead');
      }

      this.get('router').transitionTo('trip.index.enquiry-success', trip);
      trackEvent('trip:enquire:complete');
      window.timbuktu.reportConversions.reportTripEnquiry();
    }).catch((serverResponse) => {

      this.get('flashMessages')
        .danger('Please supply all the required information', {
          timeout: 4000,
          sticky: false
        });
      trackEvent('trip:enquire:validation:failed');

      this.set('submittingEnquiry', false);
      this.set('showErrors', true);
      let errors = getErrorsHashFromServerResponse(serverResponse)
      this.set('errors', errors);

      if (this.get('session.currentUser.isManager') || this.get('session.currentUser.isTimbuktu')) {
        let message = '';
        Object.keys(errors).forEach((key) => {
          message += key + ': ' + errors[key] + '<br>';
        })

        this.ui.showGeneralMessage('Regular users won\'t see this message. This trip is not valid.', message);
      }

    });
  },

  enquireGeneral(){
    trackEvent('ask-a-question:submit');
    window.timbuktu.reportConversions.reportGeneralEnquiry();

    this.set('showErrors', false);
    this.set('errors', null);
    this.set('submittingEnquiry', true);

    var enquiry = this.setUpEnquiry();
    captureEmail(enquiry.email, this.sharedUrl, 'ask', enquiry.firstName);

    enquiry.save().then(() => {
      trackEvent('ask-a-question:completed');
      this.set('submittingEnquiry', false);
      this.get('router').transitionTo('message-success');
      trackEvent('ask-a-question:complete');
    }).catch((serverResponse) => {
      this.get('flashMessages')
        .danger('Please supply all the required information', {
          timeout: 4000,
          sticky: false
        });
      trackEvent('ask-a-question:validation:failed');

      this.set('submittingEnquiry', false);
      this.set('showErrors', true);
      this.set('errors', getErrorsHashFromServerResponse(serverResponse));
    });
  },

  actions: {
    setFlights(path) {
      this.set('bookedFlights', path);
    },

    showChildrenOptions() {
      this.toggleProperty('showingChildrenOptions');
      trackEvent('enquire:toggle-children-option');
    },

    enquire() {
      if (this.enquiryType=='trip') {
        this.enquireTrip();
      } else if (this.enquiryType=='general') {
        this.enquireGeneral();
      }
    }
  }


});
