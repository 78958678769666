import {
  inject as service
} from '@ember/service';
import {
  isEmpty
} from '@ember/utils';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  and,
  reads,
  alias
} from '@ember/object/computed';
import {
  getLoccalStorageEmailCapturedKey,
  getItemFromLocalStorage,
  trackEvent
} from 'b5b/utils';
import {
  equal
} from 'ember-decorators/object/computed';
import {
  task
} from 'ember-concurrency';

export default Component.extend({
  classNameBindings: [ 'active', 'showSearchResults::no-search-results', 'tw-relative'],

  scroll: service(),
  ui: service(),
  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  tripService: service('trip'),
  stageIndex: alias('tripService.currentStageIndex'),

  // active: alias('scroll.isSearching'), we now explicitly bind each instance individually

  currentActive: false, // to differentiate between mutiple searches on same page

  isActive: and('active', 'currentActive'),

  placeholder: null,
  value: '',
  pseudoValue: '', // for content buffering

  clearValue: false, // set it in once to clear

  insertFocusTimer: null,
  deactivateTimer: null,

  includeLodges: true,
  includeTrips: true,
  autofocus: '',
  seeAllResultsVisible: true,
  showSearchResults: true, //Can disable local search results so you only use the external callback

  querying: false,

  onUpdate: null, // closure
  onSelect: null, // closure
  onClose: null, // closure

  results: [],
  popularSearches: [],

  focusIndex: null,
  isFocused: null,
  showAddButton: false,

  @equal('searchType', 'lodge') isLodgeSearch: false,

  // This was an attribute on the input element but it stopped working on Android and Chrome
  // @computed('ui.isPrimarySearchOpen')
  // disabled(onOpen) {
  //   return onOpen ? true : false;
  // },

  init() {
    this._super(...arguments);

    if (this.get('value')) {
      this.set('pseudoValue', this.get('value'));
    }

    if (this.useDefaultSuggestions) {
      this.set('popularSearches', this.get('store').peekAll('home').get('firstObject.search'));
    }

    this.set('focusIndex', -1);
  },

  didUpdateAttrs() {
    this._super(...arguments);
    if (this.get('clearValue')) {
      this.set('pseudoValue', '');

      run.next(() => {
        this.set('clearValue', false);
      })

    }
  },

  didInsertElement() {
    // We need to wait until after didInsertElement to ensure the input has been inserted into the dom
    if (this.get('autofocus')) {
      run.cancel(this.insertFocusTimer);
      this.insertFocusTimer = run.later(() => {
        this._refocus();
      }, 300);
    }
    if (!this.isFastBoot) {
      this.set('boundKeyDownHandler', this.handleKeyPress.bind(this));
      $(document).on('keydown', this.boundKeyDownHandler);
    }
  },

  _refocus() {
    run.next(this, function() {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        this.$('input').focus();
      }
    });
  },

  focusOut() {
    this.set('isFocused', null);
    this.deactivate();
  },

  @computed('pseudoValue', 'popularSearches.countries.[]', 'popularSearches.regions.[]', 'results.continents.[]', 'results.countries.[]', 'results.regions.[]', 'results.lodges.[]', 'results.experiences.[]', 'results.trips.[]', 'results.agencies.[]', 'results.locations.[]', 'results.areas.[]')
  searchSuggestions(pseudoValue, popularCountries, popularRegions, continents, countries, regions, lodges, experiences, trips, agencies, locations, areas) {
    let results = [];
    if (pseudoValue === '') {
      // load popular searches
      if (popularCountries) {
        popularCountries.forEach((country) => {
          results.push(country);
        });
      }
      if (popularRegions) {
        popularRegions.forEach((region) => {
          results.push(region);
        });
      }
    } else {
      // load search results
      // console.log(continents)
      if (continents) {
        continents.forEach((continent) => {
          results.push(continent);
        });
      }
      if (countries) {
        countries.forEach((country) => {
          results.push(country);
        });
      }
      if (regions) {
        regions.forEach((region) => {
          results.push(region);
        });
      }
      if (this.includeLodges && lodges) {
        lodges.forEach((lodge) => {
          results.push(lodge);
        });
      }
      if (experiences) {
        experiences.forEach((experience) => {
          results.push(experience);
        });
      }
      if (agencies) {
        agencies.forEach((agency) => {
          results.push(agency);
        });
      }
      if (locations) {
        locations.forEach((location) => {
          results.push(location);
        });
      }
      if (areas) {
        areas.forEach((area) => {
          results.push(area);
        });
      }
      if (this.includeTrips && trips) {
        trips.forEach((trip) => {
          results.push(trip);
        });
      }
    }
    // console.log(this.results)
    return results;
  },

  @computed('searchSuggestions.[]')
  searchResultsCount(suggestions) {
    return suggestions.length;
  },

  clearXSearch() {
    this.setProperties({
      value: '',
      pseudoValue: '',
      results: []
    });
    if (this.onKeyUpInSearchBox) {
      this.onKeyUpInSearchBox('');
    }
  },

  selectResult(result, type) {
    console.log('selectREsult', arguments)
    if (this.get('onSelect')) {
      run.cancel(this.deactivateTimer);
      this.set('active', false);
      this.set('currentActive', false);
      this.set('focusIndex', null);
      this.set('isFocused', null);

      this.setProperties({
        value: ''
      });

      if (this.clearOnSelect) {
        this.clearXSearch();
        this.send('focusInput')
      } else {
        if (result) {
          this.set('pseudoValue', result.get('name'));
          this.set('value', result.get('name'));
        } else {
          this.set('value', this.get('pseudoValue'));
        }
      }
      trackEvent('x-search:' + this.get('searchType'))

      this.get('onSelect')({
        result,
        type,
        action: 'search:select'
      });
    }
  },

  searchValueChanged(val) {
    if (this.pseudoValue !== val) {
      this.set('focusIndex', -1);
    }
    this.set('pseudoValue', val);
    if (this.showSearchResults) {
      this.set('querying', true)
      // console.log('updateSearchSuggestions', val)
      this.updateSearchSuggestionsTask.perform()
    }
  },

  updateSearchSuggestionsTask: task(function*() {
    let value = this.get('pseudoValue') && this.get('pseudoValue').trim();

    // console.log('updateSearchSuggestionsTask', value)
    this.set('querying', true);

    if (isEmpty(value)) {
      this.set('querying', false);
    // } else if (value && value.length > 1) {
    } else {

      this.set('querying', true);

      return this.get('store').createRecord('search', {
        searchType: this.get('searchType'),
        polishLevel: this.polishLevel,
        value: value,
        previousSearch: this.get('results'),
        email: getItemFromLocalStorage(getLoccalStorageEmailCapturedKey())
      }).save().then((searchResponse) => {

        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('querying', false);
          this.set('results', searchResponse);
        }
      });
    }
  }).keepLatest(),


  deactivate() {
    this.deactivateTimer = run.later(() => {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        this.set('active', false);
        this.set('currentActive', false);
        if (!this.get('pseudoValue') && (this.get('value'))) {
          this.get('onSelect')({
            action: 'search:deactivate'
          });

        }

        if (this.get('onClose')) {
          this.get('onClose')();
        }
      }
    }, 160);
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.insertFocusTimer);
    run.cancel(this.deactivateTimer);
    if (!this.isFastBoot) {
      $(document).off('keydown', this.boundKeyDownHandler);
    }
    this.set('active', false);
    this.set('currentActive', false);
  },

  handleKeyPress(e) {
    this.set('isFocused', this.$('input').is(":focus"));
    if (this.get('isFocused')) {
      switch (e.which) {
        case 13: // enter
          this._select();
          break;
        case 38: // up
          this._previous();
          break;
        case 40: // down
          this._next();
          break;
        default:
          return;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  },

  _select() {
    if (this.focusIndex == -1 && this.allowTextSearch && this.pseudoValue) {
      return this.selectResult({type: 'text', name: this.pseudoValue}, 'text');
    }
    this.selectResult(this.searchSuggestions[this.focusIndex], this.searchSuggestions[this.focusIndex]._internalModel.modelName);
  },

  _previous() {
    if (this.searchSuggestions) {
      if (this.get('focusIndex') === 0 || this.get('focusIndex') === -1) {
        return this.set('focusIndex', this.searchResultsCount - 1);
      }
      this.decrementProperty('focusIndex');
    }
  },

  _next() {
    if (this.searchSuggestions) {
      if (this.get('focusIndex') === this.searchResultsCount - 1 || this.get('focusIndex') === -1) {
        return this.set('focusIndex', 0);
      }
      this.incrementProperty('focusIndex');
    }
  },

  actions: {
    clear() {
      this.clearXSearch()
      this._refocus();
      if (this.get('onSelect')) {
        this.get('onSelect')({
          action: 'search:clearSelection'
        });
      }
      if (this.get('onClear')) {
        this.onClear();
      }
    },

    clearSelectThenInvokeAction(action, options) {
      this.clearXSearch()
      // console.log(options)
      action(options);
    },

    onClickSearchBox() {
      // We use this instead of ember click handler so that we can bind this action to the javascript onclick on the main div.
      // This allows us to open the keyboard automatically when user taps the widget on mobile.
      if (this.onOpen) {
        this.get('onOpen')();
      }
    },

    focusInput() {
      this.$('input').focus();
    },

    onKeyUpInputField(val) {
      this.searchValueChanged(val);
      if (this.onKeyUpInSearchBox) {
        this.onKeyUpInSearchBox(val);
      }
    },

    onFocus(e) {
      run.cancel(this.deactivateTimer);
      if (this.get('onOpen')) {
        this.get('onOpen')();
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      // this.updateSearchSuggestionsTask.perform()
      this.set('active', true);
      this.set('currentActive', true);
    },

    selectResult(result, type) {
      this.selectResult(result, type);
    },

    deactivate() {
      this.deactivate();
    }
  }

});
