define("ember-cli-fastboot/services/fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RequestObject = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var request = this.request;
      delete this.request;
      this.method = request === null || request === void 0 ? void 0 : request.method;
      this.body = request === null || request === void 0 ? void 0 : request.body;
      this.cookies = request === null || request === void 0 ? void 0 : request.cookies;
      this.headers = request === null || request === void 0 ? void 0 : request.headers;
      this.queryParams = request === null || request === void 0 ? void 0 : request.queryParams;
      this.path = request === null || request === void 0 ? void 0 : request.path;
      this.protocol = request === null || request === void 0 ? void 0 : request.protocol;
      this._host = function () {
        return request === null || request === void 0 ? void 0 : request.host();
      };
    },
    host: Ember.computed(function () {
      return this._host();
    })
  });
  var Shoebox = Ember.Object.extend({
    put: function put(key, value) {
      (true && !(this.get('fastboot.isFastBoot')) && Ember.assert('shoebox.put is only invoked from the FastBoot rendered application', this.get('fastboot.isFastBoot')));
      (true && !(typeof key === 'string') && Ember.assert('the provided key is a string', typeof key === 'string'));
      var fastbootInfo = this.get('fastboot._fastbootInfo');
      if (!fastbootInfo.shoebox) {
        fastbootInfo.shoebox = {};
      }
      fastbootInfo.shoebox[key] = value;
    },
    retrieve: function retrieve(key) {
      if (this.get('fastboot.isFastBoot')) {
        var shoebox = this.get('fastboot._fastbootInfo.shoebox');
        if (!shoebox) {
          return;
        }
        return shoebox[key];
      }
      var shoeboxItem = this.get(key);
      if (shoeboxItem) {
        return shoeboxItem;
      }
      var el = document.querySelector("#shoebox-".concat(key));
      if (!el) {
        return;
      }
      var valueString = el.textContent;
      if (!valueString) {
        return;
      }
      shoeboxItem = JSON.parse(valueString);
      this.set(key, shoeboxItem);
      return shoeboxItem;
    }
  });
  var FastBootService = Ember.Service.extend({
    isFastBoot: typeof FastBoot !== 'undefined',
    isFastboot: Ember.computed(function () {
      (true && !(false) && Ember.assert('The fastboot service does not have an `isFastboot` property. This is likely a typo. Please use `isFastBoot` instead.', false));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var shoebox = Shoebox.create({
        fastboot: this
      });
      this.set('shoebox', shoebox);
    },
    response: Ember.computed.readOnly('_fastbootInfo.response'),
    metadata: Ember.computed.readOnly('_fastbootInfo.metadata'),
    request: Ember.computed(function () {
      if (!this.isFastBoot) return null;
      return RequestObject.create({
        request: Ember.get(this, '_fastbootInfo.request')
      });
    }),
    // this getter/setter pair is to avoid deprecation from [RFC - 680](https://github.com/emberjs/rfcs/pull/680)
    _fastbootInfo: Ember.computed({
      get: function get() {
        if (this.__fastbootInfo) {
          return this.__fastbootInfo;
        }
        return Ember.getOwner(this).lookup('info:-fastboot');
      },
      set: function set(_key, value) {
        this.__fastbootInfo = value;
        return value;
      }
    }),
    deferRendering: function deferRendering(promise) {
      (true && !(typeof promise.then === 'function') && Ember.assert('deferRendering requires a promise or thennable object', typeof promise.then === 'function'));
      this._fastbootInfo.deferRendering(promise);
    }
  });
  var _default = _exports.default = FastBootService;
});