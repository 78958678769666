import Component from '@ember/component';

export default Component.extend({
  classNames: ['experts-card'],
  tagName: 'div',

  actions: {
    _endLoad() {
    },

    _startLoad() {
    },

  },
});
