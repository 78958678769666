define("ember-leaflet/macros/to-lat-lng", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _default() {
    var latKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'lat';
    var lngKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lng';
    return Ember.computed(latKey, lngKey, {
      get: function get() {
        var _ref = [this.get(latKey), this.get(lngKey)],
          lat = _ref[0],
          lng = _ref[1];
        return this.L.latLng(lat, lng);
      },
      set: function set(key, value) {
        this.setProperties(_defineProperty(_defineProperty({}, latKey, value ? value.lat : value), lngKey, value ? value.lng : value));
        return value;
      }
    });
  }
});