import Service, {
  inject as service
} from '@ember/service';
import {
  isEmpty
} from '@ember/utils';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  humanReadableList,
  showInViewPort,
  prevNonTransferStage,
  nextNonTransferStage,
  nextTransferStage,
  prevTransferStage,
  setupTripEntityNav,
  getEntityFilters,
  generateReplaceImagesForEntity,
  generateEmptyReplaceImages
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  formatConvertedPrice,
  convertCurrencyHash,
  formatCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  mapBy,
  alias
} from 'ember-decorators/object/computed';
import {
  serializeDate
} from 'b5b/transforms/b5bdate';
import {
  run
} from '@ember/runloop';
import {
  htmlSafe
} from '@ember/string';
import RSVP from 'rsvp';
import LodgeInfo from 'b5b/models/lodge-info';
import RegionInfo from 'b5b/models/region-info';
import ExperienceInfo from 'b5b/models/experience-info';
import { A as EmberArray } from '@ember/array';
import stage from '../models/stage';
import blockItem from '../models/block-item';

export default Service.extend(TripSaver, {

  historyService: service(),
  store: service(),
  session: service(),
  router: service(),
  messageBus: service(),
  ui: service(),
  flashMessages: service(),
  locationScrollHistory: service(),
  scroll: service(),
  entityModals: service(),
  settings: service(),
  whitelabel: service(),
  templateManager: service(),

  itineraryStates: ['', 'enquiry', 'quote', 'deposit_paid', 'balance_due', 'balance_paid'],

  isEditing: false,

  returnRoute: null, // trip.index || trip.index.edit.itinerary || create
  hasBackButton: false,
  currentStageIndex: null, // index - reset on lodges route
  numNights: null, // stage nights
  dontBook: null, // if TT doesnt have to book it

  newStage: null, // object - unsaved stage model
  newStageIndex: null, // used to insert new stage at specific index - ALSO to focus new stages on insert

  currentTrip: null, // set on create and trip route setup

  startDateForPricingAndAvailability: null,
  endDateForPricingAndAvailability: null,

  showWidgetPopup: true, // set here to persist for session
  tripDatePickerInstance: null, // for setting trips

  stageToRemove: null,
  showConfirmStageDeletionModal: false, // used for toggling the stage deletion confirmation modal
  stageForLodgeRemoval: null, //used to toogle the lodge removal modal

  stageFocusTimer: null,

  showTripNamingWithoutSaveModal: false,

  tripPreviewModal: null, // {trip, view}
  tripSortingModal: null, // {view}

  lodgeStartDate: null, // queryParam ref
  lodgeEndDate: null, // queryParam ref
  didChangeLodgeDates: false,
  checkedDateChange: false,
  showLodgeTripDateChangeModal: false,

  changingRegion: false, //default for QP

  allPartners: null, //Setup when you open quote tools
  allPartnersPromise: null,

  allServiceTypes: null, //Setup when you open quote tools
  allServiceTypesPromise: null,
  editingEnabled: false,

  @computed('currentTrip.itinerary.pricingModel', 'whitelabel.isForTimbuktu')
  timbuktuTripOnPricingModel4(pricingModel, isForTimbuktu) {
    if (isForTimbuktu && pricingModel == 4) {
      return true
    }
    return false;
  },

  @computed('editingEnabled', 'entityModals.onModal', 'entityModals.editInModal')
  isEditing(editingEnabled, entityModalsEnabled, editInModal) {
   return (editingEnabled && !entityModalsEnabled) || editInModal;
  },


  @computed('currentStageIndex', 'currentTrip', 'newStage', 'currentTrip.itinerary.stages.@each')
  currentStage(currentStageIndex, currentTrip, newStage, stages) {
    // new stages take preference, but should be cleared explicitly, eg places and own-arrangements routes
    if (currentTrip && newStage) {
      return newStage;
    }

    if (currentTrip && currentStageIndex > -1) {
      return stages.objectAt(currentStageIndex)
    }
  },

  setDefaultSchedulesOnCurrentTrip() {

    // This is actually not done for day by day trips because they have schedules allocated. Only on other content managed trips
    this.get('currentTrip.itinerary.nonTransferAndLocationStages').forEach((stage)=> {
      if (!stage.get('schedule') && stage.get('regionProxy') ) {
        stage.get('regionProxy.regionInfo').then((regionInfo)=> {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          if (regionInfo.defaultSchedule) {
            stage.set('templateSchedule', regionInfo.defaultSchedule)
          } else {
            if (stage.get('regionProxy')) {
              let query = {
                filters: JSON.stringify([{type: 'region', id: stage.get('regionProxy.id')}]),
                per_page: 18,
                isRegional: true
              };

              this.store.query('experience', query).then((experiences) => {
                if (this.isDestroying || this.isDestroyed) {
                  return;
                }
                if (experiences.length > 0) {
                  let schedule = this.store.createRecord('schedule')
                  let block = this.store.createRecord('block', {sequence: schedule.get('blocks.length')})
                  let blockItem = this.store.createRecord('blockItem', {sequence: block.get('blockItems.length'), autoIncludeAllExperiences: true})
                  block.get('blockItems').pushObject(blockItem)
                  schedule.set('region', stage.get('regionProxy'));
                  schedule.set('scheduleType', 'template');

                  schedule.get('blocks').pushObject(block)
                  regionInfo.set('defaultSchedule', schedule)
                  stage.set('templateSchedule', schedule)
                }
              });
            }
          }
        })
      }
    })

  },

  cleanupQuoteToolsDirtyIndicators() {
    this.setProperties({
      quoteGuestsChanged: false,
      quoteGuestsAdded: false,
      quoteGuestsRemoved: false,
      lineItemsChanged: false,
      paymentsChanged: false,
      discountsChanged: false
    })
  },

  reverseTrip() {
    this.set('currentStageIndex', null);
    this.set('currentTrip.itinerary.stages', this.get('currentTrip.itinerary.stages').toArray().reverse());
    this.madeChanges();
  },

  refreshCurrentTripFromServer() {
    return this.get('store').queryRecord('trip', {
      id: this.get('currentTrip.friendlyId'),
      buster: true
    });
  },

  @computed('currentTrip')
  useNewPricingModel(currentTrip) {
    if (currentTrip) {
      return currentTrip.get('useNewPricingModel');
    }
  },

  @computed('currentTrip.displayPrices')
  hideAllPrices(displayPrices) {
    if (displayPrices=="hide-all") {
      return true;
    }
  },

  @computed('currentTrip.users.@each.email')
  currentTripUserEmails(currentTripUsers) {
    let emails = [];
    // If a user tries to load a trip, then the associated users come back without emails in order to stop snooping into our API. So have to ensure that this array doesnt ahve undefined in it
    if (currentTripUsers) {
      currentTripUsers.forEach((user)=> {
        if (user.email) {
          emails.pushObject(user.email)
        }
      })
    }
    return emails;
  },

  showTripLockedForNonOwnerModal: false,

  @computed('session.currentUser.email', 'session.currentUser.isTimbuktu', 'currentTrip.itinerary.stateQuoteAndAfter', 'currentTripUserEmails')
  viewingSomeoneElsesTrip(currentUserEmail, isTimbuktuUser, stateQuoteAndAfter, currentTripUserEmails) {
    // Timbuktu users/consultans should be able to see quotes but not if preview quote mode is switched on
    if (isTimbuktuUser || this.get('session.currentUser.consultant')) {
      return false;
    }
    return stateQuoteAndAfter && !currentTripUserEmails.includes(currentUserEmail);
  },

  @computed('session.currentUser.id', 'currentTrip.userTrips.@each.id', 'currentTrip.userTrips.@each.id')
  currentUserIsGiftListOwner(currentUserId, userTrips) {

    return userTrips.filter((userTrip) => {
      return userTrip.belongsTo('user').id() == currentUserId && userTrip.isGiftListOwner
    }).get('length') > 0;
  },

  setPricingAvailablityDates(stage) {
    this.set('startDateForPricingAndAvailability', null);
    this.set('endDateForPricingAndAvailability', null);

    let startDate = null;
    let endDate = null;

    if (stage && stage.get('startDate')) {
      startDate = stage.get('startDate');
      endDate = stage.get('endDate');
    } else if (this.get('currentTrip.itinerary.startDate')) {
      let startMoment = moment(this.get('currentTrip.itinerary.startDate'));
      this.get('currentTrip.itinerary.stages').forEach((stage) => {
        startMoment.add(stage.get('numNights'), 'days');
      });

      startDate = startMoment.toDate();
      endDate = moment(startMoment).add(stage.get('numNights'), 'days').toDate();

    }

    this.set('startDateForPricingAndAvailability', startDate);
    this.set('endDateForPricingAndAvailability', endDate);
  },

  sendEvent(event = { /* eventType, properties, allocatedEmail */ }) {
    if (window && window.timbuktu && !window.timbuktu.preRendering && !window.timbuktu.pdfGen && !window.timbuktu.blockAnalyticsAndAffiliates) {
      if (window && window.location) {
        event['url'] = window.location.href
      }
      event['showShareVersion']=this.get('currentTrip.showShareVersion');
      trackEvent('send-event:' + event.eventType, event);
      // console.log(event)
      this.get('currentTrip.itinerary').sendEvent({
        event
      });
    }
  },

  getTripPrice(params, namedArgs) {
    let trip = namedArgs.trip;
    let tripIdeaSummary = namedArgs.tripIdeaSummary;
    if (!(trip || tripIdeaSummary) ) {
      console.log("ERRROR!!!!!: Need a trip or tripIdeaSummary to be passed in")
      // I don;t think we should use current trip.. Rather be explicit in the helper about which trip needs to be used
    }

    // The default is not to apply transaction fees as quotes already have them applied. We only need to apply transaction fees to estimates
    let markPricesUp = namedArgs.markPricesUp !== undefined ? namedArgs.markPricesUp : false;
    // real prices shouldn't get truncated
    let truncate = namedArgs.truncate !== undefined ? namedArgs.truncate : false
    let roundToClosest = namedArgs.roundToClosest || 0;
    let estimate = (namedArgs.estimate || tripIdeaSummary || trip.get('itinerary.stateEmpty') || trip.get('itinerary.stateEnquiry')) && (tripIdeaSummary || !trip.get('itinerary.isBookable'))
    let formatPrice = namedArgs.formatPrice !== undefined ? namedArgs.formatPrice : true; //Format prices by default
    let toCurrency = namedArgs.toCurrency || this.get('settings.currentCurrency');
    let isText = namedArgs.isText || false;

    if (estimate) {
      // default for estimates is to truncate=1000 roundToCloset=10 markPricesUp=true
      truncate = namedArgs.truncate !== undefined ? namedArgs.truncate : 1000;
      roundToClosest = namedArgs.roundToClosest !== undefined ? namedArgs.roundToClosest : 10;
      // Default for estimates is to mark up to try and get estiamtes closer to what the timbuktu auto markup will result in
      markPricesUp = namedArgs.markPricesUp !== undefined ? namedArgs.markPricesUp : true;
    }

    if (namedArgs.noPennies) {
      // default to markPricesUp=false truncate=false roundToClosest=0 unless namedArges set
      markPricesUp = namedArgs.markPricesUp !== undefined ? namedArgs.markPricesUp : false;
      truncate = namedArgs.truncate !== undefined ? namedArgs.truncate : false;
      roundToClosest = namedArgs.roundToClosest !== undefined ? namedArgs.roundToClosest : 0;
    }

    if (namedArgs.factor !== undefined) {
      markPricesUp = namedArgs.factor == 1 ? true: false;
    }

    if (trip && trip.itinerary && trip.itinerary.factor !== undefined && namedArgs.markPricesUp === undefined && namedArgs.factor === undefined) {
      // Defined in Hawk TripRate.rb
      let FACTOR_0 = 0; //Nothing added in browser
      let FACTOR_1 = 1; // #Add transaction fees and USD markup in browser
      if (trip.itinerary.factor===1) {
        markPricesUp = true;
      } else if (trip.itinerary.factor===0) {
        markPricesUp = false;
      }
    }

    let tripPrice = convertCurrencyHash({
      val: namedArgs.val,
      fromCurrency: namedArgs.fromCurrency,
      toCurrency: toCurrency,
      roundToClosest: roundToClosest,
      isText: isText,
      truncate: truncate,
      markPricesUp
    });

    if (formatPrice) {
      tripPrice =formatConvertedPrice({converted: tripPrice, toCurrency: toCurrency, isText: isText});
    }
    return tripPrice
  },

  prepareVideos() {
    this.set('currentTrip.tripClipVideo', false)
    this.set('currentTrip.loomVideo', false)
    this.get('currentTrip.itinerary.videos').forEach((video)=> {
      if (video.videoType=='tripclip') {
        this.set('currentTrip.tripClipVideo', video)
        video.set('useTripClipPlayer', false)
        $.get(video.url, (response) => {
          var videoTagMatcher = response.match(/<video.*<\/video>/);
          if (videoTagMatcher) {
            let videoTag = videoTagMatcher[0]
            let srcIndex = videoTag.indexOf("src=")
            let quoteChar = videoTag.charAt(srcIndex+4)
            let endQuoteIndex = videoTag.indexOf(quoteChar, srcIndex+5)
            let realUrl = videoTag.substring(srcIndex+5, endQuoteIndex)
            if (realUrl.substring(realUrl.length-4, realUrl.length)=='.mp4') {
              video.set('actualVideoType', 'video_url')
              // video.set('actualUrl', realUrl+"#t=0.001")
              video.set('actualUrl', realUrl)
            } else {
              video.set('useTripClipPlayer', true)
            }
          } else {
            video.set('tripClipExpired', true)
          }

        });
      } else if (video.videoType=='loom') {
        this.set('currentTrip.loomVideo', video)
      }
    })
  },

  /*---------------------
      Dates
  -----------------------*/

  openDatePicker(e) {
    this.tripDatePickerInstance.open();
    if (e) {
      e.stopPropagation()
    }
  },

  setStartDate(startDate) {
    this.set('currentTrip.itinerary.startDate', startDate);
    trackEvent('trip:set-dates', {
      tripModifyFlag: true
    });
    this.madeChanges();
  },

  addDates(event) {
    if (this.get('currentTrip.itinerary.stateEnquiryAndAfter') && this.get('isNonManagerUserForThisTrip')) {
      this.set('showTripLockedModal', true);
    } else if (this.get('currentTrip.itinerary.stateEnquiryAndAfter')) {
      this.set('showTripLockedForNonOwnerModal', true);
    } else {
      this.get('tripDatePickerInstance').set('select', this.get('currentTrip.itinerary.startDate'));
      this.get('tripDatePickerInstance').open();
    }
    if (event) {
      event.stopPropagation();
    }
  },

  /*---------------------
      Lodge
  -----------------------*/


  setLodgeTripDateChange(doChange /* bool */ ) {
    if (doChange) {
      let currentStageStartDate = this.get('lodgeStartDate');
      let currentStageEndDate = this.get('lodgeEndDate');

      let newStageNumNights = moment(currentStageEndDate).diff(moment(currentStageStartDate), 'days');
      this.set('currentStage.numNights', newStageNumNights);

      let numNightsAfterCurrentStage = 0;
      let totalNights = 0; // cant depend on itin.numNights

      if (this.get('currentStageIndex') === -1) {
        totalNights += this.get('currentStage.numNights'); // if new stage add its nights to total
      }

      this.get('currentTrip.itinerary.nonTransferAndLocationStages').forEach((stage, index) => {
        if (index > (this.get('currentStageIndex') - 1) && this.get('currentStageIndex') > -1) {
          numNightsAfterCurrentStage += stage.get('numNights');
        }

        totalNights += stage.get('numNights');
      });

      let newTripEndDate = moment(currentStageEndDate).add(numNightsAfterCurrentStage, 'days');
      let newTripStartDate = moment(newTripEndDate).subtract(totalNights, 'days');

      this.set('currentTrip.itinerary.startDate', moment(newTripStartDate).format('YYYY-MM-DD'));

    }

    this.set('checkedDateChange', true);
    this.doChangeFoundNewLodge(this.get('showLodgeTripDateChangeModal'));
  },

  doChangeFoundNewLodge(options = { /* lodge, numNights, dontBook, optional stage, useLodgeRegionIfMismatch*/ }) {
    // debugger
    if (!options.lodge) {
      options.lodge = options.entity;
      if (!options.lodge) {
        return;
      }
    }
    let lodge = options.lodge;

    // if (this.whitelabel.isOnboardingAgency) {
    //   return this.set('ui.showWaybirdSignupModal', true)
    // }

    if (this.get('lodgeEndDate') && !this.get('checkedDateChange') && this.get('didChangeLodgeDates')) {
      return this.set('showLodgeTripDateChangeModal', options);
    }

    this.setProperties({
      checkedDateChange: false,
      didChangeLodgeDates: false,
      showLodgeTripDateChangeModal: null
    });

    let stageToUpdate = options.stage || (this.chooseEntityOptions && this.chooseEntityOptions.stage);
    let needToInsertNewStage = this.get('currentTrip.itinerary.stages').indexOf(stageToUpdate) === -1;
    if (needToInsertNewStage) {
      stageToUpdate = this.newStage;
    } else {
      this.set('currentStageIndex', this.get('currentTrip.itinerary.stages').indexOf(stageToUpdate));
    }
    let originalRegion = stageToUpdate.get('region')
    let originalLodge = stageToUpdate.get('lodge')

    stageToUpdate.setProperties({
      lodge: lodge,
      stageType: 'lodge',
      madeTransferAffectingChanges: true,
      overrideLodgeKodakImageUrl: null,
      overrideLodgeKodakImageCoverStyle: null,
    });

    if (!originalRegion) {
      // No probs.. just stay null
    } else if (this.settings.isNotManager || lodge.get('region') == stageToUpdate.region) {
      // non managers always just use region associatd with lodge
      // if the lodge belongs to original region, then clear region so we pull region through lodge
      stageToUpdate.set('region', null)
    } else if ( stageToUpdate.get('region.associatedRegions').includes(lodge.get('region'))) {
      // We leave the original region on the stage, as this is probably a duplicated region and that content needs to be shown instead of the lodges default region
    } else if ( options.useLodgeRegionIfMismatch) {
      // This caters for routes where the lodge on the budget level trip idea isnt from the same region as the route, and we force the change to the lodges region
      stageToUpdate.set('region', lodge.get('region'))
    } else {
      this.set('ui.regionMismatchModalOptions', {originalRegion, stage: stageToUpdate})
    }


    // if stage is not inserted into trip yet do so - its a new stage
    if (needToInsertNewStage) {
      this.addNewStageToTrip({callMadeChanges: false, scrollToStage: options.scrollToLodge });
    } else {
      this.populateDefaultTemplates(stageToUpdate, originalRegion, originalLodge);
    }

    if (this.currentTrip.isRoute && !(this.get('currentTrip') && this.get('currentTrip').belongsTo('templateTripIdea').id())) {
      // If a route has not got a trip idea applied yet, we do this to simulate the effect of applying the "choose your own ldoges" trip idea
      this.set('currentTrip.templateTripIdea', this.currentTrip);
    }

    this.entityModals.closeModal()
    this.get('tripService').madeChanges();

    // if unpriced lodge, show modal
    if (this.settings.isNotManager && !lodge.fromPrice) {
      trackEvent('trip:stage:lodge:unpriced-lodge-added');
      this.set('ui.showUnpricedLodgesModal', true);
    }

    trackEvent('trip:change-lodge:complete', {
      tripModifyFlag: true
    });

    if (this.get('returnRoute')) {
      this.get('router').transitionTo(this.get('returnRoute'), this.get('currentTrip'));
      this.set('returnRoute', null);
    }

    if (!(options.scrollToLodge==false)) {
      let options = {scrollToLodge: true}
      if (this.currentTrip.isLayoutDayByDay && this.tripService.currentBlockItemIndex) {
        options.scrollToCurrentBlockItem=true
      }
      this.focusStage(options);
    }

    this.set('hasBackButton', false);
    this.set('chooseEntityOptions', null);
  },


  populateStageInclusionsFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge) {

    if (!stageToUpdate.isLodgeStage) {
      stageToUpdate.set('whatsIncluded', null)
      stageToUpdate.set('whatsExcluded', null)
      return;
    }

    let inclusionQueryAttrs = {
      templateType: 'template',
      buster: true,
      sort: 'recommended',
      filters: JSON.stringify([{type: 'lodge', id: stageToUpdate.get('lodge.id')}]),
      isDefault: true
    };

    // Allocate default template inclusions to stage
    this.store.query('inclusion', inclusionQueryAttrs).then((results)=>{
      if (results.length>0) {
        this.templateManager.allocateCopyOfInclusionToStage({inclusion: results.objectAt(0), stage: stageToUpdate})
      } else {
        stageToUpdate.set('whatsIncluded', null)
        stageToUpdate.set('whatsExcluded', null)
      }
    })
  },

  populateStageWhyLoveItFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge) {

    if (!stageToUpdate.isLodgeStage) {
      stageToUpdate.set('overviewWhyLove', null)
      return;
    }

    let lodgeWhyLoveQueryAttrs = {
      buster: true,
      sort: 'recommended',
      templateType: 'lodge_why_love',
      filters: JSON.stringify([{type: 'lodge', id: stageToUpdate.get('lodge.id')}]),
      isDefault: true
    };

    this.store.query('template', lodgeWhyLoveQueryAttrs).then((results)=>{
      if (results.length>0) {
        // Allocate default template why you love it for the lodge to stage
        this.templateManager.allocateCopyOfTemplateToStage({template: results.objectAt(0), stage: stageToUpdate})
      } else {
        // Allocate lodge why you love it to the stage why you love it
        stageToUpdate.get('lodge.lodgeInfo').then((lodgeInfo) => {
          // this overview can be present or null, but content from original lodge needs to be cleared
          stageToUpdate.set('overviewWhyLove', lodgeInfo.get('overviewWhyLove'))
        })
      }
    })
  },

  populateScheduleFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge) {
    let scheduleAttrs = {
      templateType: 'template',
      buster: true,
      filters: JSON.stringify([{type: 'lodge', id: stageToUpdate.get('lodge.id')}]),
      sort: 'recommended',
      isDefault: true
    };
// Allocate default template schedule to stage
    this.store.query('schedule', scheduleAttrs).then((defaultSchedules)=>{
      let defaultScheduleEntity = stageToUpdate.get('lodge')
      let options =  {defaultSchedules, stageToUpdate, originalLodge, originalRegion, defaultScheduleEntity};
      if (this.get('session.currentUser.isManager') && defaultSchedules.length>0 && stageToUpdate.get('customSchedule.blocks.length')>0) {
        // show popup with options:
        // 1) Replace checkin element but leave what you'll do
        // 2) Replace entire schedule
        this.set('showShouldOverrideScheduleModalOptions', options)
      } else if (defaultSchedules.length>0) {
        this.replaceScheduleFromTemplate(options)
      } else {
        this.populateScheduleFromRegionTemplates(stageToUpdate, originalRegion, originalLodge)
      }
    })
  },

  populateScheduleFromRegionTemplates(stageToUpdate, originalRegion, originalLodge) {
    let scheduleAttrs = {
      templateType: 'template',
      buster: true,
      filters: JSON.stringify([{type: 'region', id: stageToUpdate.get('regionProxy.id')}]),
      sort: 'recommended',
      isDefault: true
    };
// Allocate default template schedule to stage

    this.store.query('schedule', scheduleAttrs).then((defaultSchedules)=>{
      let defaultScheduleEntity = stageToUpdate.get('regionProxy')
      let options =  {defaultSchedules, stageToUpdate, originalLodge, originalRegion, defaultScheduleEntity};
      if (this.get('session.currentUser.isManager') && defaultSchedules.length>0 && stageToUpdate.get('customSchedule.blocks.length')>0) {
        // show popup with options:
        // 1) Replace checkin element but leave what you'll do
        // 2) Replace entire schedule
        this.set('showShouldOverrideScheduleModalOptions', options)
      } else if (defaultSchedules.length>0) {
        this.replaceScheduleFromTemplate(options)
      } else {
        this.setupCheckinBlock(options)
      }
    })
  },

  // This is only called for lodge stages. Either when a new lodge stage is created or a lodge is added/changed
  // originalLodge is what was present on the stage before. So if it present, it means a lodge is being replaced on an existing stage
  populateDefaultTemplates(stageToUpdate, originalRegion, originalLodge) {

    this.populateStageWhyLoveItFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge)
    this.populateStageInclusionsFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge)

    if (stageToUpdate.isLodgeStage) {
      if (this.get('session.currentUser.isManager')) {
        this.populateScheduleFromLodgeTemplates(stageToUpdate, originalRegion, originalLodge)
      } else {
        this.populateScheduleFromRegionTemplates(stageToUpdate, originalRegion, originalLodge)
      }
    } else if (stageToUpdate.isRegionStage) {
      this.populateScheduleFromRegionTemplates(stageToUpdate, originalRegion, originalLodge)
    }
  },

  forceBlockItemToPosition(blockItems, blockItem, newPosition) {
    // console.log(arguments)
    // Find the current index of the element
    let currentIndex = blockItems.indexOf(blockItem);

    blockItems.removeAt(currentIndex);


    if (newPosition > currentIndex) {
        newPosition--;
    }

    blockItems.insertAt(newPosition, blockItem);
    blockItems.forEach((loopItem, index) => {
      if (!loopItem.isDeleted) {
        loopItem.set('sequence', index);
      }
    });

    blockItems.forEach((loopItem, index) => {

      // console.log(loopItem.blockItemType, loopItem.heading)

    });
  },

  changeTripLayout(options, layout) {
    let trip = options.trip;
    let oldLayout = trip.get('layout')
    if (oldLayout == layout) {
      return
    }
    // THe layout needs to be set first, other wise checkin blocks will not be set up
    trip.set('layout', layout)
    if (layout=='day-by-day') {
      this.addDayByDaySupport(trip)
    } else {
      this.removeDayByDayContent(trip)
    }
  },

  addDayByDaySupport(targetTrip) {
    targetTrip.itinerary.stages.forEach((stage)=> {
      if (stage.isLodgeOrRegionStage) {
        // Deal with content managed trips that have template schedules
        if (stage.get('templateSchedule') && !stage.get('customSchedule')) {
          let copy = stage.get('templateSchedule').makeCopy();

          if (copy.get('blocks.firstObject')) {
            let block = copy.get('blocks.firstObject')
            block.set('dayTitle', 'What you can do in '+stage.get('placeName'))
            block.set('dayImageTitle', stage.get('placeName'))
          }
          stage.set('customSchedule', copy)
        }


        this.setupCheckinBlock({stageToUpdate: stage})
      }
    })
    let finishStage = targetTrip.itinerary.stages.lastObject;
    if (finishStage && !finishStage.isLodgeOrRegionStage) {
      if (!finishStage.get('customSchedule')) {
        this.templateManager.addCustomSchedule({stage: finishStage, createBlock: true})
      }
      if (!finishStage.get('customSchedule').getBlockItemByTypeIfItExists('todays-preceding-stages')) {
        this.templateManager.addBlockItem({block: finishStage.get('customSchedule.blocks.firstObject'), blockItemType: 'todays-preceding-stages'})
      }
    }
    this.ensureTripSummaryBlockItem(targetTrip);

  },

  removeDayByDayContent(targetTrip) {
    targetTrip.itinerary.stages.forEach((stage)=> {
      if (stage.get('customSchedule')) {
        let blocksToRemove = []
        stage.get('customSchedule.blocks').forEach((block)=> {
          let blockItemsToRemove = []
          block.get('blockItems').forEach((blockItem)=> {
            if (blockItem.isDayByDayBlockItem) {
              blockItemsToRemove.pushObject(blockItem)
            }
          })
          blockItemsToRemove.forEach((blockItem)=> {
            this.templateManager.removeBlockItem({block, blockItem})
          })
          if (block.get('blockItems.length') == 0) {
            blocksToRemove.pushObject(block)
          }
        })
        blocksToRemove.forEach((block)=> {
          this.templateManager.removeBlock({schedule: stage.get('customSchedule'), block, stage})
        })
      }
    })
  },

  ensureTripSummaryBlockItem(targetTrip) {
    if (!targetTrip.get('itinerary.firstSchedule')) {
      let firstSchedule = this.templateManager.addCustomSchedule({createBlock: false})
      targetTrip.set('itinerary.firstSchedule', firstSchedule)
    }
    let tripSummaryBlockItem = targetTrip.get('itinerary.firstSchedule').getTripSummaryBlockItemIfItExists();


    if (!tripSummaryBlockItem) {
      let tripSummaryBlock = this.store.createRecord('block',
      {
        sequence: 0,
        dayTitle: 'Your upcoming trip',
        dayImageTitle: 'Trip summary'

      })
      targetTrip.get('itinerary.firstSchedule.blocks').unshiftObject(tripSummaryBlock)
      this.templateManager.addBlockItem({block: tripSummaryBlock, blockItemType: 'trip-summary'})
    }
  },

  setupCheckinBlock(options) {

    let stageToUpdate = options.stageToUpdate;
    let originalRegion = options.originalRegion;
    let originalLodge = options.originalLodge;

    if (!stageToUpdate.get('itinerary.trip.isLayoutDayByDay')) {
      return;
    }

    if (!stageToUpdate.get('customSchedule')) {
      this.templateManager.addCustomSchedule({stage: stageToUpdate})
    }

    let checkinBlock = stageToUpdate.get('customSchedule').getCheckinBlockIfItExists();
    let checkinBlockItem = null;

    if (!checkinBlock) {
      checkinBlock = this.store.createRecord('block',
      {
        sequence: 0,
        daysAfterStart: 0,
      })
      stageToUpdate.get('customSchedule.blocks').unshiftObject(checkinBlock)
    }

// THese seperate block items pre-date the combination of them into todays-preceding-stages bock item
    let todaysLocationsBlockItem = checkinBlock.get('blockItems').findBy('blockItemType', 'todays-locations');
    if (todaysLocationsBlockItem) {
      this.templateManager.removeBlockItem({block: checkinBlock, blockItem: todaysLocationsBlockItem})
    }
    let todaysTransfersBlockItem = checkinBlock.get('blockItems').findBy('blockItemType', 'todays-transfers');
    if (todaysTransfersBlockItem) {
      this.templateManager.removeBlockItem({block: checkinBlock, blockItem: todaysTransfersBlockItem})
    }

    let todaysPrecedingStagesBlockItem = checkinBlock.get('blockItems').findBy('blockItemType', 'todays-preceding-stages');
    if (!(todaysPrecedingStagesBlockItem)) {
      todaysPrecedingStagesBlockItem = this.templateManager.addBlockItem({block: checkinBlock, blockItemType: 'todays-preceding-stages'})
    }
    this.forceBlockItemToPosition(checkinBlock.get('blockItems'), todaysPrecedingStagesBlockItem, 0)

    if (stageToUpdate.isLodgeOrRegionStage) {
      checkinBlockItem = stageToUpdate.get('customSchedule').getCheckinBlockItemIfItExists();
      if (!checkinBlockItem) {
        checkinBlockItem = this.templateManager.addBlockItem({block: checkinBlock, blockItemType: 'checkin'})
      }
      this.forceBlockItemToPosition(checkinBlock.get('blockItems'), checkinBlockItem, 1)
      if (stageToUpdate.isLodgeStage) {
        checkinBlockItem.set('heading', 'Check in to '+stageToUpdate.get('lodge.name'))
        stageToUpdate.get('lodge.lodgeInfo').then((lodgeInfo) => {
          checkinBlockItem.set('overview', lodgeInfo.get('overview'))
        })
      } else {
        checkinBlockItem.set('heading', 'Check in to your '+stageToUpdate.get('placeName')+' property')
        checkinBlockItem.set('overview', null)

      }
    }

    // if (this.whitelabel.agency.addAboutRegionElements) {
      let aboutRegionBlockItem = checkinBlock.get('blockItems').findBy('blockItemType', 'about-region');
      if (!aboutRegionBlockItem) {
        aboutRegionBlockItem = this.templateManager.addBlockItem({block: checkinBlock, blockItemType: 'about-region'})
      }
      this.forceBlockItemToPosition(checkinBlock.get('blockItems'), aboutRegionBlockItem, 2)
    // }



    if ( !originalRegion || !(stageToUpdate.get('regionProxy.associatedRegions').includes(originalRegion))) {
      checkinBlock.setProperties({
        dayTitle: `${stageToUpdate.placeName}`,
        dayImageTitle: stageToUpdate.get('entity.name'),
        overrideDayImageUrl: stageToUpdate.get('entity.heroEntity.kodakOriginalUrl'),
        overrideDayImageCoverStyle: stageToUpdate.get('entity.heroEntity.coverStyle')
      })

      // if (this.whitelabel.agency.addAboutRegionElements) {
        if (aboutRegionBlockItem) {
          aboutRegionBlockItem.set('heading', 'About '+stageToUpdate.get('regionProxy.name'))
        }
      // }
    }
    return [checkinBlock, checkinBlockItem];

  },

  replaceScheduleFromTemplate(options) {
    let defaultSchedules = options.defaultSchedules;
    let stageToUpdate = options.stageToUpdate;

    // Force creation of new custom schedule
    this.templateManager.addCustomSchedule({stage: stageToUpdate})
    this.templateManager.allocateCopyOfScheduleToStage({schedule: defaultSchedules.objectAt(0), stage: stageToUpdate})
    this.setupCheckinBlock(options)
    this.set('showShouldOverrideScheduleModalOptions', null)
  },

  retainScheduleForNewLodge(options) {
    this.setupCheckinBlock(options)
    this.set('showShouldOverrideScheduleModalOptions', null)
  },

  // This allows you to shortcut the process of showing the lodge removal confirmation modal
  goRemoveLodge(options) {
    this.set('stageForLodgeRemoval', options.stage)
    this.doRemoveLodge();
  },

  doRemoveLodge() {

    let polishLevel = this.get('stageForLodgeRemoval.lodge.isCustom') ? 'own-arrangement' : 'lodge';
    trackEvent(`trip:${polishLevel}:remove`, {
      tripModifyFlag: true
    });

    let region = this.get('stageForLodgeRemoval.region') || this.get('stageForLodgeRemoval.lodge.region')

    this.stageForLodgeRemoval.setProperties({
      region,
      lodge: null,
      stageType: 'region',
      madeTransferAffectingChanges: true,
      whatsIncluded: null,
      whatsExcluded: null,
      overviewWhyLove: null
    });

    let [checkinBlock, checkinBlockItem] = this.setupCheckinBlock({stageToUpdate: this.stageForLodgeRemoval})
    let scrollToCurrentBlock = false;
    let scrollToCurrentBlockItem = false;
    if (checkinBlock && checkinBlockItem) {
      this.set('tripService.currentBlockIndex', this.stageForLodgeRemoval.get('customSchedule.blocks').indexOf(checkinBlock))
      this.set('tripService.currentBlockItemIndex', checkinBlock.get('blockItems').indexOf(checkinBlockItem))
      scrollToCurrentBlock=true;
      scrollToCurrentBlockItem=true;
    }
    this.set('currentStageIndex', this.get('currentTrip.itinerary.stages').indexOf(this.stageForLodgeRemoval));

    this.madeChanges();
    this.set('stageForLodgeRemoval', null);
    this.focusStage({scrollToCurrentBlock, scrollToCurrentBlockItem})
  },

  goChooseLodge(options /* {stage} */ ) {

    console.log(options)
    if (this.shouldBlockChangesToTrip()) {
      return;
    }
    let stage = options.stage;
    let eventName = stage.lodge ? 'trip:change-lodge:start' : 'trip:choose-lodge:start';
    this.set('chooseEntityOptions', options)
    options.choosingEntity=true;

    trackEvent(eventName, {
      tripModifyFlag: true
    });

    this.setProperties({
      returnRoute: this.get('router.currentRouteName'),
      hasBackButton: true
    });

    let queryParams = this.getQueryParamsForChoosingLodge(options)

    this.setPricingAvailablityDates(stage);
    this.set('currentStageIndex', this.get('currentTrip.itinerary.stages').indexOf(stage));
    if (options.blockItem) {
      this.set('tripService.currentBlockIndex', stage.get('customSchedule.blocks').indexOf(options.block))
      this.set('tripService.currentBlockItemIndex', options.block.get('blockItems').indexOf(options.blockItem))
    }

    if (this.get('startDateForPricingAndAvailability') && this.get('endDateForPricingAndAvailability')) {
      queryParams.startDate = serializeDate(this.get('startDateForPricingAndAvailability'));
      queryParams.endDate = serializeDate(this.get('endDateForPricingAndAvailability'));
    }

    this.get('router').transitionTo('lodges', {
      queryParams: queryParams
    });

    this.get('historyService').enterSuspension({
      name: 'choose-lodge'
    });
  },

  // Note that right now the statedate and end dates for pricing and avaialbility are only handled later when you acutally go and choose a lodge
  getQueryParamsForChoosingLodge(options /* {stage} */ ) {

    let stage = options.stage;

    let regions = [];
    let countries = [];
    let experiences = [];
    let autoBudgetNames = [];

    if (options.filterRegions || options.filterCountries) {
      if (options.filterRegions) {
        regions = options.filterRegions
      }
      if (options.filterCountries) {
        countries = options.filterCountries
      }
    } else if (stage.get('regionProxy')) {
      regions = [stage.get('regionProxy')];

      stage.get('regionProxy.associatedEntities') && stage.get('regionProxy.associatedEntities').forEach((associatedEntity)=> {
        if (associatedEntity.get('associatedRegion')) {
          regions.pushObject(associatedEntity.get('associatedRegion'))
        }
      })
      if (stage.get('budgetFilters')){
        stage.get('budgetFilters').forEach((filter) => {
          autoBudgetNames.pushObject(filter.get('autoBudget'));
        });
      }
    } else {
      stage.get('stageFilters').forEach((stageFilter) => {
        if (stageFilter.get('autoBudget')){
          autoBudgetNames.pushObject(stageFilter.get('autoBudget'));
        }
        regions.pushObjects(stageFilter.get('filterRegions'));
        countries.pushObjects(stageFilter.get('filterCountries'));
      })
    }

    // The experience filters still need to be applied even once a region has been selected
    stage.get('stageFilters').forEach((stageFilter) => {
      experiences.pushObjects(stageFilter.get('experiences').toArray());
    })

    // This is basically to catch lodges that dont have a region. so that you filter by other lodges in that country.
    if (regions.length == 0 && countries.length ==0 && experiences.length == 0 && stage.get('countryProxy')) {
      countries.pushObject(stage.get('countryProxy'));
    }

    let queryParams = {
      changingLodge: true,
      filters: getEntityFilters({regions, countries, experiences}),
      autoBudget: autoBudgetNames
    };
    console.log(queryParams)

    return queryParams;
  },

  getLodgePrice(params, namedArgs) {

    // Basically what we are doing here is we show the fromPrice from the lodge unless the lodge has accurate pricing which matches the start date being used
    // If a stage is passed through we use the start date and accurate pricing from the stage rather than from the lodge
    // Note that we pass through all the properties seperately rather than just teh stage or the lodge to ensure that the help recomputes when these properties change
    // It is possible that the stageAccurateXXX properties and lodgeAccurateXXX properties can be passed through for example on the lodge card. Stage properties will always trump lodge properties
    // but there is scope for both, for exanple on the itinerary map the ldoge card wants to use stage properties for accurate pricing especailly if the same lodge is in an itinerary twice
    // while when looking at a list of lodges on lodge listing page, you need to use the lodge accurateXXX properties as there are no stages for those lodges

    var markPricesUp = namedArgs.markPricesUp;
    if (markPricesUp === undefined) {
      markPricesUp = true;
    }

    // let accuratePriceEntity = namedArgs.lodge; // This can either come from a lodge or a stage depending if showing in itinerary on lodges listing
    let roundToClosest = namedArgs.roundToClosest || 10;
    let truncate = true;
    let price = namedArgs.lodge.get('fromPrice');
    let fromCurrency = namedArgs.lodge.get('fromPriceCurrency');

    let startDate = namedArgs.startDate;
    if (!startDate) {
      startDate = this.get('startDateForPricingAndAvailability');
    }
    if (namedArgs.stageStartDate) {
      // accuratePriceEntity = namedArgs.stage;
      startDate = namedArgs.stageStartDate;
      // console.log(namedArgs.stage.get('accuratePriceAmount'));
    }

    if (startDate && namedArgs.stageAccuratePriceDate && namedArgs.stageAccuratePriceAmount && namedArgs.stageAccuratePriceCurrency && moment(startDate).format('YYYY-MM-DD') === moment(namedArgs.stageAccuratePriceDate).format('YYYY-MM-DD')) {
      // console.log('setting accurate price')
      price = namedArgs.stageAccuratePriceAmount;
      fromCurrency = namedArgs.stageAccuratePriceCurrency;
    } else if (startDate && namedArgs.lodgeAccuratePriceDate && namedArgs.lodgeAccuratePriceAmount && namedArgs.lodgeAccuratePriceCurrency && moment(startDate).format('YYYY-MM-DD') === moment(namedArgs.lodgeAccuratePriceDate).format('YYYY-MM-DD')) {
      // console.log('setting accurate price')
      price = namedArgs.lodgeAccuratePriceAmount;
      fromCurrency = namedArgs.lodgeAccuratePriceCurrency;
    } else {
      // console.log('missing acc price data')
    }

    // TODO ian - we need to come up with a better soltution. right now lodge rate estimates don't get a price if there is only a from price for a lodgez
    // we need to comp
    if (price == 0) {
      price = namedArgs.lodge.get('fromPrice');
      fromCurrency = namedArgs.lodge.get('fromPriceCurrency');
    }

    let lodgePrice = "";
    if (namedArgs.lodge.get('hasPrice')) {
      lodgePrice += '<span class="price">';
      if (namedArgs.style) {
        lodgePrice += '<span class="amount">';
      }

      lodgePrice += formatCurrencyHash({
        val: price,
        fromCurrency: fromCurrency,
        toCurrency: namedArgs.toCurrency || this.get('settings.currentCurrency'),
        roundToClosest: roundToClosest,
        isText: namedArgs.isText || false,
        truncate: truncate,
        markPricesUp
      });

      if (namedArgs.style) {
        lodgePrice += ' </span>';
      }

      if (!namedArgs.excludePersonPerNight) {
        if (namedArgs.style) {
          lodgePrice += '<span class="unit">person/night</span>';
        } else {
          lodgePrice += " person/night";
        }
      }

    } else {
      lodgePrice += '<span class="price unpriced"> Price on request';
    }

    lodgePrice += "</span>";
    return htmlSafe(lodgePrice);
  },


  /*---------------------
      Region
  -----------------------*/

  goChooseRegion(options /* {stage} */ ) {

    if (this.shouldBlockChangesToTrip()) {
      return;
    }

    let stage = options.stage;
    let regions = [];
    let countries = [];
    let experiences = [];

    if (options.filterRegions || options.filterCountries) {
      if (options.filterRegions) {
        regions = options.filterRegions
      }
      if (options.filterCountries) {
        countries = options.filterCountries
      }
    } else if (stage.get('regionProxy')) {
      countries.pushObject(stage.get('regionProxy.country'));
    } else {
      stage.get('stageFilters').forEach((stageFilter) => {
        regions.pushObjects(stageFilter.get('filterRegions'));
        countries.pushObjects(stageFilter.get('filterCountries'));
      })
    }

    stage.get('stageFilters').forEach((stageFilter) => {
      experiences.pushObjects(stageFilter.get('experiences').toArray());
    })


    let currentRegionIds = this.get('currentTrip.regions.length') > 0 ? this.get('currentTrip.regions').mapBy('id') : [];
    let currentContinentNames = this.get('currentTrip.regions.length') > 0 ? this.get('currentTrip.regions').mapBy('country').mapBy('continentName').uniq() : [];
    let eventName = stage.region ? 'trip:change-region:start' : 'trip:choose-region:start';

    trackEvent(eventName, {
      tripModifyFlag: true
    });

    options.choosingEntity=true;
    this.setProperties({
      returnRoute: this.get('router.currentRouteName'),
      hasBackButton: true,
      chooseEntityOptions: options
    });

    let queryParams = {
      changingRegion: true,
      filters: getEntityFilters({regions, countries, experiences}),
      currentRegionIds: currentRegionIds,
    };

    if (regions.length == 0 && currentRegionIds.length == 0 && countries.length == 0 && experiences.length == 0) {
      queryParams.continents = currentContinentNames;
    }

    // this.setPricingAvailablityDates(stage);
    this.set('currentStageIndex', this.get('currentTrip.itinerary.stages').indexOf(stage));

    // if (this.get('startDateForPricingAndAvailability') && this.get('endDateForPricingAndAvailability')) {
    //   queryParams.startDate = serializeDate(this.get('startDateForPricingAndAvailability'));
    //   queryParams.endDate = serializeDate(this.get('endDateForPricingAndAvailability'));
    // }

    this.get('router').transitionTo('regions', {
      queryParams: queryParams
    });

    this.get('historyService').enterSuspension({
      name: 'choose-region'
    });
  },

  doChangeFoundNewRegion(options = { /* region, scrollToStage */ }) {
    let region = options.entity || options.region;
    if (!region) {
      return;
    }

    let needToInsertNewStage = this.get('currentTrip.itinerary.stages').indexOf(this.get('currentStage')) === -1;
    let stageToUpdate = needToInsertNewStage ? this.newStage : this.currentStage;
    let originalRegion = stageToUpdate.get('region')
    let originalLodge = stageToUpdate.get('lodge')
    // Default would be to make this a region stage, but you can set the region on a lodge stage in order to override the region of the lodge
    let stageType ='region';
    if (stageToUpdate.lodge && stageToUpdate.isLodgeStage) {
      stageType='lodge'
    }

    stageToUpdate.setProperties({
      stageType,
      region: region,
      madeTransferAffectingChanges: true
    });

    // if stage is not inserted into trip yet do so - its a new stage
    if (needToInsertNewStage) {
      this.addNewStageToTrip({callMadeChanges: false, scrollToStage: options.scrollToStage});
    } else {
      this.populateDefaultTemplates(stageToUpdate, originalRegion, originalLodge);
    }


    this.get('tripService').madeChanges();

    trackEvent('trip:change-region:complete', {
      tripModifyFlag: true
    });

    if (this.get('returnRoute')) {
      this.get('router').transitionTo(this.get('returnRoute'), this.get('currentTrip'));
      this.set('returnRoute', null);
    }

    let scrollToStage = true;
    if (options.scrollToStage===false) {
      scrollToStage=false;
    }
    if (scrollToStage) {
      this.focusStage();
    }

    this.focusStage();

    this.set('hasBackButton', false);
    this.set('chooseEntityOptions', null);
  },


  /*---------------------
      Brochure
  -----------------------*/

  emailBrochureToCurrentUser(options={/*url*/}) {
    // We need to make sure that pdfgen uses a non-cached url, we had a bug where the crop style was changed on a regional experience image
    // THe trip xhr request loaded it correctl but the cached regionInfo request had the old version, so then the url of the image changed mid-request and messed up the rendering service tracking images being loaded


    if (options.url.indexOf('buster=true')<=0) {
      if (options.url.indexOf('?')) {
        options.url=options.url+"&buster=true"
      } else {
        options.url=options.url+"?buster=true"
      }
    }

    return this.currentTrip.itinerary.emailBrochureToCurrentUser(options)
  },

  /*---------------------
      Stage
  -----------------------*/

  focusStage(options={/*scrollToLodge, scrollToCurrentBlock*/}) {
    console.log(arguments)
    // cancel the focus stage to avoid multiple animations. Essentially debounces calls to this method
    run.cancel(this.stageFocusTimer);
    this.stageFocusTimer = run.later(() => {
      // take over the reigns
      this.set('locationScrollHistory.restoreScrollManually', true);
      // open the stage drawer
      this.set('currentStage.stageExpanded', true);
      // find the stage dom node

      let cssSelector = `.stages-count-container .stage-counter:eq(${this.get('currentStageIndex')})`;


      // -50 caters for the size of the fixed nav header
      let offset = -50;
      // console.log(this.router.currentRouteName)
      if (this.router.currentRouteName=='trip.index.index') {
        if ((options.scrollToCurrentBlock || options.scrollToCurrentBlockItem) && this.currentTrip.isLayoutDayByDay) {
          offset=-200;
          cssSelector = ` ${cssSelector} .block-counter:eq(${this.get('currentBlockIndex')})`;
          if (options.scrollToCurrentBlockItem && this.currentTrip.isLayoutDayByDay) {
            cssSelector = ` ${cssSelector} .block-item-counter:eq(${this.get('currentBlockItemIndex')})`;
          }
        } else if (options.scrollToLodge) {
          offset=-98;
          cssSelector = `${cssSelector} .js-scroll-to-lodge`
        }


      } else if (this.router.currentRouteName=='trip.index.editor') {
        // -108 necessary showcases the header for the lodge stage on the editor
        offset=-108;

      } else if (this.router.currentRouteName=='trip.index.edit.itinerary') {
        cssSelector = $(".is-component-for-real-stage:eq(" + this.get('currentStageIndex') + ")");
      }
      let context = this;
      function actuallyFocusStage() {
        let stageToFocus = $(cssSelector);
        // console.log(cssSelector, stageToFocus)
        if (stageToFocus && stageToFocus.length > 0) {
          // if there are open modals (specifically for mobile cases), schedule focus for after
          if (context.get('scroll.openModals.length')) {
            context.set('scroll.onRemoveModal', context.focusStage.bind(context));
          } else {
            // else just focus immediately
            showInViewPort(stageToFocus, offset, 300);
          }
          return true;
        }
      }
      if (!actuallyFocusStage()) {
        var interval = setInterval(function() {
          if (actuallyFocusStage()) {
            clearInterval(interval);
          }
        }, 200);
      }

    }, 400);
  },

  incrementStageNights(stage) {
    trackEvent(`trip:lodge:change-nights`, {
      tripModifyFlag: true
    });
    stage.incrementProperty('numNights');
    this.madeChanges();

    trackEvent('trip:stage:change-nights:increase', {
      tripModifyFlag: true
    });
  },

  decrementStageNights(stage) {
    trackEvent('trip:stage:change-nights:decrease', {
      tripModifyFlag: true
    });

    if (stage.get('numNights') > 0) {
      stage.decrementProperty('numNights');
      this.madeChanges();
    } else {
      this.willRemoveStage(stage);
    }
  },

  closeEntityModalsAndCreateStage(options) {
    // THis method is enough for now. We might need more sophisticated handling of these actions later
    this.entityModals.closeModal()
    this.createStage(options)
  },

  addStageFromSearchSuggestion(options, searchSelection) {
    if (searchSelection.action == "search:select" && searchSelection.result) {
      if (searchSelection.type == "lodge") {

      } else if (searchSelection.type == "region") {
      }
    }
    let stageOptions = {type: searchSelection.type, model: searchSelection.result}
    this.createStage(stageOptions)

  },

  createStage(options = { /* type, route, model, stageType, numNights */ }) {

    if (this.shouldBlockChangesToTrip()) {
      return;
    }

    if (options.type == 'custom-entity') {
      options.stageType = options.entityType;
    } else if (options.type == 'add-on') {
      options.stageType = 'region';
    } else {
      options.stageType = options.type;
    }
    let numNights = options.numNights == undefined ? 3 :  options.numNights;

    if (options.stageType=='location' && !options.numNights) {
      numNights=0;
    }

    this.set('newStage', this.get('store').createRecord('stage', {
      stageType: options.stageType,
      numNights: numNights,
      madeTransferAffectingChanges: true
    }));

    if (!isNaN(options.newStageIndex)) {
      this.set('newStageIndex', options.newStageIndex);
      if (options.newStageIndex == 0 && ['region', 'lodge'].includes(options.stageType) && this.get('currentTrip.itinerary.stages.length') > 0) {
        this.set('newStage.addNewStartLocation', true)
      }
    }

    trackEvent('trip:stage:new:' + options.type, {
      tripModifyFlag: true
    });

    this.set('currentStageIndex', null);

    if (options.model) {
      switch (options.type) {

        case 'region':
          this.get('newStage').setProperties({
            region: options.model
          });
          this.addNewStageToTrip();
          break;

        case 'lodge':
          this.get('newStage').setProperties({
            lodge: options.model
          });
          this.addNewStageToTrip();
          break;

        case 'location':
          this.get('newStage').setProperties({
            location: options.model
          });
          this.addNewStageToTrip();
          break;

        case 'add-on':
          // if is single entity, create stage directly
          if (options.model.get('stageFilter.isSingleEntity')) {
            this.get('newStage').setProperties({
              region: options.model.get('stageFilter.filterRegions.firstObject')
            });
          }

          this.get('store').createRecord('filter-join', {
            stageFilter: options.model.get('stageFilter'),
            stage: this.get('newStage')
          });

          this.set('newStageIndex', null);

          this.addNewStageToTrip();
          this.get('flashMessages').success('Added to trip');
          break;

        default:
          break;
      }
    } else {

      let ownArrangementsOptions = {};

      switch (options.type) {
        case 'location':
          // options.stage = this.newStage;
          this.set('showChooseLocationModal', this.newStage)
          break;
        case 'lodge':
          options.stage = this.newStage;
          this.goChooseLodge(options);
          break;
        case 'custom-entity':
          ownArrangementsOptions = {
            entityType: options.stageType,
            heading: `Add your own ${options.entityType}`,
            startSearch: options.startSearch,
            subheading: this.get('whitelabel.showManagerTools') ? null : 'Staying with friends or organised your own accommodation already? Add this to your trip',
            stage: this.newStage,
            choosingEntity: true
          }
          this.editCustomEntity(ownArrangementsOptions);
          break;
        case 'region':
          options.stage = this.newStage;
          this.goChooseRegion(options);
          break;
        case 'custom-transfer':
          let customTransfer = this.store.createRecord('transfer', {
            transferType: 'custom',
            vehicleType: 'general'
          });
          this.set('newStage.customTransfer', customTransfer);
          this.set('newStage.numNights', 0);
          this.addNewStageToTrip();
          break;
      }
    }
  },

  addNewStageToTrip(options = {}) {
    let stages = this.get('currentTrip.itinerary.stages');

    // If the new stage entity has a region and this is the first stage being added to a trip then we can add in a start and end location automatically
    let defaultLocation = this.get('newStage.regionProxy.defaultLocation') || this.get('newStage.regionProxy.country.defaultLocation')
    if (this.newStage.entityRegionId && defaultLocation && stages.length == 0) {

      let startStage = this.get('store').createRecord('stage', {
        stageType: 'location',
        numNights: 0,
        location: defaultLocation
      });
      stages.insertAt(0, startStage);

      let finishStage = this.get('store').createRecord('stage', {
        stageType: 'location',
        numNights: 0,
        location: defaultLocation
      });
      stages.addObject(finishStage);
      if (this.get('currentTrip.isLayoutDayByDay')) {
        this.templateManager.addCustomSchedule({stage: finishStage, createBlock: true})
        this.templateManager.addBlockItem({block: finishStage.get('customSchedule.blocks.firstObject'), blockItemType: 'todays-preceding-stages'})
      }


    }

    let index= 0 //Cater for case where there are no stages to iterate over
    if (this.get('newStageIndex') === null || isNaN(this.newStageIndex)) {
      let count = 0;
      this.get('currentTrip.itinerary.stages').forEach((stage)=> {
        // Find the last non-location and add the new lodge after that.
        if (index > 0 && !stage.isLocationStage || index == 0) {
          // We want to insert it after the matching stage
          index = count +1;
        }
        count = count + 1;
      })
    } else {
      index = parseInt(this.get('newStageIndex'));
    }
    this.get('currentTrip.itinerary.stages').insertAt(index, this.get('newStage'));
    if (this.newStage.addNewStartLocation==true) {
      // This is for case where we adding new stage for first stage
      let newStartLocationStage = this.get('store').createRecord('stage', {
        stageType: 'location',
        numNights: 0,
        location: defaultLocation
      });
      this.get('currentTrip.itinerary.stages').insertAt(0, newStartLocationStage);
      // Index now needs to point at the new stage rather than new starting location
      index=1;
      this.set('newStage.addNewStartLocation', false)
    }

    let nextTransStage = nextTransferStage(stages, index);
    let nextNonTransStage = nextNonTransferStage(stages, index);

    let prevTransStage = prevTransferStage(stages, index);
    let prevNonTransStage = prevNonTransferStage(stages, index);

    // If we are adding a non-transfer stage (lodge/region) we want to add transfers from the previous stage if it exists
    // and to the next stage if it exists.

    // add transfer stage from new stage to next stag
    if (!this.newStage.isTransferStage && nextNonTransStage && !(nextTransStage && stages.indexOf(nextTransStage) === index + 1)) {
      let newTransferStage = this.get('store').createRecord('stage', {
        stageType: 'template-transfer',
        numNights: 0,
        needsTransfer: true
      });
      stages.insertAt(index+1, newTransferStage)
    }

    // add transfer stage from previous stage to next stag
    if (!this.newStage.isTransferStage && prevNonTransStage && !(prevTransStage && stages.indexOf(prevTransStage) === index - 1)) {
      let newTransferStage = this.get('store').createRecord('stage', {
        stageType: 'template-transfer',
        numNights: 0,
        needsTransfer: true
      });
      stages.insertAt(index, newTransferStage)
    }

    if (options.callMadeChanges!==false) {
      this.madeChanges();
    }

    this.set('currentStageIndex', stages.indexOf(this.get('newStage')));
    if (this.get('currentStage.isLodgeOrRegionStage')) {
      this.populateDefaultTemplates(this.get('currentStage'), null, null);
    }

    this.setProperties({
      newStage: null,
      newStageIndex: null
    });
    // Default to scrolling to stage
    let scrollToStage = true;
    if (options.scrollToStage===false) {
      scrollToStage=false;
    }
    if (scrollToStage) {
      this.focusStage();
    }

  },

  // updateStage(options = { /* stage, type, model, route */ }) {
  //   options.stage.setProperties({
  //     stageType: options.type
  //   });

  //   trackEvent('trip:stage:update:' + options.type, {
  //     tripModifyFlag: true
  //   });

  //   // do we need this?
  //   this.set('currentStageIndex', null);

  //   switch (options.type) {
  //     case 'region':
  //       options.stage.setProperties({
  //         lodge: null,
  //         region: options.model
  //       });
  //       break;

  //     case 'lodge':
  //       options.stage.setProperties({
  //         lodge: options.model,
  //         region: null
  //       });
  //       break;

  //     case 'add-on':
  //       options.stage.setProperties({
  //         lodge: null,
  //         region: null
  //       });
  //       break;

  //     default:
  //       break;
  //   }

  //   this.madeChanges();

  //   if (options.route) {
  //     this.get('router').transitionTo(options.route);
  //   }
  // },

  willRemoveStage(stage) {
    this.setProperties({
      stageToRemove: stage,
      showConfirmStageDeletionModal: true
    });
  },

  doRemoveStage() {
    let stageIndex = this.get('currentTrip.itinerary.stages').indexOf(this.get('stageToRemove'));
    let redundantTransferStage = nextTransferStage(this.get('currentTrip.itinerary.stages'), stageIndex);

    if (this.get('currentTrip.itinerary.stages').indexOf(redundantTransferStage) === stageIndex + 1) {
      redundantTransferStage.deleteRecord();
      // Note that we can simply unload the record as we do an embedded save of all records. The server is already set up to assume any entries missing from
      // the payload are deleted, so we dont need call save on the deleted record
      this.store.unloadRecord(redundantTransferStage)
    }

    this.flagStageTransfersForUpdate(this.get('stageToRemove'));

    this.get('stageToRemove').deleteRecord();
      // Note that we can simply unload the record as we do an embedded save of all records. The server is already set up to assume any entries missing from
      // the payload are deleted, so we dont need call save on the deleted record
    this.store.unloadRecord(this.get('stageToRemove'))
    // console.log("tripService: removed stage id=", this.get('stageToRemove.id'));
    this.setProperties({
      stageToRemove: null
    });
    trackEvent(`trip:remove-lodge`, {
      tripModifyFlag: true
    });

    this.madeChanges();
    this.set('showConfirmStageDeletionModal', false);
  },

  flagStageTransfersForUpdate(stage){
    let stageIndex = this.get('currentTrip.itinerary.stages').indexOf(stage),
     prevStage = prevNonTransferStage(this.get('currentTrip.itinerary.stages'), stageIndex),
     nextStage = nextNonTransferStage(this.get('currentTrip.itinerary.stages'), stageIndex);

    if (prevStage){
      prevStage.set('madeTransferAffectingChanges', true);
    }

    if (nextStage){
      nextStage.set('madeTransferAffectingChanges', true);
    }
  },


  /*---------------------
      custom lodges
  -----------------------*/

  stopChoosingGpsLocation() {
    this.set('editEntityModalOptions.updateExistingGpsLocation', false);
    this.set('editEntityModalOptions.chooseGpsOnMap', false);
  },

  editCustomEntity(options = { /* heading, subheading, returnRoute, entity, entityType, stage */ }) {

    if (!options.entityType) {
      alert('mandatory to supply entityType')
    }

    if (!options.entity) {
      options.entity = this.get('store').createRecord(options.entityType);
      if (this.get('session.currentUser.isManager')) {
        options.entity.set('shareWithAgency', true);
      }
      options.entity.set('entityInfo', this.get('store').createRecord(`${options.entityType}Info`));
    }

    options.heading = options.heading ? options.heading : null;
    options.subHeading = options.subheading ? options.subheading : null;

    this.setProperties({
      editEntityModalOptions: options
    });
  },

  createDuplicateImage(metaImage) {
    return Ember.Object.create({
      location: metaImage.originalUrl,
      coverStyle: metaImage.coverStyle,
      type: 'duplicate',
      enabled: true
    })
  },

  cloneEntityAndEdit(options = { /* heading, subheading, returnRoute, entity, entityType, stage */ }) {

    if (!options.entityType) {
      alert('mandatory to supply entityType')
    }
    if (!options.entity) {
      alert('mandatory to supply enitity to clone')
    }
    if (options.stage) {
      this.set('currentStage', options.stage)
    }

    let originalEntity=options.entity;
    options.entity=originalEntity.makeCopy()
    options.entity.set('originalEntity', originalEntity)
    options.entity.set('internalDescription', null)

    options.entity.set('replaceImages', generateEmptyReplaceImages())
    if (originalEntity.metaImages) {
      originalEntity.metaImages.forEach((metaImage)=> {
        let duplicateImage = this.createDuplicateImage(metaImage)
        if (originalEntity.metaImageUsedAsKodak && originalEntity.metaImageUsedAsKodak.originalUrl == duplicateImage.originalUrl) {
          options.entity.set('metaImageUsedAsKodak', duplicateImage)
          duplicateImage.set('use_as_kodak_image', true)
        }
        options.entity.get('replaceImages.images').unshiftObject(duplicateImage);
      })
    }

    if (!(options.entity.get('metaImageUsedAsKodak')) && originalEntity.kodakOriginalUrl) {
      let kodakImage = Ember.Object.create({
        location: originalEntity.kodakOriginalUrl,
        coverStyle: originalEntity.coverStyle,
        type: 'duplicate',
        enabled: true,
        use_as_kodak_image: true
      })
      options.entity.get('replaceImages.images').unshiftObject(kodakImage);
    }

    options.entity.get('replaceImages.images').forEach((metaImage, index)=> {
      metaImage.set('sequence',index);
    })

    if (this.get('session.currentUser.isManager')) {
      options.entity.set('shareWithAgency', true);
    }
    originalEntity.get('entityInfo').then((entityInfo)=>{
      options.entity.set('entityInfo', entityInfo.makeCopy());
      this.editCustomEntity(options)
    })
  },

  checkImagesUpdatedForCustomEntity(entityType, entity, targetReplaceImagesVersion) {
    if (entity.replaceImagesVersion != String(targetReplaceImagesVersion)) {
      run.later(() => {
        return this.get('store').queryRecord(entityType, {
          id: entity.id,
          buster: true
        }).then((refreshedEntity)=> {
          if (refreshedEntity.replaceImagesVersion != String(targetReplaceImagesVersion)) {
            this.checkImagesUpdatedForCustomEntity(entityType, entity, targetReplaceImagesVersion);
          } else {
            entity.set('replaceImages', null)
          }
        })
      }, 1000)
    } else if (entity.replaceImagesVersion && entity.replaceImagesVersion == String(targetReplaceImagesVersion)) {
      // We need this check because the entity can also be side loaded through a price-request for example
      entity.set('replaceImages', null)
    }


  },

  saveCustomEntity(options) {

    let targetReplaceImagesVersion = options.entity.replaceImages && options.entity.replaceImages.timestamp;
    console.log('targetReplaceImagesVersion', targetReplaceImagesVersion)
  // Note that locations dont have an entityInfo


    return options.entity.get('entityInfo').then((entityInfo)=> {
      let originalEntityInfo = entityInfo;
      // console.log(options)
      return options.entity.save().then((entity) => {
        return entity.get('entityInfo').then((entityInfo) => {
          let entityInfoClass = this.getEntityInfoClass(options);

          entityInfoClass.eachAttribute((name) => {
            entityInfo.set(name, originalEntityInfo.get(name));
          })
          return entityInfo.save().then(()=> {
            if (targetReplaceImagesVersion) {
              this.checkImagesUpdatedForCustomEntity(options.entityType, entity, targetReplaceImagesVersion)
            }
            // console.log(options.specifyNewImages)
            if (options.specifyNewImages) {
              options.specifyNewImages=false;
              return options.entity.specifyReplaceImages({replaceImages: options.entity.replaceImages});
            } else {
              return new RSVP.Promise(function(resolve) {
                resolve();
              });
            }
          });

        })
      })
    })
  },


  saveCustomEntityAndStopManagingPhotos(options) {

    this.set('editEntityModalOptions.managePhotos', false);
    options.specifyNewImages=true

    return this.saveCustomEntity(options)
  },

  saveCustomEntityAndCompleteWorkflow(options) {

    if (this.whitelabel.isOnboardingAgency) {
      return this.set('ui.showWaybirdSignupModal', true)
    }

    this.set('savingCustomEntity', true);
    return this.saveCustomEntity(options).then(() => {
      this.set('editEntityModalOptions', false);
      if (options.entityType=='lodge' && options.stage && options.choosingEntity) {
        this.doChangeFoundNewLodge(options)
      } else if (options.entityType=='region' && options.stage && options.choosingEntity) {
          this.doChangeFoundNewRegion(options)
      } else if (options.afterCustomEntityCreateAction) {
        options.afterCustomEntityCreateAction();
      }
    })
    //NB Please note you can't capture the error here are it needs to propagate back to the form to highlight manadatory form fields that are missing

  },

  getEntityInfoClass(options) {
    switch (options.entityType) {
      case 'region':
        return RegionInfo;
      case 'lodge':
        return LodgeInfo;
      case 'experience':
        return ExperienceInfo;
    }
    return null;
  },



  /*---------------------
      Trip designer tour
  -----------------------*/

  skippedDesignSteps: [],

  @computed('currentTrip.itinerary.startDate', 'currentTrip.itinerary.nonTransferAndLocationStages.[]', 'currentTrip.itinerary.allStagesHaveLodges', 'skippedDesignSteps.@each', 'currentTrip.itinerary.stateQuoteAndAfter')
  tripDesignerStep(tripStartDate, nonTransferAndLocationStages, allStagesHaveLodges, skippedDesignSteps, stateQuoteAndAfter) {
    if (stateQuoteAndAfter) {
      return;
    } else if (!tripStartDate && !skippedDesignSteps.includes('add-date')) {
      return 'add-date';
    } else if (isEmpty(nonTransferAndLocationStages) && !skippedDesignSteps.includes('add-stage')) {
      return 'add-stage';
    } else if (!nonTransferAndLocationStages.get('firstObject.lodge') && !skippedDesignSteps.includes('add-lodge')) {
      return 'add-lodge';
    } else if (nonTransferAndLocationStages.get('length') >= 2 && allStagesHaveLodges && !skippedDesignSteps.includes('get-quote')) {
      return 'get-quote';
    }
  },

  skipDesignStep(step) {
    trackEvent('trip-designer:skip-step');
    this.get('skippedDesignSteps').pushObject(step);
  },

  /*-------------------
      Trip ideas
  ---------------------*/

  applyingTripIdeaInTransition: false,
  currentlyAppliedTripIdea: null,
  customTripIdea: {
    tripIdeaKodak: 'https://waybird.imgix.net/assets/images/general/choose-your-own-lodges.JPG',
    tripIdeaType: 'custom'
  },

  // these properties get reset in trip.index didTransition
  applyTripIdeaFromQueryParam(options = {/*trip, transition */}){

    // only apply trip idea once and dont apply if its already applied on every transition
    let tripIdeaType = options.transition.queryParams.tripIdea;
    if (this.applyingTripIdeaInTransition || this.currentlyAppliedTripIdea === tripIdeaType) {
      return
    }


    this.set('applyingTripIdeaInTransition', true);
    this.set('currentlyAppliedTripIdea', tripIdeaType);

    //Even though this relationship is async, you can filterBy a property which will give you results from entries in the hasMany that have already been loaded into the store
    let loadedTripIdea = options.trip.get('tripIdeas').filterBy('tripIdeaType', tripIdeaType)[0];
    if (!loadedTripIdea && tripIdeaType=='custom') {
      loadedTripIdea = this.customTripIdea;
    }
    this.applyTripIdea({tripIdea: loadedTripIdea, autoApplied: true});

    // this.flashMessages.success('Applied the "' + loadedTripIdea.tripIdeaTitle + '" trip idea!');
    // console.log('Applied the "' + loadedTripIdea.tripIdeaTitle + '" trip idea!');
    return loadedTripIdea;
  },

  lodgeToApplyOnTripIdea: null,

  applyTripIdea(options = {/*tripIdea, autoApplied */}){
    // console.log('apply trip idea', options)


    let trip = this.get('currentTrip'),
    tripIdea = options.tripIdea,
    autoApplied = options.autoApplied,
    startDate = trip.get('itinerary.startDate'),
    appliedTrip = null;

    // ie, if we pass the route in - CHOOSE YOUR OWN LODGES
    if (tripIdea && !tripIdea.isTripIdea) {
      tripIdea = this.get('originalRoute')
    }
    trip.get('itinerary.stages').forEach((stage)=>{
      stage.rollbackAttributes();
    })

    trip.set('templateTripIdea', tripIdea);
    appliedTrip = this.doApplyTripIdea({trip, tripIdea, startDate});


    if (!options.specifiedTrip){
      this.madeChanges({noUnsaved: autoApplied});
    }

    return appliedTrip;
  },

  // TODODUPLICATION
  applyLodgeToMatchingStage(stage) {
    if (stage.get('isLocationStage')) {
      return;
    }
    let lodge = this.lodgeToApplyOnTripIdea

    if (stage.get('regionProxy.name') === lodge.get('region.name')) {
      stage.set('lodge', lodge);
      stage.set('stageType', 'lodge');
    }
  },

  doApplyTripIdea(params = {/*trip, tripIdea, startDate */}){
    let trip = params.trip,
    tripIdea = params.tripIdea,
    startDate = params.startDate;

    trip.set('itinerary.startDate', startDate);
    trip.get('itinerary.stages').forEach((stage, index) => {

      let tripIdeaStage = tripIdea.get('itinerary.stages').objectAt(index);

      if (stage.isTemplateTransferStage) {
        stage.set('templateTransfer', tripIdeaStage.get('templateTransfer'));
      } else if (stage.isLodgeOrRegionStage) {

        if (tripIdeaStage.get('lodge')) {
          // TODODUPLICATION
          stage.set('lodge', tripIdeaStage.get('lodge'));
          stage.set('stageType', 'lodge');
        } else {
          stage.set('lodge', null);
          stage.set('stageType', 'region');
        }

        if (this.lodgeToApplyOnTripIdea){
          // we want to do this for region or lodge stages on the route
          // This will override the lodge from the trip idea
          this.applyLodgeToMatchingStage(stage, index)
        }

        stage.get('filterJoins').clear();
        tripIdeaStage.get('filterJoins').forEach((join) => {
          let newJoin = this.store.createRecord('filter-join', {stage: stage, stageFilter: join.get('stageFilter')});
          stage.get('filterJoins').pushObject(newJoin);
        })
      }
    });

    return trip;
  },

  /*---------------------
      Quote
  -----------------------*/
  ensureTripHasQuote(trip) {
    if (!trip.get('itinerary.quote')) {
      trip.set('itinerary.quote', this.get('store').createRecord('quote'));
      if (this.whitelabel.isForTimbuktu) {
        trip.set('itinerary.quote.useTimbuktuAutoMarkup', true);
      } else {
        trip.set('itinerary.quote.markupPercentage', 25)
      }
    }
  },

  @computed('currentTrip.itinerary.currencyForSettlement', 'settings.currentCurrency')
  canSettleInCurrentCurrency(currencyForSettlement, currentCurrency) {
    return currencyForSettlement === currentCurrency;
  },

    /*---------------------
      Location
    -----------------------*/

  setLocation(stage, location) {

    let needToInsertNewStage = this.get('currentTrip.itinerary.stages').indexOf(stage) === -1;

    // if stage is not inserted into trip yet do so - its a new stage
    if (needToInsertNewStage) {
      this.addNewStageToTrip({callMadeChanges: false});
    }

    if (stage.get('sequence') === 0) {
      stage.get('itinerary').set('manualStartLocation', true);
    } else {
      stage.get('itinerary').set('manualEndLocation', true);
    }

    stage.setProperties({
      location,
      madeTransferAffectingChanges: true
    });

    if (stage.get('itinerary.autoAllocateStartAndEnd') && this.session.get('currentUser.isManager')) {
      this.get('ui').showGeneralMessage('You\'re in control', 'The starting and ending locations are now manually controlled on this trip. They will not be updated automatically.');
      stage.set('itinerary.autoAllocateStartAndEnd', false)
    }
    this.set('showChooseLocationModal', false);

    this.madeChanges();
  },

  /*---------------------
      User permissions
  -----------------------*/

  @computed('session.currentUser', 'tripBelongsToCurrentUser')
  willAutoSaveCurrentTrip(currentUser, tripBelongsToCurrentUser) {
    return currentUser && tripBelongsToCurrentUser && !currentUser.isManager;
  },

  @computed('session.currentUser', 'currentTrip.users.[]')
  userCanManageThisTrip(currentUser, currentTripUsers) {
    return currentTripUsers && currentUser && currentTripUsers.findBy('id', currentUser.get('id')) && currentUser.get('isManager') || currentUser && currentUser.get('viewAnyTrip');
  },

  @computed('session.currentUser.id', 'currentTrip.consultant.id')
  currentUserPrimaryManagerForThisTrip(currentUserId, consultantId) {
    return currentUserId === consultantId;
  },

  @computed('currentTrip.itinerary.stateEmpty', 'whitelabel.isForTimbuktu', 'currentTrip.itinerary.isBookable', 'session.currentUser.isManager', 'whitelabel.isOnboardingAgency')
  guestCanEditTrip(stateEmpty, isForTimbuktu, isBookable, isManager, isOnboardingAgency) {
    return stateEmpty && (isForTimbuktu || isManager || isOnboardingAgency) && !isBookable;
  },

  @computed('tripBelongsToCurrentUser', 'session.currentUser.isManager')
  isNonManagerUserForThisTrip(tripBelongsToCurrentUser, currentUserIsManager) {
    return tripBelongsToCurrentUser && !currentUserIsManager;
  },

  @computed('currentTrip', 'currentTrip.consultant')
  tripHasConsultant(currentTrip, consultant) {
    return currentTrip && consultant;
  },

  @computed('session.currentUser', 'currentTrip', 'currentTrip.users.@each.id')
  tripBelongsToCurrentUser(currentUser, currentTrip, currentTripUsers) {
    return currentUser && currentTrip && currentTripUsers.mapBy('id').includes(currentUser.get('id'));
  },

  @computed('currentTrip.master', 'currentTrip.users.@each.id', 'session.currentUser')
  notOwnTrip(isMaster, currentTripUsers, currentUser) {
    return isMaster || !currentUser || !currentTripUsers.mapBy('id').includes(currentUser.get('id'));
  },

  // Trip needs to be saved before you can do certain actions
  @computed('currentTrip.id', 'tripBelongsToCurrentUser', 'userCanManageThisTrip')
  savedTripEditableByUser(id, tripBelongsToCurrentUser, userCanManageThisTrip) {
    return id && (tripBelongsToCurrentUser || tripBelongsToCurrentUser);
  },

  @computed('session.currentUser.isManager', 'tripBelongsToCurrentUser')
  userCanViewTripPrivateInfo(isManager, tripBelongsToCurrentUser) {
    return isManager || tripBelongsToCurrentUser;
  },

  @computed('currentTrip.itinerary.stateBeforeQuote', 'currentTrip.itinerary.isInstantBookable')
  showLodgePricesInTrip(stateBeforeQuote, isInstantBookable) {
    return stateBeforeQuote && !isInstantBookable;
  },

  @computed('currentTrip.itinerary.stateBeforeQuote', 'currentTrip.itinerary.isInstantBookable')
  Trip(stateBeforeQuote, isInstantBookable) {
    return stateBeforeQuote && !isInstantBookable;
  },


/*-------------------
      Dirty indicators to show quote needs be saved to get price from server
  ---------------------*/
  quoteGuestsChanged: false,
  quoteGuestsAdded: false,
  quoteGuestsRemoved: false,
  lineItemsChanged: false,
  paymentsChanged: false,
  discountsChanged: false,


  // TODO this needs to be set to false when new trip is set up

  /*-------------------
      Trip saving
  ---------------------*/

  savingTrip: false,
  showTripLockedModal: false,
  showSaveTripModalAfterLogin: false,
  tripToBeSavedAfterLogin: null,
  showTripNamingModal: false,


  shouldBlockChangesAndAlertUser() {
    if (this.currentTrip.itinerary.specialOfferId && (!this.session.currentUser || !this.session.currentUser.isContentTeam)) {
      this.set('ui.showUneditableTripModal', true);
      return true;
    }
    return false;
  },

  madeChanges(params = { /*noUnsaved */}) {
    /*
      Rules
      -----

      2. If making changes to a quote but not the owner
      3. 3. If trip has already been enquired, then show trip locked modal
      4. If not own trip show set flag for unsaved changes, but dont save yet
      5. If own trip then auto-save
    */

    console.log('madeChanges');


    this.get('session.currentUserPromise').then((currentUser) => {
      setupTripEntityNav(this.currentTrip, this, this.ui, this.session); //If you add a stage directly to a new trip from recommended places you want the overview / details / when to go to show up. Not just rely on coming back from /regions or /lodges to cause the trip nav to be set up again
      let nonConsultantLooking = !currentUser || (currentUser && !currentUser.get('isManager'));

      if (nonConsultantLooking) {
        // 2. If making changes to a quote but not the owner
        if (this.get('viewingSomeoneElsesTrip')) {
          trackEvent('made-changes:trip-locked:non-owner');
          return this.set('showTripLockedForNonOwnerModal', true);
        }
        // 3. If trip has already been enquired, then show trip locked modal
        if (this.get('currentTrip.itinerary.stateEnquiryAndAfter')) {
          if (this.get('tripBelongsToCurrentUser')) {
            trackEvent('made-changes:trip-locked');
            return this.set('showTripLockedModal', true);
          }
          trackEvent('made-changes:trip-locked:non-owner:after-enquiry');
          return this.set('showTripLockedForNonOwnerModal', true);
        }
      }

      // Refresh start and end locations and transfers
      this.get('currentTrip').refreshStartEndLocationsAndTransfers();

      // 4. If not own trip/ unsaved new trip, set flag for unsaved changes, but dont save yet
      if (this.get('notOwnTrip')) {

        if (!params.noUnsaved){
          this.set('currentTrip.hasUnsavedChanges', true);
        }

        // In a new trip do nothing if dates are set but no nonLocation stages have been added
        if (!this.get('currentTrip.itinerary.nonTransferAndLocationStages.length')) {
          return;
        }

        // Template trips and customised trips that belong to other people
        // Make request to server to get fromPrice/toPrice/numNights
        this.get('currentTrip.itinerary').calculatePricingAndNumNights().then((/* response */) => {
          // THIS IS THE POINT TO HOOK INTO to give feedback to user that they have added a lodge which is closed or is missing lodge rates
          // eg if (this.get('trip.itinerary.estimate.errors')) {
          // this.get('store').pushPayload(response);
        });
        return;
      }

      // 5. If own trip then auto-save
      // Server will supply new fromPrice/toPrice/numNights with update to trip
      if (nonConsultantLooking) {
        this.saveChanges();
      } else {
        this.get('currentTrip.itinerary').calculatePricingAndNumNights();
      }

    });
  },

  savingErrors: {},
  beforeSaveTripId: null,

  saveChanges(options = { /* bypass, fromSection, manualTransition, specifiedTrip, newTripLayout */ }) {

    // console.log('saveChanges');
    // console.log(arguments)

    // reset errors
    this.set('savingErrors', {});

    // this will always be false if coming from madeChanges as its already checked
    // bypass if a new unsaved trip
    if (!options.bypass && this.get('notOwnTrip')) {
      trackEvent('save-changes:name-trip-first');
      return this.nameAndSaveTrip(options);
    }

    let trip = options.specifiedTrip || this.get('currentTrip');
    this.set('beforeSaveTripId', trip.get('id'));

    // Only consultants can save changes to trips once they have gone past enquiry state
    if (trip.get('itinerary.stateEnquiryAndAfter')) {
      trip.set('makeCopyOfTripWhenSaving', !this.get('userCanManageThisTrip'));
    }

    if (!trip.fromSection && options.fromSection) {
      trip.set('fromSection', options.fromSection);
    }

    if (trip.get('name')) {
      trip.set('hasUnsavedChanges', false);
      this.setProperties({
        showTripNamingModal: false
      });

      // we fall back to agency default layout here as the current flow for saving trips when you are unauthenticated loses the trip layout
      return this.saveTrip(trip, {
          showSuccessFlash: false,
          specifiedTrip: options.specifiedTrip,
          newTripLayout: options.newTripLayout || this.get('whitelabel.agency.defaultLayout')
        }).then((newTrip) => {

          if (this.get('beforeSaveTripId') !== newTrip.get('id')) {
            this.get('flashMessages').success('"' + trip.get('name') + '" saved');
            // NOTE: replaceWith doesnt invoke a refresh of the other CP's - so we use transitionTo
            // NOTE 2: we dont really want to transition - it just interferes

            //  we leave users in the same place unless they are duplicating a trip
            if (trip.itinerary.stateEnquiryAndAfter) {
              this.get('router').transitionTo('trip.index.index', newTrip.get('friendlyId'));
            } else {
              if (!options.manualTransition && !options.specifiedTrip) {
                this.get('router').transitionTo(this.get('router.currentRouteName'), newTrip.get('friendlyId'));
              }
            }
          }

          trackEvent('trip:save:complete', {
            fromSection: options.fromSection
          });
          window.timbuktu.reportConversions.reportSaveTrip();
        })
        .finally(() => {
          this.set('beforeSaveTripId', null);
        });
    } else {
      this.get('flashMessages').warning('Trip name cannot be empty');
    }
  },

  nameAndSaveTrip(options = { /* bypass, fromSection */ }) {
    this.setProperties({
      showTripLockedModal: false,
      'ui.showUneditableTripModal': false
    });

    if (options.fromSection=='uneditable-trip') {
      this.set('currentTrip.makeCopyOfTripWhenSaving', true);
      this.set('currentTrip.removeReferenceToSpecialOfferWhenSaving', true);
    }

    if (this.get('session.isAuthenticated')) {
      this.nameYourTrip();
    } else {
      this.set('showSaveTripModalAfterLogin', true);
      this.set('tripToBeSavedAfterLogin', this.get('currentTrip'));
      this.set('ui.showRegisterModal', true);
    }
    if (options.fromSection) {
      trackEvent(`trip:${options.fromSection}:name-and-save:start`);
    } else {
      console.log('ERROR!!! NO fromSection specified. Why is the trip name and save modal being shown?. The fromSection needs to be passed through')
      trackEvent(`trip:generic:name-and-save:start`);
    }
  },

  saveWizardRecommendations(){

    this.set('ui.showSavedWizardTripsModal', true);
    this.set('ui.savingWizardTrips', true);

    let trips = this.get('ui.lastWizardTripRecommendations').mapBy('id')

    this.store.query('trip', {
      ids: trips
    }).then((trips) => {
      // Note: trip ideas are included with base trips here
      let templateTrips = trips.rejectBy('isTripIdea');
      let savingTrips = [];

      templateTrips.forEach((trip) => {
        let savingTrip = this.saveChanges({
          specifiedTrip: trip.makeCopy(),
          bypass: true,
          fromSection: 'wizard-recommendations'
        })

        savingTrips.pushObject(savingTrip)
      })

      RSVP.all(savingTrips).then(() => {
        this.set('ui.savingWizardTrips', false);
      });

    });

    this.set('ui.lastWizardTripRecommendations', null);
  },

  nameYourTrip() {
    if (this.get('currentTrip.name') === 'My new trip') {
      if (this.get('currentTrip.itinerary.stages') !== undefined || this.get('currentTrip.itinerary.stages').length > 0) {
        let newTripName = "My " + humanReadableList(this.get('currentTrip.countryNames')) + " trip"
        this.set('currentTrip.name', newTripName);
      }
    }

    this.set('showTripNamingModal', {newTripLayout: this.get('whitelabel.agency.defaultLayout')});
  },

  shouldBlockChangesToTrip() {
    // TOOD TRIPV3 check that this logic still works
    // if trying to add new lodges on enquiry, and not a manager
    if (this.get('currentTrip.itinerary.stateEnquiryAndAfter') && !this.get('session.currentUser.isManager')) {
      if (this.get('tripService.viewingSomeoneElsesTrip') || !this.get('tripService.tripBelongsToCurrentUser')) {
        trackEvent('trip:add-lodge-on-quote:not-owner:show-locked-modal')
        this.set('tripService.showTripLockedForNonOwnerModal', true);
        return true;
      } else {
        trackEvent('trip:add-lodge-on-quote:owner:show-locked-modal')
        this.set('tripService.showTripLockedModal', true);
        return true;
      }
    }
  },


  /*-------------------
      Trip maps
  ---------------------*/
  openMapModal() {
    this.set('tripPreviewModal', {
      trip: this.currentTrip,
      view: 'map'
    })
  },

  /*-------------------
      Trip links
  ---------------------*/

  customiseLinkParams(trip, options={}) {
    let params = {};

    let selectedLodgeNames = options['selectedLodgeNames'];

    if (!isEmpty(selectedLodgeNames)) {
      params['selectedLodgeNames'] = selectedLodgeNames;
    }

    let currentStageIndex = options['currentStageIndex'];

    if (!isEmpty(currentStageIndex)) {
      params['currentStageIndex'] = currentStageIndex;
    }

    let routeName = (this.get('session.currentUser.isManager') || this.whitelabel.isOnboardingAgency) ?  'trip.index.edit.itinerary' : 'trip.index.editor'
    return [
      routeName, trip.get('friendlyId'), {
        isQueryParams: true,
        values: params
      }
    ];
  },

  /*-------------------
      Partners
  ---------------------*/

  ensureAllPartnersLoaded() {
    if (isEmpty(this.get('allServiceTypes'))) {
      let allServiceTypesPromise = this.store.query('serviceType', {
        buster: true
      });
      this.set('allServiceTypesPromise', allServiceTypesPromise);
      allServiceTypesPromise.then((allServiceTypes) => {
        this.set('allServiceTypes', allServiceTypes);
      })
    }
    if (isEmpty(this.get('allPartners'))) {
      let allPartnersPromise = this.store.query('partner', {
        buster: true
      });
      this.set('allPartnersPromise', allPartnersPromise);
      allPartnersPromise.then((partners) => {
        this.set('allPartners', partners);
      })
    }
  },

  /*-------------------
      Images
  ---------------------*/

  goChooseTripImage(options) {
    this.set('overrideTripImageOptions', options)
  },
  goChooseStageImage(options) {
    // console.log(options)
    this.set('overrideStageImageOptions', options)
  },

  /*-------------------
      Trip summaries
  ---------------------*/

  archiveTrip(options) {
    return this.store.findRecord('trip', options.tripSummary.friendlyId).then((trip) => {
      return trip.archiveTrip().then(() => {
        this.messageBus.publish('my-trips-reload');
      })
    })
  },
  unArchiveTrip(options) {
    return this.store.findRecord('trip', options.tripSummary.friendlyId).then((trip) => {
      return trip.unArchiveTrip().then(() => {
        this.messageBus.publish('my-trips-reload');
      })
    })
  },

  removeCurrentUserFromTrip(options) {
    return this.store.findRecord('trip', options.tripSummary.friendlyId).then((trip) => {
      return trip.removeCurrentUserFromTrip().then(() => {
        this.messageBus.publish('my-trips-reload');
      })
    })
  },

  actions: {

    hideTripNamingModal() {
      this.setProperties({
        showTripNamingModal: false
      });
    },

    openMapModal() {
      this.openMapModal();
    },

    doChangeFoundNewLodge(options = { /* lodge, numNights, dontBook */ }) {
      this.doChangeFoundNewLodge(options)
    },

    goRemoveLodge(options={/* stage*/}) {
      this.goRemoveLodge(options);
    },

    doRemoveLodge() {
      this.doRemoveLodge();
    },

    editCustomEntity(options = {}) {
      this.editCustomEntity(options);
    },

    cloneEntityAndEdit(options = {}) {
      this.cloneEntityAndEdit(options);
    },


    saveCustomEntity(options = { /* lodge, dontBook, numNights */ }) {
      // need to return this for state-button
      return this.saveCustomEntity(options);
    },

    saveCustomEntityAndCompleteWorkflow(options = { /* lodge, dontBook, numNights */ }) {
      // need to return this for state-button
      return this.saveCustomEntityAndCompleteWorkflow(options);
    },

    madeChanges() {
      this.madeChanges();
    },

    saveChanges(optionsHash) {
      this.saveChanges(optionsHash);
    },

    incrementStageNights(stage) {
      this.incrementStageNights(stage);
    },

    decrementStageNights(stage) {
      this.decrementStageNights(stage);
    },

    setStartDate(startDate) {
      this.setStartDate(startDate);
    },

    addDates(event) {
      this.addDates(event);
    },

    skipDesignStep(step) {
      this.skipDesignStep(step);
    },


    addStageFromSearchSuggestion() {
      this.addStageFromSearchSuggestion(...arguments);
    },

    changeTripLayout() {
      this.changeTripLayout(...arguments);
    },




    createStage(options = { /* type, route, model */ }) {
      this.createStage(options);
    },

    willRemoveStage(stage) {
      this.willRemoveStage(stage);
    },

    goChooseLodge(options) {
      this.goChooseLodge(options)
    },

    goChooseRegion(options) {
      this.goChooseRegion(options)
    },


    setLodgeTripDateChange(doChange) {
      this.setLodgeTripDateChange(doChange);
    },

    applyTripIdea(options) {
      this.applyTripIdea(options);
    },

    setLocation(stage, location) {
      this.setLocation(stage, location);
    },


    reverseTrip() {
      this.reverseTrip();
    },

    openDatePicker(e) {
      this.openDatePicker(e);
    },
    goChooseStageImage(options) {
      this.goChooseStageImage(options)
    },
    goChooseTripImage(options) {
      this.goChooseTripImage(options)
    },
    doChangeFoundNewRegion(options) {
      this.doChangeFoundNewRegion(options)
    },
    retainScheduleForNewLodge(options) {
      this.retainScheduleForNewLodge(options)
    },
    replaceScheduleFromTemplate(options) {
      this.replaceScheduleFromTemplate(options)
    },
    archiveTrip(options) {
      this.archiveTrip(options)
    },
    unArchiveTrip(options) {
      this.unArchiveTrip(options)
    },

    removeCurrentUserFromTrip(options) {
      this.removeCurrentUserFromTrip(options)
    }



  }
});
