import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default Component.extend({
  store: service(),
  whitelabel: service(),

  init() {
    this._super(...arguments);
    this.set('experts', []);
    this.fetchExperts();
  },

  async fetchExperts() {
    const experts = await this.store.query('user', {
      is_travel_specialist: true,
      agency_id: 7,
      per_page: 3
    });

    set(this, 'experts', experts);
  }
});
