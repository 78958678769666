import Component from '@ember/component';

export default Component.extend({
  classNames: ['filter-modal'],
  classNameBindings: ['isOpen:open'],
  isOpen: false,

  actions: {
    closeModal() {
      this.set('isOpen', false);
      if (this.get('closeModal')) {
        this.get('closeModal')();
      }
    },

    enablePaneView() {
      if (this.get('enablePaneView')) {
        this.get('enablePaneView')();
      }
    },

    resetSuggestionsCategory() {
      if (this.get('resetSuggestionsCategory')) {
        this.get('resetSuggestionsCategory')();
      }
    }
  }
});
