import Controller from '@ember/controller';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  getErrorsHashFromServerResponse,
  trackEvent,
  reportEmailToHubspot
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';


export default Controller.extend({

  @computed('agencyName', 'overrideDomain')
  domain(agencyName, overrideDomain) {
    let domain = overrideDomain || agencyName;
    if (domain) {
      domain = domain.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase().replace('waybirdcom', '')+".waybird.com";
      return domain;
    }
  },

  actions: {
    createAgency() {

      const {
        agencyName,
        generalEmail,
        domain
      } =
      this;

      this.set('saving', true);
      this.set('errors', {})

      reportEmailToHubspot(generalEmail, 'register-account', 'Create account');


      // adwords script for waybird pulled in by hubspot script so we just create local gtag function to report event
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('config', 'AW-16736814306', {
        'conversion_linker': true,
        'allow_enhanced_conversions':true
      });

      gtag('event', 'conversion', {
        'send_to': 'AW-16736814306/rq_8CMmkl90ZEOKJ3qw-'
      });

      this.store.createRecord('agency', {
          name: agencyName,
          generalEmail,
          domain
        })
        .save()
        .then((agency) => {
          this.set('saving', false);
          this.transitionToRoute('waybird.account.register-user', agency);
        })
        .catch((serverResponse) => {
          this.set('errors', getErrorsHashFromServerResponse(serverResponse));
          this.set('showErrors', this.errors && true);
          this.set('saving', false);
        });

      return false;
    }
  }

});
