import {
  isEmpty
} from '@ember/utils';
import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  equal,
  alias,
  or
} from 'ember-decorators/object/computed';
import {
  memberAction
} from 'ember-api-actions';
import Self from 'b5b/models/lodge';

var Lodge = DS.Model.extend(HasKodakImage, {
  name: DS.attr('string'),
  internalDescription: DS.attr('string'),
  shortName: DS.attr('string'),
  fromPrice: DS.attr(),
  fromPriceCurrency: DS.attr(),
  lodgeStyle: DS.attr(),
  rating: DS.attr(),
  lodgeAvailable: DS.attr(),
  roomsAvailable: DS.attr(),
  destinationName: DS.attr(), //Never serialised from server but can be set on a lodge to create a custom region when creating custom lodge
  blockFromLoading: DS.attr(),
  lodgeInfoId: DS.attr(),
  replaceImages: DS.attr(),
  replaceImagesVersion: DS.attr(),
  metaImageUsedAsKodak: DS.attr(),

  metaImages: DS.attr(),
  @alias('metaImages') metaLodgeImages: null,

  @mapBy('metaLodgeImages', 'kodakOriginalUrl') carouselImages: null,

  metaExperiences: DS.attr(),

  isPopular: DS.attr(),

  teaserDescription: DS.attr('string'),
  teaserText: DS.attr(),

  latitude: DS.attr(),
  longitude: DS.attr(),

  specialOfferCount: DS.attr(),
  specialOfferNames: DS.attr(),

  accuratePriceAmount: DS.attr(),
  accuratePriceCurrency: DS.attr(),
  accuratePriceDate: DS.attr('b5bdate'),

  reportBadVideo: memberAction({
    path: 'report_bad_video'
  }),

  specifyReplaceImages: memberAction({
    path: 'specify_replace_images'
  }),




  @computed('latitude', 'region.latitude')
  latitudeWithFallback(latitude, regionLatitude) {
    return (isNaN(latitude) || !latitude) ? regionLatitude : latitude || regionLatitude;
  },

  @computed('longitude', 'region.longitude')
  longitudeWithFallback(longitude, regionLongitude) {
    return (isNaN(longitude) || !longitude) ? regionLongitude : longitude || regionLongitude;
  },

  @computed('latitude', 'longitude')
  mapMarker(latitude, longitude) {
    if (!latitude || !longitude) {
      return;
    }

    return [
      [parseFloat(longitude).toFixed(6), parseFloat(latitude).toFixed(6)]
    ];
  },

  feefoProductReviewsCount: DS.attr(),
  feefoProductReviewsRating: DS.attr(),

  published: DS.attr(),
  publishedForWaybird: DS.attr(),
  category: DS.attr(),
  metaImagesCount: DS.attr(),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),

  // polished || unexplored || custom
  polishLevel: DS.attr({
    defaultValue: 'custom'
  }),
  // I'm forcing userOwnerId to be a string here so that we can compare it to user.id  which is a string.
  // If you want to change this to a number you'll need to look code where this is compared to the user id
  userOwnerId: DS.attr('string'),
  userOwnerName: DS.attr('string'),
  shareWithAgency: DS.attr(),
  isArchived: DS.attr(),

  @equal('polishLevel', 'polished') isPolished: false,
  @equal('polishLevel', 'unexplored') isUnexplored: false,
  @equal('polishLevel', 'custom') isCustom: false,
  @or('isPolished', 'isUnexplored') isRegularLodge: null,
  @alias('region.name') placeName: null,
  @alias('lodgeInfo') entityInfo: null,


  experiences: DS.hasMany('experience', {
    inverse: null,
    async: false
  }),

  lodgeInfo: DS.belongsTo('lodgeInfo'),
  area: DS.belongsTo('area', {
    inverse: null
  }),
  region: DS.belongsTo('region', {
    inverse: null,
    async: false
  }),
  country: DS.belongsTo('country', {
    inverse: null,
    async: false
  }),
  //originalEntity currently not serialised back from server. This is used to save relationship to server
  originalEntity: DS.belongsTo('lodge', {
    inverse: null,
    async: false
  }),


  friendlyId: DS.attr(),

  @alias('region') regionProxy: null,
  @alias('lodgeInfo') entityInfo: null,

  @computed('friendlyId', 'id')
  lodgeFriendlyId(friendlyId, id) {
    return friendlyId || id;
  },

  @alias('name') seoName: null,

  // We used prefer the shortName over the long name for lodges but there are some realyl weird short names
  // So just going to make it an alias for the long name
  // @or('shortName', 'name') displayName: null,
  @alias('name') displayName: null,


  // Lodges have a teaser text.. Custom lodges have a teaserDescriptino
  @or('teaserText', 'teaserDescription') teaser: null,

  @computed('experiences')
  activityExperiences(experiences) {
    return experiences.filterBy('isActivity');
  },


  @computed('activityExperiences.length')
  hasActivities(activityExperiencesLength) {
    return activityExperiencesLength > 0;
  },

  @computed('lodgeStyle')
  lodgeStyleSvgName(lodgeStyle) {
    return `lodge--${lodgeStyle.toLowerCase()}`;
  },

  @computed('lodgeStyle')
  atTheLodgeStyle(lodgeStyle) {
    return `At the ${lodgeStyle}`;
  },

  @computed('lodgeStyle')
  roomType(lodgeStyle) {
    if (lodgeStyle === 'Camp') {
      return 'Tent';
    }
    return 'Room';
  },

  @computed('latitude', 'longitude')
  isValidMapLocation(lat, long) {
    return lat && long && !isNaN(lat) && !isNaN(long)
  },

  @computed('regionCategory', 'category')
  lodgeCategory(regionCategory, lodgeCategory) {
    // fall back to region category is lodge doesn't have one. Most lodges don't
    return isEmpty(lodgeCategory) ? regionCategory : lodgeCategory;
  },

  @computed('lodgeCategory')
  isSafari(lodgeCategory) {
    //Even though, lodges fall back to region categry, its still possible for some lo
    return isEmpty(lodgeCategory) || 'Safari' === lodgeCategory ? true : false;
  },

  @computed('region', 'kodakOriginalUrl', 'country')
  heroEntity(region, kodakOriginalUrl, country) {
    if (kodakOriginalUrl) {
      return this;
    }

    if (region && region.get('hasKodakImage')) {
      return region;
    }

    if (country && country.get('hasKodakImage')) {
      return country;
    }

  },

  @computed('region', 'isValidMapLocation')
  mapEntity(region, isValidMapLocation) {

    if (isValidMapLocation) {
      return this;
    }

    if (region) {
      return region;
    }
  },

  @equal('lodgeCategory', 'Beach') isBeach: false,
  @equal('lodgeCategory', 'Retreat') isRetreat: false,
  @equal('lodgeCategory', 'City') isCity: false,
  @alias('fromPrice') hasPrice: false,

  makeCopy() {

    let copy = this.get('store').createRecord('lodge');

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    if (this.region) {
      copy.set('region', this.get('region'))
    } else {
      copy.set('country', this.get('country'))
    }

    return copy;
  }

});

export default Lodge;
