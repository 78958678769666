import { htmlSafe } from "@ember/string";
import { isArray } from "@ember/array";
import { get } from "@ember/object";
import RSVP from "rsvp";
import Trip from "b5b/models/trip";
import Itinerary from "b5b/models/itinerary";
import Stage from "b5b/models/stage";
import ImageMeta from "b5b/models/image-meta";
import moment from "moment";
import config from "b5b/config/environment";
import { A as EmberArray } from '@ember/array';

var special = [
  "zeroth",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth",
];
var deca = [
  "twent",
  "thirt",
  "fort",
  "fift",
  "sixt",
  "sevent",
  "eight",
  "ninet",
];
export function stringifyNumber(n) {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
}

const HOST = "https://www.timbuktutravel.com";
const LOCAL_STORAGE_EMAIL_CAPTURED_KEY = "email_captured";
const LOCAL_STORAGE_MODAL_UX_FEEDBACK_SHOWN = "modal_ux_feedback_shown3";
const LOCAL_STORAGE_MODAL_WIZARD_SHOWN = "modal_wizard_shown";
export const LOCAL_STORAGE_CONSULTANT_ID_KEY = "consultant_id";
export const ROUTE_DURATIONS = [
  {
    key: "less_than_week",
    name: "Less than a week",
    minNights: 3,
    maxNights: 6,
  },
  {
    key: "1_2_weeks",
    name: "1-2 weeks",
    minNights: 7,
    maxNights: 14,
  },
  {
    key: "2_weeks_plus",
    name: "2 weeks +",
    minNights: 15,
    maxNights: 100,
  },
];

export const AGES_GROUP_METAS = [
  {
    singular: "adult",
    key: "adult",
    numberOf: "numAdults",
  },
  {
    singular: "young adult",
    key: "youngAdult",
    numberOf: "numYoungAdults",
  },
  {
    singular: "child",
    key: "child",
    numberOf: "numChildren",
  },
  {
    singular: "infant",
    key: "infant",
    numberOf: "numInfants",
  },
];

export const REGION_TYPES = ["Safari", "City", "Retreat", "Beach"];

export const REGION_TYPE_KODAKS = {
  Safari:
    "https://waybird.imgix.net/assets/images/region-types/safari.jpg",
  City: "https://waybird.imgix.net/assets/images/region-types/city.JPG",
  Retreat:
    "https://waybird.imgix.net/assets/images/region-types/retreat.JPG",
  Beach:
    "https://waybird.imgix.net/assets/images/region-types/beach.JPG",
};

export const TRIP_IDEAS_BUDGET_MAPPING = {
  low: "Shoestring",
  "Shoestring": "low",
  mid: "Mid-range",
  "Mid-range": "mid",
  high: "Luxury",
  Luxury: "high",
};

export const STRIPE_CURRENCIES = ["gbp", "usd", "eur"];
export const PAYPAL_CURRENCIES = ["gbp", "usd", "eur"];
//
export const FLYWIRE_CURRENCIES = ["gbp", "usd", "eur", "zar"];
export const WAYBIRD_CURRENCIES = ["usd"];


export const TRACKING_DOMAINS = [
  "google-analytics.com",
  "facebook.com",
  "doubleclick.net",
  "adobe.com",
  "twitter.com",
  "pinterest.com",
  "linkedin.com",
  "amazon-adsystem.com",
  "youtube.com",
  "bing.com",
  "instagram.com",
  "snapchat.com",
  "taboola.com",
  "outbrain.com",
  "quantserve.com",
  "scorecardresearch.com",
  "criteo.com",
  "bluekai.com",
  "rubiconproject.com",
  "pubmatic.com"
];


let POSSIBLE_MONTHS =  ["Any month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const safeVoid0 = htmlSafe("javascript:void(0)");

const metaTags = {
  "og:title": function (title, tagId = "meta-facebook-title-tag") {
    return {
      type: "meta",
      tagId,
      attrs: {
        property: "og:title",
        content: title,
      },
    };
  },

  "og:url": function (url, tagId = "meta-facebook-url-tag") {
    return {
      type: "meta",
      tagId,
      attrs: {
        property: "og:url",
        content: url,
      },
    };
  },

  "og:image": function (imgUrl, tagId = "meta-fb-image-tag") {
    return {
      type: "meta",
      tagId,
      attrs: {
        property: "og:image",
        content: imgUrl + "?w=1200&h=630",
      },
    };
  },

  link: function (url, tagId = "canonical-link") {
    let href = (url === '' || url.startsWith("/")) ? `${HOST}${url}` :`${HOST}/${url}`;
    HOST + "/" + url
    return {
      type: "link",
      tagId,
      attrs: {
        rel: "canonical",
        href,
      },
    };
  },

  description: function (description, tagId = "meta-description-tag") {
    return {
      type: "meta",
      tagId,
      attrs: {
        name: "description",
        content: description,
      },
    };
  },

  robots: function (robots, tagId = "meta-robots-tag") {
    return {
      type: "meta",
      tagId,
      attrs: {
        name: "robots",
        content: robots,
      },
    };
  },

  // default width of 1440 for shared images
  "og:image:width": function (
    width = 1200,
    tagId = "meta-facebook-image-width"
  ) {
    return {
      type: "meta",
      tagId,
      attrs: {
        property: "og:image:width",
        content: width,
      },
    };
  },

  // default height of 960 for shared images
  "og:image:height": function (
    height = 630,
    tagId = "meta-facebook-image-height"
  ) {
    return {
      type: "meta",
      tagId,
      attrs: {
        property: "og:image:height",
        content: height,
      },
    };
  },
};

let localStorageAvailable = undefined;
let fakeLocalStorage = {};

function checkForLocalStorageAvailability() {
  if (typeof Fastboot !== "undefined") {
    return;
  }
  var test = "test_ls";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    localStorageAvailable = true;
  } catch (e) {
    localStorageAvailable = false;
    trackEvent("localStorage:unavailable");
  }
}

export function copyTextToClipboard(text) {
  let textArea = document.createElement("textarea");
  textArea.style.position = "absolute";
  textArea.style.top = -10;
  textArea.style.left = -10;
  textArea.style.width = 0;
  textArea.style.height = 0;
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
}

export function isLocalStorageAvailable() {
  if (typeof localStorageAvailable === "undefined") {
    checkForLocalStorageAvailability();
  }
  return localStorageAvailable;
}

export function getUrlParams() {
  let rawParams = window.location.href.split('?').pop().split('&');
  let params={};
  rawParams.forEach((param) => {
    let splits = param.split('=');
    params[splits[0]]=splits[1]
  });
  return params;
}

export function setupTripEntityNav(
  trip,
  tripService,
  uiService,
  sessionService
) {
  let links = [];
  let hasStages = trip.get("itinerary.stages.length") > 0;


  links.pushObject({
    name: "Overview",
    params: {
      routeName: "trip.index.index",
      routeModel: trip,
    },
  });

  if (trip.isGiftList) {
    links.pushObject({
      name: "Gift",
      params: {
        routeName: "trip.index.gift",
        routeModel: trip,
      },
    });
  } else if (!trip.isLayoutDayByDay) {
    links.pushObject({
      name: "Details",
      params: {
        routeName: "trip.index.quote",
        routeModel: trip,
      },
    });
  }

  if (
    (trip.get('itinerary.videos.length') > 0 || trip.get('itinerary.stages').reduce(function(anyLodgeVideosToShow, stage) {
      return anyLodgeVideosToShow || stage.get('showLodgeVideos');
    }, false))
  ) {
    if (!(window.timbuktu.services.whitelabel.isForAgency && trip.isRoute)) {
      links.pushObject({
        name: "Video",
        params: {
          routeName: "trip.index.video",
          routeModel: trip,
        },
      });
    }
  }

  if (
    trip.get("itinerary.stateBeforeQuote") &&
    hasStages &&
    window &&
    window.timbuktu.services.whitelabel.isForTimbuktu
  ) {
    links.pushObject({
      name: "When to go",
      params: {
        routeName: "trip.index.when-to-go",
        routeModel: trip,
      },
    });
  }

  // console.log(!trip.get('isGiftList') || (trip.isGiftList && (sessionService.get('currentUser.isManager') || tripService.currentUserIsGiftListOwner)))
  if (
    !trip.showShareVersion &&
    trip.get("itinerary.validCharges.length") > 0 &&
    tripService.get("userCanViewTripPrivateInfo") &&
    (!trip.get('isGiftList') || (trip.isGiftList && (sessionService.get('currentUser.isManager') || tripService.currentUserIsGiftListOwner)))
  ) {
    links.pushObject({
      name: "Dashboard",
      params: {
        routeName: "trip.index.dashboard",
        routeModel: trip,
      },
    });
  }

  sessionService.get("currentUserPromise").then((user) => {
    if (
      (window && window.timbuktu.services.whitelabel.isForTimbuktu) &&
      trip.itinerary.stateBeforeQuote &&
      !trip.itinerary.isInstantBookable
    ) {
      links.pushObject({
        name: "Customise",
        params: {
          routeName: "trip.index.editor",
          routeModel: trip,
        },
      });
    }

    // if (
    //   !trip.showShareVersion &&
    //   !trip.get('isGiftList') &&
    //   (trip.get("itinerary.stateQuote") || trip.get("itinerary.stateBalanceDue"))
    // ) {
    //   links.pushObject({
    //     name: "Pay",
    //     params: {
    //       routeName: "trip.index.pay",
    //       routeModel: trip,
    //     },
    //   });
    // }

    uiService.setProperties({
      entityNavData: {
        entity: trip,
        title: trip.name,
        titleLink: {
          routeName: "trip.index.index",
          routeModel: trip,
        },
        hideTitleInEntityNav: true,
        links: links,
      },
    });

    if (
      (user && !trip.showShareVersion && (user.get("isTimbuktu") || user.get("isManager"))) ||
      window.timbuktu.services.whitelabel.isOnboardingAgency
    ) {
      links.unshift({
        name: "Quote Tools",
        params: {
          routeName: "trip.index.edit.itinerary",
          routeModel: trip,
        },
      });
      uiService.set('entityNavData.mobileMenu', {
        displayOn:'hide-after-tablet',
        realAction: 'toggleSideNavActive',
        realActionTarget: uiService,
        visibleForRoutes: "trip.index.edit.",
      });
    }
  });
}

export function setupDealEntityNav(deal,dealService, uiService, sessionService) {

  sessionService.get('currentUserPromise').then((user) => {
      uiService.setProperties({
        displayOn: 'hide-after-tablet',
        entityNavData: {
          entity: deal,
          title: deal.name,
          titleLink: {
            routeName: 'deal.index.index',
            routeModel: deal
          },
          hideTitleInEntityNav: true,
          links: [{
            name: 'Janice Joplin x 2 South africa',
            params: {
              routeName: 'deal.index.edit.trips',
              routeModel: deal
            }
          }],
        }
      });
      uiService.set('entityNavData.mobileMenu', {
        realAction: 'toggleSideNavActive',
        realActionTarget: uiService,
        visibleForRoutes: 'deal.index.edit.'
      })
  })


}

export function generateEmptyReplaceImages() {
  return {images: EmberArray([]), timestamp: Date.now()}
}


export function generateReplaceImagesForEntity(entity) {
  console.log('generateReplaceImagesForEntity', entity)
  entity.set('replaceImages', generateEmptyReplaceImages())
  if (entity.get('metaImageUsedAsKodak')) {
    entity.get('replaceImages.images').pushObject(createImageMeta(entity.get('metaImageUsedAsKodak')))
  }
  if (entity.get('metaImages')) {
    entity.get('metaImages').forEach((image, index)=> {
      let metaImage = createImageMeta(image);
      entity.get('replaceImages.images').pushObject(metaImage)
    })
  }
}

export function createImageMeta(metaImageFromServer) {
  let metaImage = ImageMeta.create(metaImageFromServer)
  // Only enabled images are serialised from the server
  metaImage.set('enabled',true)
  return metaImage;
}

export function getItemFromLocalStorage(key) {
  if (typeof Fastboot !== "undefined") {
    return;
  }
  if (typeof localStorageAvailable === "undefined") {
    checkForLocalStorageAvailability();
  }
  if (localStorageAvailable) {
    return localStorage.getItem(key);
  } else {
    return fakeLocalStorage[key];
  }
}


export function scheduleHeading(stateBeforeQuote, scheduleHeadingTripIdeas, scheduleHeadingQuotes, tripScheduleHeading, scheduleHeading) {
  if (scheduleHeading) {
    return scheduleHeading
  } else if (tripScheduleHeading) {
    return tripScheduleHeading
  }
  if (stateBeforeQuote) {
    return scheduleHeadingTripIdeas || "What you can do"
  } else {
    return scheduleHeadingQuotes || "What you'll do"
  }
}

export function getBlockTitle(trip, whitelabel) {
  let isDayByDay = null;
  if (trip) {
    isDayByDay = trip.get('isLayoutDayByDay');
  } else {
    isDayByDay = whitelabel.get('agency.allowLayoutDayByDay');
  }
  let blockTitle = isDayByDay  ? 'page' : 'block'
  return blockTitle;
}

export function monthNameFromNumber(monthNumber) {
  let index = monthNumber == 'any' ? 0 : monthNumber;
  return POSSIBLE_MONTHS.objectAt(index);
}

export function yearNameFromNumber(yearNumber) {
  return yearNumber == 'any' ? 'Any year' : yearNumber
}

export function setItemInLocalStorage(key, value) {
  if (typeof Fastboot !== "undefined") {
    return;
  }
  if (typeof localStorageAvailable === "undefined") {
    checkForLocalStorageAvailability();
  }
  if (localStorageAvailable) {
    return localStorage.setItem(key, value);
  } else {
    return (fakeLocalStorage[key] = value);
  }
}

export function lodgeStyles(date) {
  var LODGE_STYLE_CAMP = "Camp";
  var LODGE_STYLE_LODGE = "Lodge";
  var LODGE_STYLE_HOTEL = "Hotel";
  var LODGE_STYLE_MOBILE = "Mobile";
  var LODGE_STYLE_PRIVATE_HOUSE = "Private House";
  var LODGE_STYLE_CHALET = "Chalet";
  var LODGE_STYLE_VILLA = "Villa";

  var LODGE_STYLES = [
    LODGE_STYLE_CAMP,
    LODGE_STYLE_LODGE,
    LODGE_STYLE_HOTEL,
    LODGE_STYLE_MOBILE,
    LODGE_STYLE_PRIVATE_HOUSE,
    LODGE_STYLE_CHALET,
    LODGE_STYLE_VILLA,
    "Houseboat",
    "Homestay",
    "Heritage",
    "Ryokan"
  ];

  return LODGE_STYLES.sort();
}

export function metadataDate(date) {
  return moment(date).format("YYYY-MM-DD");
}

export function removeItemFromLocalStorage(key) {
  if (typeof Fastboot !== "undefined") {
    return;
  }
  if (typeof localStorageAvailable === "undefined") {
    checkForLocalStorageAvailability();
  }
  if (localStorageAvailable) {
    return localStorage.removeItem(key);
  } else {
    return delete fakeLocalStorage[key];
  }
}

export function addSurchagesIfNecessary(trip, pricesHash) {
  if (pricesHash) {
    if (
      trip.get("itinerary.useNewPricingModel") ||
      trip.get("itinerary.stateQuote")
    ) {
      return pricesHash;
    }
    // New pricing model, doesn't have surcharges. All the prices in the app are marked up according to stripe fees
    var includingSurcharge = {};
    Object.keys(pricesHash).forEach((currencyKey) => {
      includingSurcharge[currencyKey] = pricesHash[currencyKey] * 1.03;
    });
    return includingSurcharge;
  }
}

export function convertUrlToAltText(url) {
  if (url && url.substring) {
    //It is possible to pass in a promise which will resolve to a string, so we just check that we are actually dealing with a string
    return url
      .substring(url.lastIndexOf("/") + 1, url.length - 4)
      .replace(/(-|_)/g, " ")
      .replace(/(timbuktu|travel|Timbuktu|Travel)/g, "");
  }
}

export function getSortTitle(sort, isMobileOnly) {
  switch (sort) {
    case 'from_price_asc':
      return 'Price' + (isMobileOnly ? '' : ' (low to high)');
    case 'from_price_desc':
      return 'Price' + (isMobileOnly ? '' : ' (high to low)');
    case 'priority_asc':
      return 'Recommended';
    case 'alphabetical_asc':
      return 'Alphabetical';
    case 'created_at_desc':
      return 'Create date';
    case 'name_asc':
      return 'Alphabetical';
    case 'name_desc':
      return 'Alphabetical';
    case 'sequence_asc':
      return 'Recommended';
    case 'api_updated_at_asc':
      return 'Last edited'
    case 'last_viewed_asc':
      return 'Last viewed'
    case 'total_price_asc':
      return 'Price' + (isMobileOnly ? '' : ' (low to high)');
    case 'total_price_desc':
      return 'Price' + (isMobileOnly ? '' : ' (high to low)');
    case 'per_guest_price_asc':
      return 'Per guest price' + (isMobileOnly ? '' : ' (low to high)');
    case 'per_guest_price_desc':
      return 'Per guest price' + (isMobileOnly ? '' : ' (high to low)');
    case 'start_date_asc':
      return 'Start date';



    default:
      return 'Sort by';
  }
}

export function shouldShowStageSchedule(stage, schedule, customScheduleOnly) {
  // console.log('shouldShowStageSchedule', schedule)
  if (schedule && schedule.get('isCustomSchedule') || !customScheduleOnly && (stage.get('itinerary.beforeQuoteNotInstantBookable') && schedule && schedule.get('isTemplateSchedule'))) {
    return true
  }
  return false;
}

export function getSortedTripIdeas(tripIdeas, sortKey, luxuryFirst=false) {
  let sortedTripIdeas = [];
  let map = tripIdeas.mapBy(sortKey)
  let budgetLow = tripIdeas.objectAt(map.indexOf('low'));
  let budgetMid = tripIdeas.objectAt(map.indexOf('mid'));
  let budgetHigh = tripIdeas.objectAt(map.indexOf('high'));

  if (luxuryFirst) {
    if (budgetHigh) {
      sortedTripIdeas.pushObject(budgetHigh);
    }
    if (budgetMid) {
      sortedTripIdeas.pushObject(budgetMid);
    }
    if (budgetLow) {
      sortedTripIdeas.pushObject(budgetLow);
    }
  } else {
    if (budgetLow) {
      sortedTripIdeas.pushObject(budgetLow);
    }
    if (budgetMid) {
      sortedTripIdeas.pushObject(budgetMid);
    }
    if (budgetHigh) {
      sortedTripIdeas.pushObject(budgetHigh);
    }
  }

  return sortedTripIdeas;
}




export function getErrorsHashFromServerResponse(serverResponse) {
  var returnErrors = null;
  if (serverResponse.errors) {
    returnErrors = {};
    serverResponse.errors.forEach((error) => {
      if (!error.source) {
        return;
      }
      var splits = error.source.pointer.split("/");
      var attribute = splits[splits.length - 1];
      attribute = attribute.replace(/\./g, "_");
      returnErrors[attribute] = [error.detail];
    });
  }
  return returnErrors;
}

export function getCategoryExperiencesPromise(store) {
  let experiencesCount = store
    .peekAll("home")
    .get("firstObject.categoryExperienceCount");
  let experiences = store
    .peekAll("experience")
    .filterBy("isCategory")
    .sortBy("name");
  if (experiences.get("length") >= experiencesCount) {
    return new RSVP.Promise(function (resolve) {
      resolve(experiences);
    });
  }

  return store
    .query("experience", {
      isCategory: true,
      per_page: 50,
    })
    .then((experiences) => {
      return experiences.sortBy("name");
    });
}

export function getActivityExperiencesPromise(store) {
  let experiencesCount = store
    .peekAll("home")
    .get("firstObject.activityExperienceCount");
  let experiences = store
    .peekAll("experience")
    .filterBy("isActivity")
    .sortBy("name");
  if (experiences.get("length") >= experiencesCount) {
    return new RSVP.Promise(function (resolve) {
      resolve(experiences);
    });
  }

  return store
    .query("experience", {
      isActivity: true,
      per_page: 50,
    })
    .then((experiences) => {
      return experiences.sortBy("name");
    });
}

export function getLodgeRelatedExperiencesPromise(store) {
  let experiencesCount =
    store.peekAll("home").get("firstObject.activityExperienceCount") +
    store.peekAll("home").get("firstObject.lodgeTypeExperienceCount");
  let experiences = store
    .peekAll("experience")
    .filter((experience) => {
      if (experience.get("isLodgeType") || experience.get("isActivity")) {
        return experience;
      }
    })
    .sortBy("name");

  // THis is based on assumption that at least some of the lodge type experiences are
  if (experiences.get("length") >= experiencesCount) {
    return new RSVP.Promise(function (resolve) {
      resolve(experiences);
    });
  }

  return store
    .query("experience", {
      isActivity: true,
      isLodgeType: true,
      per_page: 50,
    })
    .then((experiences) => {
      return experiences.sortBy("name");
    });
}

export function getDurationForNumNights(numNights) {
  let duration = "";
  ROUTE_DURATIONS.forEach((routeDuration) => {
    if (
      !duration &&
      numNights >= routeDuration.minNights &&
      numNights <= routeDuration.maxNights
    ) {
      duration = routeDuration.name;
    }
  });
  return duration;
}

export function getTermsAndConditionsUrlForTrip(trip) {
  // in order: the actual terms and conditions agreed, then the current terms and conditions for the contracting entity that they would agree to which covers quotes and beyond
  // Instant book would have to fall back to the default terms and conditions for the agency

  return (
    trip.get("itinerary.termsAcceptedUrl") ||
    trip.get("contractingEntityCurrentTermsAndConditionsUrl") ||
    window.timbuktu.services.whitelabel.termsAndConditionsUrl
  );
}

export function getDurationForKey(key) {
  return ROUTE_DURATIONS.findBy("key", key);
}

export function generateMeta(keyVal, whitelabel) {
  if (window.timbuktu.services.whitelabel.isForAgency) {
    let link = keyVal['link'];
    keyVal={robots: "noindex, nofollow"}
    if (link) {
      keyVal['link']=link;
    }
  }
  if (!keyVal["og:image:width"]) {
    keyVal["og:image:width"] = undefined;
  }
  if (!keyVal["og:image:height"]) {
    keyVal["og:image:height"] = undefined;
  }

  var tags = Object.keys(keyVal).map(function (key) {
    let val = keyVal[key];
    let args = isArray(val) ? val : [val];
    return metaTags[key](...args);
  });

  return tags;
}

export function getTripImageFromRegionalImage(image, region) {
  let description = region.name;
  if (image.description) {
    description = description + " - " + image.description;
  }
  let newImage = {
    description: description,
    originalUrl: image.originalUrl,
    coverStyle: image.coverStyle,
    regionId: region.id,
  };
  return newImage;
}

export function getTripImageFromExperience(experience) {
  let description = experience.name;
  let newImage = {
    description: description,
    originalUrl: experience.heroEntity.kodakOriginalUrl,
    coverStyle: experience.heroEntity.coverStyle,
    experienceId: experience.id,
  };
  return newImage;
}

export function getTripImageFromEntityMetaImage(image, entity) {
  let description = entity.name;
  if (image.description) {
    description = description + " - " + image.description;
  }
  let newImage = {
    description: description,
    originalUrl: image.originalUrl,
    coverStyle: image.coverStyle,
  };
  if (entity.get("region")) {
    newImage["regionId"] = entity.get("region.id");
  }
  return newImage;
}

export function findRecordsToUnload(hasManyRelationship) {
  let recordsToUnload = [];
  if (hasManyRelationship.value()) {
    hasManyRelationship.value().forEach((entry) => {
      // We might have created new records without ids. The server will allocate them ids, but because its an embedded save they are not tied back to the
      // original records without ids, effectively duplicating any new records. so we remove the original ones without ids
      if (entry && entry.get("isNew")) {
        recordsToUnload.push(entry);
      }
    });
  }
  return recordsToUnload;
}

export function uniqBy(items, propName) {
  var hash = {};
  return items.reduce(function (sum, item) {
    if (!hash[get(item, propName)]) {
      sum.push(item);
      hash[get(item, propName)] = true;
    }
    return sum;
  }, []);
}

export function uniqueBy(items, propName) {
  return uniqBy(items, propName);
}

export function deDupeArray(array) {
  return array.filter(function (item, index) {
    return array.indexOf(item) == index;
  });
}

export function isAfricanSafari(trip) {
  if (
    trip.get("metaContinentNames.length") == 1 &&
    trip.metaContinentNames[0] == "Africa"
  ) {
    return true;
  }
  return false;
}

export function continentalJourney(trip) {
  if (trip.get("metaContinentNames.length") > 0) {
    let continentNames = humanReadableList(
      trip.metaContinentNames.map((name) => {
        return name + "n";
      })
    );
    // let journeyDescription = isAfricanSafari(trip) ? "safari" : "journey";
    // Just going to say journey as not all the african countries are safaris
    let journeyDescription = "journey";
    let description = `An ${continentNames} ${journeyDescription} to`;
    return description;
  }
}

export function humanReadableList(
  collection,
  options = {
    /* itemKey, endSep */
  }
) {
  var list = "",
    endSep = options.endSep || "&",
    itemKey = options.itemKey;

  if (!itemKey) {
    {
      itemKey = "name";
    }
  }
  let validatedCollection = [];
  collection.forEach(function (entry) {
    if (entry) {
      validatedCollection.pushObject(entry);
    }
  });
  validatedCollection.forEach(function (entry, index) {
    if (entry.get) {
      list = list + entry.get(itemKey);
    } else {
      list = list + entry;
    }

    if (index != validatedCollection.length - 1) {
      if (index < validatedCollection.length - 2) {
        list = list + ", ";
      } else {
        list = list + (" " + endSep + " ");
      }
    }
  });
  return list;
}

export function getRequestParamByName(name) {
  // Check for fastboot
  if (typeof document !== "undefined") {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}

export function trim(str, maxLen, wordCut = false) {
  if (typeof str !== "string") {
    return "";
  }

  //string is too long, lets trim it and append ...
  if (str.length > maxLen) {
    // if we want to cut the text mid word
    if (wordCut) {
      str = str.slice(0, maxLen - 1);
      str += "...";
    } else {
      var lastSpace = str.lastIndexOf(" ");
      str = str.slice(0, maxLen - 3);
      //there is no space in the word
      if (lastSpace === -1) {
        str += "...";
      } else if (lastSpace > -1) {
        var t = str.lastIndexOf(" ");
        str = str.slice(0, t);
        str += "...";
      }
    }
  }

  return str;
}

window.trim = trim;

function py2_round(value) {
  // Google's polyline algorithm uses the same rounding strategy as Python 2, which is different from JS for negative values
  return Math.floor(Math.abs(value) + 0.5) * Math.sign(value);
}

function encode(current, previous, factor) {
  current = py2_round(current * factor);
  previous = py2_round(previous * factor);
  var coordinate = current - previous;
  coordinate <<= 1;
  if (current - previous < 0) {
    coordinate = ~coordinate;
  }
  var output = "";
  while (coordinate >= 0x20) {
    output += String.fromCharCode((0x20 | (coordinate & 0x1f)) + 63);
    coordinate >>= 5;
  }
  output += String.fromCharCode(coordinate + 63);
  return output;
}

/**
 * Encodes the given [latitude, longitude] coordinates array.
 * Find source for this here https://github.com/mapbox/polyline/blob/master/src/polyline.js
 *
 * @param {Array.<Array.<Number>>} coordinates
 * @param {Number} precision
 * @returns {String}
 */
export function encodePolyline(coordinates, precision) {
  if (!coordinates.length) {
    return "";
  }

  var factor = Math.pow(10, precision || 5),
    output =
      encode(coordinates[0][0], 0, factor) +
      encode(coordinates[0][1], 0, factor);

  for (var i = 1; i < coordinates.length; i++) {
    var a = coordinates[i],
      b = coordinates[i - 1];
    output += encode(a[0], b[0], factor);
    output += encode(a[1], b[1], factor);
  }

  return output;
}

export function decodePolyline(str, precision) {
  var index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change,
    factor = Math.pow(10, precision || 5);

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lat / factor, lng / factor]);
  }

  return coordinates;
}

export function minLodgePriceForRange() {
  return 0;
}

export function maxLodgePriceForRange() {
  return 2000;
}

export function minTripPriceForRange() {
  // This used to be 500 so that the slider didnt go all the way down to zero, however some of the routes eg cape winelands don't have
  // a from price higher than 500 without lodges as they are just a few days of self drive
  // return 500;
  // if you change this, please change the link-to-trips componnent
  return 0;
}

export function maxTripPriceForRange() {
  // if you change this, please change the link-to-trips componnent
  return 25000;
}

export function entityType(entity) {
  if (!entity) {
    return '';
  }
  let modelName = entity.get('_internalModel.modelName');
  switch (modelName) {
    case 'lodge':
      return 'property';
    default:
      return modelName
  }
}
export function pluralEntityType(entity) {
  if (!entity) {
    return '';
  }
  let modelName = entity.get('_internalModel.modelName');
  switch (modelName) {
    case 'lodge':
      return 'properties';
    default:
      return modelName+'s'
  }
}

export function trackFacebookDestinationView(destination, tripService) {
  if (window && typeof window.fbq !== "undefined") {
    let destinationProps = {
      content_type: "destination",
    };

    switch (destination.get("_internalModel.modelName")) {
      case "country":
        destinationProps["content_ids"] = "country-" + destination.get("id");
        destinationProps["country"] = destination.get("name");
        break;
      case "region":
        destinationProps["content_ids"] = "region-" + destination.get("id");
        destinationProps["region"] = destination.get("name");
        destinationProps["country"] = destination.get("country.name");
        break;
    }

    const DATE_FORMAT = "YYYY-MM-DD";
    if (tripService.get("currentTrip.itinerary.startDate")) {
      let startDate = moment(
        tripService.get("currentTrip.itinerary.startDate")
      ).format(DATE_FORMAT);
      destinationProps["travel_start"] = startDate;
    }
    if (tripService.get("currentTrip.itinerary.endDate")) {
      let endDate = moment(
        tripService.get("currentTrip.itinerary.endDate")
      ).format(DATE_FORMAT);
      destinationProps["travel_end"] = endDate;
    }

    window.fbq("track", "ViewContent", destinationProps);
  }
}

export function trackEvent(name, properties) {
  if (config.APP.logEvents) {
    console.log(name, properties)
  }

  if (window && window.timbuktu && !window.timbuktu.preRendering && !window.timbuktu.pdfGen && !window.timbuktu.isForAgency && !window.timbuktu.blockAnalyticsAndAffiliates) {
    if (!properties) {
      properties = {};
    }
    if (properties.tripModifyFlag) {
      window.timbuktu.reportConversions.reportModifyTrip();
    }
    // Stringify properties here otherwise a token is added by mixpanel
    let label = JSON.stringify(properties);
    if (mixpanel) {
      mixpanel.track(name, properties);
      if (gtag) {
        gtag('event', name, properties)
      }
    }

    if (
      properties &&
      properties.tripModifyFlag &&
      window &&
      typeof window.fbq !== "undefined"
    ) {
      window.fbq("track", "CustomizeProduct");
    }
  }
}

export function lowerCaseAndDasherized(val) {
  return val.toLowerCase().replace(/ /g, "-"); // replace spaces
}

export function toTitleCase(val) {
  return val.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function toTitleCaseExceptLastWord(val) {
  let titleCase = toTitleCase(val);
  let lastSpaceIndex = titleCase.lastIndexOf(" ");
  return (
    titleCase.slice(0, lastSpaceIndex + 1) +
    titleCase.charAt(lastSpaceIndex + 1).toLowerCase() +
    titleCase.slice(lastSpaceIndex + 2, titleCase.length)
  );
}

export function friendlyIdToSeoName(friendlyId) {
  return toTitleCaseExceptLastWord(friendlyId.replace(/-/g, " "))
    .replace(/Game Reserve|National Park|in Zambia|in Zimbabwe/g, "")
    .replace("Democratic Republic of Congo", "DRC")
    .replace("Drc", "DRC")
    .replace("Of", "of")
    .replace("De", "de")
    .replace("Es", "es")
    .trim();
}

export function capitalizeFirstLetter(val) {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

export function showInViewPort($el, offset, duration, container) {
  let options = {
    duration: duration || 400,
    offset,
    easing: "linear",
  };
  if (container) {
    options.container = container;
  }
  $el.velocity("scroll", options);
}

export function redirectTo(router, url) {
  if (router._routerMicrolib.recognizer.recognize(url)) {
    router.handleURL(url);
    router._routerMicrolib.updateURL(url);
    return true;
  }
  return false;
}

export function getLoccalStorageEmailCapturedKey() {
  return LOCAL_STORAGE_EMAIL_CAPTURED_KEY;
}

export function getCapuredEmail() {
  return getItemFromLocalStorage(LOCAL_STORAGE_EMAIL_CAPTURED_KEY);
}

export function reportEmailToHubspot(email, location, callToAction) {
  // Identify the user to Hubspot Marketing previously known as Leadin
  // Their JS snippet is unable to bind to dynamically created forms
  //So we've hardcoded a form into the HTML. Whenever we capture an email we use this hard coded form to actually submit the email
  // #lif stands for LeadInForm ... Would've made this longer but Leadin cuts it short
  var leadInForm = $("#lif");
  var formDetails = `hidden ${location} ${callToAction}`;
  //We replace the classes on the form to give leadin  a clue about where this form was filled in
  leadInForm.attr("class", formDetails);
  leadInForm.submit(function () {
    return false;
  });
  $("#lead_in_form_email").val(email);
  $("#lead_in_form_button").click();
}

export function reportConversionEmailToGTag() {
  if (window.timbuktu.services.whitelabel.isForTimbuktu && gtag) {
    window.timbuktu.services.session.get('currentUserPromise').then((user)=> {
      let email = (user && user.get('email')) || getCapuredEmail();
      if (email) {
        gtag('set', 'user_data', {
          "email": email
        });
      }
    })
  }
}

export function resolveUrl(baseUrl, src) {
  let url;
  try {
      url = new URL(src, baseUrl);
  } catch(err) {
      console.error(err);
      return;
  }
  return url.href;

}

export function captureEmail(email, location, callToAction) {
  if (window && window.timbuktu.pdfGen) {
    return;
  }

  if (window.timbuktu.services.whitelabel.isForAgency) {
    return
  }

  if (window && typeof window.fbq !== 'undefined') {
    window.fbq('track', 'Lead');
  }

  window.timbuktu.appController.set("emailCaptured", email);
  setItemInLocalStorage(LOCAL_STORAGE_EMAIL_CAPTURED_KEY, email);

  reportEmailToHubspot(email, location, callToAction)

  if (mixpanel) {
    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
    });
  }

  trackEvent("email:capture");
  window.timbuktu.affiliateService.trackEmailCapture();
}

export function getLoccalStorageKeyModalUXFeedbackShown() {
  return LOCAL_STORAGE_MODAL_UX_FEEDBACK_SHOWN;
}

export function getLoccalStorageKeyModalWizardShown() {
  return LOCAL_STORAGE_MODAL_WIZARD_SHOWN;
}

export function loadSvgAsync() {
  // function getErrorMessage(attemptNumber, jqXHR, textStatus, errorThrown) {
  //   var message = `Unable to load svg sprites attempt: Attempt ${attemptNumber} - `;
  //   if (window && window.location) {
  //     message = message + window.location.href;
  //   }
  //   if (navigator && navigator.userAgent) {
  //     message = message + " - " + navigator.userAgent;
  //   }
  //   if (jqXHR) {
  //     message = message + " -jqXHR:  " + JSON.stringify(jqXHR);
  //   }
  //   if (textStatus) {
  //     message = message + " -textStatus: " + textStatus;
  //   }
  //   if (errorThrown) {
  //     message = message + " -errorThrown: " + errorThrown;
  //   }
  //   return message;
  // }

  // Load the svg assets in async
  var url = "/assets/images/svg-sprites.svg";

  $.ajax({
    url: url,
  })
    .done(function (thing, status, response) {
      $("body").append(response.responseText);
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      if (!window.timbuktu.preRendering) {
        // let message = getErrorMessage(1, jqXHR, textStatus, errorThrown);
        // Bugsnag.notify(message, message);
        // trackEvent("svg-load:first-attempt:fail", {
        //   message,
        // });
      }
      $.ajax({
        url: url,
      })
        .done(function (thing, status, response) {
          $("body").append(response.responseText);
          trackEvent("svg-load:second-attempt:pass");
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (!window.timbuktu.preRendering) {
            // let message = getErrorMessage(2, jqXHR, textStatus, errorThrown);
            // Bugsnag.notify(message, message);
            // trackEvent("svg-load:second-attempt:fail", {
            //   message,
            // });
          }
        });
    });
}

export function getCookieValue(a, b) {
  if (typeof document !== "undefined") {
    b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  } else {
    return "";
  }
}

// At minimum needs path, width and height
export function buildImgXUrl(
  path,
  w,
  h,
  auto,
  crop,
  fit,
  dpr,
  q,
  blend,
  balph,
  bm,
  px,
  blur,
  mask
) {
  if (!path) {
    return;
  }

  let src,
    params = "";

  if (
    !w &&
    !h &&
    !auto &&
    !crop &&
    !fit &&
    !dpr &&
    !q &&
    !blend &&
    !balph &&
    !bm
  ) {
    src = path;
  } else {
    params += w ? "w=" + w : "";
    params += h ? "&h=" + h : "";
    params += crop ? "&crop=" + encodeURI(crop) : "&crop=fit";
    params += fit ? "&fit=" + fit : "&fit=min";
    params += dpr ? "&dpr=" + dpr : "";
    params += q ? "&q=" + q : "&q=50";
    params += blend ? "&blend=" + blend : "";
    params += balph ? "&balph=" + balph : "";
    params += bm ? "&bm=" + bm : "";
    params += px ? "&px=" + px : "";
    params += blur ? "&blur=" + blur : "";
    params += mask ? "&mask=" + mask : "";
    if (window.timbuktu.pdfGen) {
      params += "&fm=jpg"
    } else {
      params += auto ? "&auto=" + auto : "&auto=format";
    }

    src = path + "?" + params;
  }

  return src;
}

export function hexToRgb(hex) {
  hex = hex.replace(/^#/, "");

  const num = parseInt(hex, 16);
  const r = num >> 16;
  const g = (num >> 8) & 255;
  const b = num & 255;
  return `${r}, ${g}, ${b}`;
}

export function hexToHsl(hex) {
  hex = hex.replace(/^#/, "");

  const num = parseInt(hex, 16);
  const r = num >> 16;
  const g = (num >> 8) & 255;
  const b = num & 255;
  return rgbToHsl(r, g, b);
}

function rgbToHsl(r, g, b) {
  var d, h, l, max, min, s;
  r /= 255;
  g /= 255;
  b /= 255;
  max = Math.max(r, g, b);
  min = Math.min(r, g, b);
  h = 0;
  s = 0;
  l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
    }
    h /= 6;
  }

  return {
    h: h * 360,
    s: s * 100,
    l: l * 100,
  };
}


export function convertTemplateTransferToCustomTransfer(options) {
  let sourceStage = options.sourceStage;
  let targetStage = options.targetStage;
  let stageIndex = options.stageIndex;
  let targetStages = options.targetStages;
  targetStage.set("customTransfer", sourceStage.get('templateTransfer').makeCopy());

  let description = transferDescriptionParagraph(null, {
    prevStage: prevNonTransferStage(targetStages, stageIndex),
    nextStage: nextNonTransferStage(targetStages, stageIndex),
    transfer: sourceStage.get('templateTransfer'),
  });

  targetStage.set("customTransfer.description", description);
  targetStage.set('stageType', 'custom-transfer')
  return targetStage;
}





export function shuffleArray(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function teamSort(a, b) {
  // sorts by name - ignores upper and lowercase
  let nameA = a.name.toUpperCase();
  let nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function getEntityFilters(entities) {
  let filters = []
  if (entities.lodges) {
    filters = filters.concat(entities.lodges.map((entity) => {
      return {id: entity.id, type: 'lodge', name: entity.name};
    }));
  }
  if (entities.countries) {
    filters = filters.concat(entities.countries.map((entity) => {
      return {id: entity.id, type: 'country', name: entity.name};
    }));
  }
  if (entities.regions) {
    filters = filters.concat(entities.regions.map((entity) => {
      return {id: entity.id, type: 'region', name: entity.name};
    }));
  }
  if (entities.experiences) {
    filters = filters.concat(entities.experiences.map((entity) => {
      return {id: entity.id, type: 'experience', name: entity.name};
    }));
  }

  return filters;
}

export function getEntityFiltersForServerQuery(entities) {
  return JSON.stringify(getEntityFilters(entities));
}

export function getEntityFiltersByEntityIdsForServerQuery(entityIds) {

  let filters = []
  if (entityIds.lodgeIds) {
    filters = filters.concat(entityIds.lodgeIds.map((entityId) => {
      return {id: entityId, type: 'lodge'};
    }));
  }
  if (entityIds.countryIds) {
    filters = filters.concat(entityIds.countryIds.map((entityId) => {
      return {id: entityId, type: 'country'};
    }));
  }
  if (entityIds.regionIds) {
    filters = filters.concat(entityIds.regionIds.map((entityId) => {
      return {id: entityId, type: 'region'};
    }));
  }
  if (entityIds.experienceIds) {
    filters = filters.concat(entityIds.experienceIds.map((entityId) => {
      return {id: entityId, type: 'experience'};
    }));
  }

  return JSON.stringify(filters);
}

export const SUGGESTIONS = {
  "itinerary.whatsIncluded": [
    "All accommodation, domestic flights, transfers, meals, drinks and guided safari activities as specified in your itinerary.",
    "Return airport transfers",
    "Accommodation as listed",
    "All meals and drinks as listed",
    "Activities as listed",
    "Airport meet and greet",
    "Road transfers",
    "English-speaking guide",
    "Laundry Service",
  ],
  "itinerary.whatsExcluded": [
    "International flights, personal travel insurance, visas and tipping.",
    "Items of a personal nature",
    "Laundry Service",
  ],
  "stage.whatsIncluded": [
    "Breakfast daily",
    "All meals",
    "All meals and drinks (excluding premium brands and champagne)",
    "All meals, drinks and activities",
    "2x game activities per day",
    "All park and concession fees",
    "Laundry services"
  ],
  "stage.whatsExcluded": [
    "Items of a personal nature",
    "Spa treatments",
    "Meals not specified",
    "Drinks",
    "Premium drinks",
    "Activities not listed",
    "All park and concession fees",
    "Gratuities",
    "Laundry services"
  ],
  "stage.firstTransfer": [
    "You will be met on arrival at the airport and transferred to your first lodge.",
  ],
  "stage.lastTransfer": [
    "You will be transferred to the airport for your onward flight.",
  ],
  "stage.roomType": [
    "Standard Room",
    "Luxury Room",
    "Double Room",
    "Suite",
    "Safari Tent",
  ],
};

export const TRANSFER_VEHICLE_TYPES = {
  commercial_flight: {
    label: "Flight transfer",
    svg: "flight",
    sequence: 1,
  },
  light_aircraft: {
    label: "Flight transfer",
    svg: "flight",
    sequence: 2,
  },
  road_transfer: {
    label: "Road transfer",
    svg: "road",
    sequence: 3,
  },
  guided_journey: {
    label: "Road transfer with your guide",
    svg: "road",
    sequence: 4,
  },
  "self-drive": {
    label: "Self-drive transfer",
    svg: "self-drive",
    sequence: 5,
  },
  helicopter: {
    label: "Helicopter transfer",
    svg: "helicopter",
    sequence: 6,
  },
  boat: {
    label: "Boat transfer",
    svg: "boat",
    sequence: 7,
  },
  walk: {
    label: "Walking transfer",
    svg: "walk",
    sequence: 8,
  },
  train: {
    label: "Train",
    svg: "general",
    sequence: 9,
  },
  speed_boat: {
    label: "Speed boat",
    svg: "boat",
    sequence: 10,
  },
  sea_plane: {
    label: "Sea plane",
    svg: "general",
    sequence: 11,
  },
  own_arrangement: {
    label: "Own arranged transfer",
    svg: "general",
    sequence: 12,
  },
  general: {
    label: "Transfer",
    svg: "general",
    sequence: 13,
  },
  cycling: {
    label: "Cycling",
    svg: "general",
    sequence: 14,
  },
};

export function prevNonTransferStage(stages, stageIndex) {
  let prevStage = null;
  for (let i = stageIndex - 1; i > -1; i--) {
    if (stages.objectAt(i).notTransferStage) {
      prevStage = stages.objectAt(i);
      break;
    }
  }
  return prevStage;
}

export function nextNonTransferStage(stages, stageIndex) {
  let nextStage = null;
  for (let i = stageIndex + 1; i < stages.length; i++) {
    if (stages.objectAt(i).notTransferStage) {
      nextStage = stages.objectAt(i);
      break;
    }
  }
  return nextStage;
}

// NOTE the inverseness
export function prevTransferStage(stages, stageIndex) {
  let prevStage = null;
  for (let i = stageIndex; i > -1; i--) {
    if (stages.objectAt(i).isTransferStage) {
      prevStage = stages.objectAt(i);
      break;
    }
  }
  return prevStage;
}

export function nextTransferStage(stages, stageIndex) {
  let nextStage = null;
  for (let i = stageIndex + 1; i < stages.length; i++) {
    if (stages.objectAt(i).isTransferStage) {
      nextStage = stages.objectAt(i);
      break;
    }
  }
  return nextStage;
}

export function transferDescriptionParagraph(params, namedArgs) {
  let prevDest = null,
    nextDest = null,
    prevStage = namedArgs.prevStage,
    nextStage = namedArgs.nextStage,
    transfer = namedArgs.transfer,
    needsTransfer = namedArgs.needsTransfer,
    startPoint = "from your lodge",
    endPoint = "your next lodge";

  if (!prevStage || !nextStage) {
    return '';
  }

  if (!transfer || needsTransfer) {
    return "Loading...";
  }

  // If there is a description. That will take preference. For example custom transfers.
  if (transfer.description) {
    // Own arrangements has a description for example.
    return transfer.description;
  }

  if (transfer.isFallback) {
    return "We’ll make these transfer arrangements for you, from private vehicles to helicopters. Get in touch to find out more.";
  }

  switch (namedArgs.prevStage.stageType) {
    case "lodge":
      prevDest = prevStage.get("lodge.region");
      startPoint = "from " + prevStage.get("lodge.name");
      break;

    case "location":
      prevDest = prevStage.get("location");
      // does this cause issues sometimes?
      startPoint = "from " + prevStage.get("location.name");
      break;

    case "region":
      prevDest = prevStage.get("region");
      break;

    default:
      break;
  }

  switch (nextStage.stageType) {
    case "lodge":
      nextDest = nextStage.get("lodge.region");
      endPoint = nextStage.get("lodge.name");
      break;

    case "location":
      nextDest = nextStage.get("location");
      // does this cause issues sometimes?
      endPoint = nextStage.get("location.name");
      break;

    case "region":
      nextDest = nextStage.get("region");
      break;

    default:
      break;
  }

  let desc = "";

  switch (transfer.descriptionCode) {
    /*
      Note: When importing the transfers, and the inverse transfers are created we also reverse the .locations,
      so here they follow the same order. location1 (etc) is correct for both the verse and inverse
    */

    case "road_light_comm_road":
    case "road_comm_light_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will catch your next flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_comm_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} and driven to ${endPoint}`;
      break;

    case "road_comm":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${nextDest.get("name")}.`;
      break;
    case "comm_road":
      desc = `You will fly from ${prevDest.get("name")} to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} and driven to ${endPoint}.`;
      break;

    // case 'road_light_road':
    case "road_light_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_light_light_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will catch your next flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_light_heli":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get(
          "name"
        )} you will be met and taken to your helicopter for your flight to ${endPoint}`;
      break;

    case "road_light_heli_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get(
          "name"
        )} you will be met and taken to your helicopter for your flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_light_boat_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take a boat to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_light":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${nextDest.get("name")}.`;
      break;
    case "light_road":
      desc = `You will fly from ${prevDest.get("name")} to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_light_road_comm_road":
    case "road_comm_road_light_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will be met and driven to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(3)
        .get("name")}. You will be met on arrival at ${transfer
        .get("locations")
        .objectAt(3)
        .get("name")} and driven to ${endPoint}.`;
      break;

    case "road_comm_comm_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. At ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your next flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. On arrival at ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} you will be met and driven to ${endPoint}.`;
      break;

    case "heli_light_light":
    case "light_light_heli":
      desc = `You will be taken to your Helicopter for your short flight to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")}. On arrival at ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} for your onward journey.`;
      break;

    case "road_light_comm_comm_light_road":
    case "road_light_comm_comm_heli_road":
    case "road_heli_comm_comm_light_road":
      desc = `You will be collected ${startPoint} and taken to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(3)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(3)
        .get(
          "name"
        )} you will be met and transferred for a short flight to ${transfer
        .get("locations")
        .objectAt(4)
        .get("name")} followed by a short drive to ${endPoint}.`;
      break;

    case "road_light_comm_comm_road":
    case "road_comm_comm_light_road":
      desc = `You will be collected ${startPoint} and taken to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(3)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(3)
        .get(
          "name"
        )} you will be met and transferred for a short drive to ${endPoint}.`;
      break;

    case "road_light_light_heli":
    case "heli_light_light_road":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(2)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(2)
        .get(
          "name"
        )} you will be met and taken to your helicopter for your flight to ${endPoint}.`;
      break;

    case "road_light_light":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your flight to ${endPoint}.`;
      break;

    case "road_light_boat":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your boat to ${endPoint}.`;
      break;

    case "road_boat_light":
      desc = `You will be collected ${startPoint} and driven to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} for your boat to ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")}. From ${transfer
        .get("locations")
        .objectAt(1)
        .get("name")} you will take your flight to ${endPoint}.`;
      break;

    case "light_light_road":
      desc = `You will fly from ${prevDest.get("name")} to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get(
          "name"
        )}, where you will be met on arrival and driven to ${endPoint}.`;
      break;

    case "boat_light_road":
      desc = `You will take a boat from ${prevDest.get("name")} to ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")}. On arrival in ${transfer
        .get("locations")
        .objectAt(0)
        .get("name")} you will take your flight to ${transfer
        .get("locations")
        .objectAt(1)
        .get(
          "name"
        )}, where you will be met on arrival and driven to ${endPoint}.`;
      break;

    case "self":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `Collect your hire car from ${prevDest.get(
          "name"
        )} and make your own way to ${endPoint}.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `Make your own way ${startPoint} to ${nextDest.get("name")}.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `Make your own way ${startPoint} to ${endPoint}.`;
      }
      break;

    case "guided":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be met on arrival at ${prevDest.get(
          "name"
        )} by your guide and driven to ${endPoint}. Be sure to keep your binoculars handy as you never know what you might see on route.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `You will be collected ${startPoint} by your guide and driven to ${nextDest.get(
          "name"
        )}. Be sure to keep your binoculars handy as you never know what you might see on route.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be collected ${startPoint} by your guide and driven to ${endPoint}. Be sure to keep your binoculars handy as you never know what you might see on route.`;
      }
      break;

    case "walk":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will walk from ${prevDest.get(
          "name"
        )} to ${endPoint} with your guide.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `You will walk ${startPoint} to ${nextDest.get(
          "name"
        )} with your guide.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will walk ${startPoint} to ${endPoint} with your guide.`;
      }
      break;

    case "heli":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be met on arrival at ${prevDest.get(
          "name"
        )} and flown to ${endPoint}.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `You will be collected ${startPoint} and flown to ${nextDest.get(
          "name"
        )}.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be collected ${startPoint} and flown to ${endPoint}.`;
      }
      break;

    case "road":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be met on arrival at ${prevDest.get(
          "name"
        )} and driven to ${endPoint}.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `You will be collected ${startPoint} and driven to ${nextDest.get(
          "name"
        )}.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be collected ${startPoint} and driven to ${endPoint}.`;
      }
      break;

    case "boat":
      // AtoL
      if (prevStage.isLocationStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be met on arrival at ${prevDest.get(
          "name"
        )} and transferred by boat to ${endPoint}.`;
      }
      // LtoA
      if (prevStage.isLodgeOrRegionStage && nextStage.isLocationStage) {
        desc = `You will be collected ${startPoint} and transferred by boat to ${nextDest.get(
          "name"
        )}.`;
      }
      // LtoL
      if (prevStage.isLodgeOrRegionStage && nextStage.isLodgeOrRegionStage) {
        desc = `You will be collected ${startPoint} and transferred by boat to ${endPoint}.`;
      }
      break;

    default:
      // Basically deals with template transfers
      console.log(
        "Undefined code:",
        transfer.descriptionCode,
        "- needs attention"
      );
      break;
  }

  return desc;
}

// Please see the full list of countries in the Hawk codebase and use that as source of truth. For now, I'm just including the countries from "client/guest" countries
export const COUNTRIES = [
  { code: "AD", name: "Andorra", defaultCurrency: "eur" },
  { code: "AE", name: "UAE - United Arab Emirates" },
  { code: "AF", name: "Afghanistan" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AI", name: "Anguilla" },
  { code: "AL", name: "Albania" },
  { code: "AM", name: "Armenia" },
  { code: "AO", name: "Angola" },
  { code: "AQ", name: "Antarctica" },
  { code: "AR", name: "Argentina" },
  { code: "AS", name: "American Samoa" },
  { code: "AT", name: "Austria", defaultCurrency: "eur" },
  { code: "AU", name: "Australia", defaultCurrency: "usd", guest: true },
  { code: "AW", name: "Aruba" },
  { code: "AX", name: "Åland Islands" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BB", name: "Barbados" },
  { code: "BD", name: "Bangladesh" },
  { code: "BE", name: "Belgium" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BG", name: "Bulgaria" },
  { code: "BH", name: "Bahrain" },
  { code: "BI", name: "Burundi" },
  { code: "BJ", name: "Benin" },
  { code: "BL", name: "Saint Barthélemy" },
  { code: "BM", name: "Bermuda" },
  { code: "BN", name: "Brunei" },
  { code: "BO", name: "Bolivia" },
  { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { code: "BR", name: "Brazil" },
  { code: "BS", name: "Bahamas" },
  { code: "BT", name: "Bhutan" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BW", name: "Botswana" },
  { code: "BY", name: "Belarus" },
  { code: "BZ", name: "Belize" },
  { code: "CA", name: "Canada", defaultCurrency: "usd", guest: true },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CD", name: "DRC (Democratic Republic of the Congo)" },
  { code: "CF", name: "Central African Republic" },
  { code: "CG", name: "Republic of the Congo" },
  { code: "CH", name: "Switzerland" },
  { code: "CI", name: "Côte d'Ivoire" },
  { code: "CK", name: "Cook Islands" },
  { code: "CL", name: "Chile" },
  { code: "CM", name: "Cameroon" },
  { code: "CN", name: "China" },
  { code: "CO", name: "Colombia" },
  { code: "CR", name: "Costa Rica" },
  { code: "CU", name: "Cuba" },
  { code: "CV", name: "Cabo Verde (Cape Verde)" },
  { code: "CW", name: "Curaçao" },
  { code: "CX", name: "Christmas Island" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DE", name: "Germany", defaultCurrency: "eur" },
  { code: "DJ", name: "Djibouti" },
  { code: "DK", name: "Denmark" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "DZ", name: "Algeria" },
  { code: "EC", name: "Ecuador" },
  { code: "EE", name: "Estonia" },
  { code: "EG", name: "Egypt" },
  { code: "EH", name: "Western Sahara" },
  { code: "ER", name: "Eritrea" },
  { code: "ES", name: "Spain", defaultCurrency: "eur" },
  { code: "ET", name: "Ethiopia" },
  { code: "FI", name: "Finland" },
  { code: "FJ", name: "Fiji" },
  { code: "FK", name: "Falkland Islands (Malvinas)" },
  { code: "FM", name: "Micronesia" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FR", name: "France", defaultCurrency: "eur" },
  { code: "GA", name: "Gabon" },
  {
    code: "GB",
    name: "UK (United Kingdom)",
    defaultCurrency: "gbp",
    guest: true,
  },
  { code: "GD", name: "Grenada" },
  { code: "GE", name: "Georgia" },
  { code: "GF", name: "French Guiana" },
  { code: "GG", name: "Guernsey" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GL", name: "Greenland" },
  { code: "GM", name: "Gambia" },
  { code: "GN", name: "Guinea" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "GR", name: "Greece", defaultCurrency: "eur" },
  { code: "GS", name: "South Georgia and the South Sandwich Islands" },
  { code: "GT", name: "Guatemala" },
  { code: "GU", name: "Guam" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HK", name: "Hong Kong" },
  { code: "HM", name: "Heard Island and McDonald Islands" },
  { code: "HN", name: "Honduras" },
  { code: "HR", name: "Croatia" },
  { code: "HT", name: "Haiti" },
  { code: "HU", name: "Hungary" },
  { code: "ID", name: "Indonesia" },
  { code: "IE", name: "Ireland", defaultCurrency: "eur", guest: true },
  { code: "IL", name: "Israel" },
  { code: "IM", name: "Isle of Man" },
  { code: "IN", name: "India" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "IQ", name: "Iraq" },
  { code: "IR", name: "Iran" },
  { code: "IS", name: "Iceland" },
  { code: "IT", name: "Italy", defaultCurrency: "eur" },
  { code: "JE", name: "Jersey" },
  { code: "JM", name: "Jamaica" },
  { code: "JO", name: "Jordan" },
  { code: "JP", name: "Japan" },
  { code: "KE", name: "Kenya" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "KH", name: "Cambodia" },
  { code: "KI", name: "Kiribati" },
  { code: "KM", name: "Comoros" },
  { code: "KN", name: "Saint Kitts and Nevis" },
  { code: "KP", name: "North Korea" },
  { code: "KR", name: "South Korea" },
  { code: "KW", name: "Kuwait" },
  { code: "KY", name: "Cayman Islands" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "LA", name: "Laos" },
  { code: "LB", name: "Lebanon" },
  { code: "LC", name: "Saint Lucia" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LK", name: "Sri Lanka" },
  { code: "LR", name: "Liberia" },
  { code: "LS", name: "Lesotho" },
  { code: "LT", name: "Lithuania", defaultCurrency: "eur" },
  { code: "LU", name: "Luxembourg", defaultCurrency: "eur" },
  { code: "LV", name: "Latvia" },
  { code: "LY", name: "Libya" },
  { code: "MA", name: "Morocco" },
  { code: "MC", name: "Monaco" },
  { code: "MD", name: "Moldova, Republic of" },
  { code: "ME", name: "Montenegro" },
  { code: "MF", name: "Saint Martin, (French part)" },
  { code: "MG", name: "Madagascar" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MK", name: "North Macedonia" },
  { code: "ML", name: "Mali" },
  { code: "MM", name: "Myanmar" },
  { code: "MN", name: "Mongolia" },
  { code: "MO", name: "Macao" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MS", name: "Montserrat" },
  { code: "MT", name: "Malta" },
  { code: "MU", name: "Mauritius" },
  { code: "MV", name: "Maldives" },
  { code: "MW", name: "Malawi" },
  { code: "MX", name: "Mexico" },
  { code: "MY", name: "Malaysia" },
  { code: "MZ", name: "Mozambique" },
  { code: "NA", name: "Namibia" },
  { code: "NC", name: "New Caledonia" },
  { code: "NE", name: "Niger" },
  { code: "NF", name: "Norfolk Island" },
  { code: "NG", name: "Nigeria" },
  { code: "NI", name: "Nicaragua" },
  { code: "NL", name: "Netherlands" },
  { code: "NO", name: "Norway" },
  { code: "NP", name: "Nepal" },
  { code: "NR", name: "Nauru" },
  { code: "NU", name: "Niue" },
  { code: "NZ", name: "New Zealand", defaultCurrency: "usd", guest: true },
  { code: "OM", name: "Oman" },
  { code: "PA", name: "Panama" },
  { code: "PE", name: "Peru" },
  { code: "PF", name: "French Polynesia" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PH", name: "Philippines" },
  { code: "PK", name: "Pakistan" },
  { code: "PL", name: "Poland" },
  { code: "PM", name: "Saint Pierre and Miquelon" },
  { code: "PN", name: "Pitcairn" },
  { code: "PR", name: "Puerto Rico" },
  { code: "PS", name: "Palestine" },
  { code: "PT", name: "Portugal" },
  { code: "PW", name: "Palau" },
  { code: "PY", name: "Paraguay" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Reunion" },
  { code: "RO", name: "Romania" },
  { code: "RS", name: "Serbia" },
  { code: "RU", name: "Russia" },
  { code: "RW", name: "Rwanda" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SC", name: "Seychelles" },
  { code: "SD", name: "Sudan" },
  { code: "SE", name: "Sweden" },
  { code: "SG", name: "Singapore" },
  { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "SI", name: "Slovenia", defaultCurrency: "eur" },
  { code: "SJ", name: "Svalbard and Jan Mayen" },
  { code: "SK", name: "Slovakia", defaultCurrency: "eur" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SM", name: "San Marino" },
  { code: "SN", name: "Senegal" },
  { code: "SO", name: "Somalia" },
  { code: "SR", name: "Suriname" },
  { code: "SS", name: "South Sudan" },
  { code: "ST", name: "Sao Tome and Principe (São Tomé and Príncipe)" },
  { code: "SV", name: "El Salvador" },
  { code: "SX", name: "Sint Maarten, (Dutch part)" },
  { code: "SY", name: "Syria" },
  { code: "SZ", name: "Swaziland (Eswatini)" },
  { code: "TC", name: "Turks and Caicos Islands" },
  { code: "TD", name: "Chad" },
  { code: "TF", name: "French Southern Territories" },
  { code: "TG", name: "Togo" },
  { code: "TH", name: "Thailand" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TK", name: "Tokelau" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TN", name: "Tunisia" },
  { code: "TO", name: "Tonga" },
  { code: "TR", name: "Turkey" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TV", name: "Tuvalu" },
  { code: "TW", name: "Taiwan, Province of China" },
  { code: "TZ", name: "Tanzania" },
  { code: "UA", name: "Ukraine" },
  { code: "UG", name: "Uganda" },
  {
    code: "US",
    name: "USA (United States of America)",
    defaultCurrency: "usd",
    guest: true,
  },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VA", name: "Holy See" },
  { code: "VC", name: "Saint Vincent and the Grenadines" },
  { code: "VE", name: "Venezuela" },
  { code: "VG", name: "Virgin Islands, British" },
  { code: "VI", name: "Virgin Islands, U.S." },
  { code: "VN", name: "Vietnam" },
  { code: "VU", name: "Vanuatu" },
  { code: "WF", name: "Wallis and Futuna" },
  { code: "WS", name: "Samoa" },
  { code: "YE", name: "Yemen" },
  { code: "YT", name: "Mayotte" },
  { code: "ZA", name: "South Africa", defaultCurrency: "zar", guest: true },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
  {
    code: "T_EU",
    name: "EU",
    defaultCurrency: "eur",
    guest: true,
    grouping: true,
  },
  {
    code: "T_NA",
    name: "North America",
    defaultCurrency: "usd",
    guest: true,
    grouping: true,
  },
  {
    code: "T_RW",
    name: "Rest of World",
    defaultCurrency: "usd",
    guest: true,
    grouping: true,
  },
  {
    code: "T_ME",
    name: "Middle East",
    defaultCurrency: "usd",
    guest: true,
    grouping: true,
  },
  {
    code: "T_UN",
    name: "Unknown",
    defaultCurrency: "usd",
    guest: true,
    grouping: true,
  },
].sortBy("name");

export const USA_STATES = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky[D]", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts[D]", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania[D]", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia[D]", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
].sortBy("name");

export function setupConversionReportingWrapper() {

  let reportConversions = window.timbuktu.reportConversions;
  // First we set up all the adwords conversions

  function adwordsReportWizardLead(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/SeZDCPv8l50BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()

      //track event for UK/ROW
      gtag('event', 'conversion', { 'send_to': 'AW-10973045828/QKP-CNCE29kDEMTIrfAo', 'event_callback': callback })
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    return false;
  }
  reportConversions.adwordsReportWizardLead=adwordsReportWizardLead;

  function adwordsReportTripEnquiry(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/Bqu5CLKBh50BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()

      //Track for UK/ROW
      gtag('event', 'conversion', { 'send_to': 'AW-10973045828/k0koCNmE29kDEMTIrfAo', 'event_callback': callback });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    if (rdt) {
      rdt('track', 'Lead')
      rdt('track', 'Custom', { customEventName: 'Trip Enquiry' });
    }

    return false;
  }
  reportConversions.adwordsReportTripEnquiry=adwordsReportTripEnquiry;

  function adwordsReportGeneralEnquiry(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/YPkvCJbYj50BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()

      //track event for UK/ROW
      gtag('event', 'conversion', { 'send_to': 'AW-10973045828/8O1WCNaE29kDEMTIrfAo', 'event_callback': callback });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    if (rdt) {
      rdt('track', 'Lead')
      rdt('track', 'Custom', { customEventName: 'General Enquiry' });
    }
    return false;
  }
  reportConversions.adwordsReportGeneralEnquiry=adwordsReportGeneralEnquiry;

  function adwordsReportNewsletterSubscription(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/hVHKCKf8hp0BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    return false;
  }
  reportConversions.adwordsReportNewsletterSubscription=adwordsReportNewsletterSubscription;

  function adwordsReportUserRegistration(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/sLQOCK2Im50BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    return false;
  }
  reportConversions.adwordsReportUserRegistration=adwordsReportUserRegistration;

  function adwordsReportModifyTrip(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/CiR9CIDdj50BEJr9h5sD',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()

    }
    return false;
  }
  reportConversions.adwordsReportModifyTrip=adwordsReportModifyTrip;

  function adwordsReportSaveTrip(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/Q1g4CPjXj50BEJr9h5sD',
          'event_callback': callback
      });
      //Track event for UK / ROW
      gtag('event', 'conversion', { 'send_to': 'AW-10973045828/aZQeCNOE29kDEMTIrfAo', 'event_callback': callback });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    if (rdt) {
      rdt('track', 'Lead')
      rdt('track', 'Custom', { customEventName: 'Save Trip' });

    }
    return false;
  }
  reportConversions.adwordsReportSaveTrip=adwordsReportSaveTrip;

  function adwordsReportPayDeposit(priceInGbp) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    if (gtag) {
      gtag('event', 'conversion', {
          'send_to': 'AW-862060186/ooWFCP7Xj50BEJr9h5sD',
          'value': priceInGbp,
          'currency': 'GBP',
          'transaction_id': '',
          'event_callback': callback
      });
      // Enhanced conversion reported for email
      reportConversionEmailToGTag()
    }
    if (rdt) {
      rdt('track', 'Purchase')
    }
    return false;
  }
  reportConversions.adwordsReportPayDeposit=adwordsReportPayDeposit;

  // Now we set up the generic conversion functions that call adwords and other providers

  reportConversions.reportWizardLead=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportWizardLead();
      window.uetq.push('event', 'complete', {'event_category': 'wizard', 'event_label': 'wizard_complete', 'event_value': 0});
    }
  }
  reportConversions.reportTripEnquiry=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportTripEnquiry();
      window.uetq.push('event', 'enquiry', {'event_category': 'trip', 'event_label': 'trip_enquiry', 'event_value': 0});
    }

  }
  reportConversions.reportGeneralEnquiry=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportGeneralEnquiry();
      window.uetq.push('event', 'enquiry', {'event_category': 'enquiry', 'event_label': 'general_enquiry', 'event_value': 0});
    }

  }
  reportConversions.reportNewsletterSubscription=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportNewsletterSubscription();
      window.uetq.push('event', 'subscribe', {'event_category': 'newsletter', 'event_label': 'newsletter_subscribe', 'event_value': 0});
    }

  }
  reportConversions.reportUserRegistration=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportUserRegistration();
      window.uetq.push('event', 'register', {'event_category': 'user', 'event_label': 'user_register', 'event_value': 0});
    }

  }
  reportConversions.reportModifyTrip=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportModifyTrip();
      // No Bing tag at the moment
    }

  }
  reportConversions.reportSaveTrip=function() {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      reportConversions.adwordsReportSaveTrip();
      window.uetq.push('event', 'save', {'event_category': 'trip', 'event_label': 'trip_save', 'event_value': 0});
    }

  }
  reportConversions.reportPayDeposit=function(tripPriceInGbp) {
    if (window.timbuktu.reportConversions.allowConversionReports) {
      tripPriceInGbp=Math.round(tripPriceInGbp * 100) / 100;
      reportConversions.adwordsReportPayDeposit(tripPriceInGbp);
      window.uetq.push('event', 'deposit-paid', {'event_category': 'trip', 'event_label': 'trip_deposit-paid', 'event_value': tripPriceInGbp, 'gv': tripPriceInGbp, 'gc': 'GBP'});
      if (window && typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Purchase', {currency: "GBP", value: tripPriceInGbp});
      }
    }
  }
}
