import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  reads
} from '@ember/object/computed';
import {
  htmlSafe
} from '@ember/string';

export default Component.extend({

  ui: service(),
  whitelabel: service(),

  click() {
    if (this.onClick) {
      this.onClick();
    }
  },

// If the size of this banner is dynamic, you can use the following commented code to set the height of the banner to the height of the image when the image is loaded:
// FeaturedBanner height is static at the mo

  didInsertElement() {
    this._super(...arguments);

    // this.set('boundLoadCallback', run.bind(this.ui, 'setFeaturedBannerHeight', this));
    // this.$('img')[0].addEventListener('load', this.boundLoadCallback);


  },

  // willDestroyElement() {
  //   if (this.boundLoadCallback && this.$('img')[0]) {
  //     this.$('img')[0].removeEventListener('load',  this.boundLoadCallback);
  //   }
  // },


});
