import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';
import Component from '@ember/component';
import tripCarouselImages from '../../mixins/trip-carousel-images';
import RSVP from 'rsvp';
import {
  getTripImageFromRegionalImage,
  getTripImageFromEntityMetaImage
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';

export default Component.extend({

  classNames: ['override-trip-images-manager'],
  store: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),

  @alias('xplorer.isLoading') isLoading: null,

  choosingImage: false,
  choosingImageType: null,
  coverStyles: ['left top', 'left center', 'left bottom' ,'right top', 'right center', 'right bottom', 'center top', 'center center', 'center bottom'],


  didInsertElement() {
    this._super(...arguments);
  },

  didReceiveAttrs(){
    this._super(...arguments);
    if (this.get('tripCarouselImagesMaybePromise').then){
      this.get('tripCarouselImagesMaybePromise').then((images) => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }
        this.set('tripCarouselImages', images)
        this.setupImages()
      })
    } else {
      this.set('tripCarouselImages',  this.get('tripCarouselImagesMaybePromise'))
      this.setupImages()
    }
    if (this.tripImageType=='kodak') {
      this.set('urlPropertyName', 'trip.heroEntity.kodakOriginalUrl')
      this.set('tripImagePropertyName', 'trip.overrideKodak')
      this.set('tripImageCoverStylePropertyName', 'trip.overrideKodak.coverStyle')
    } else if (this.tripImageType=='second') {
      this.set('urlPropertyName', 'trip.firstRegionImage.originalUrl')
      this.set('tripImagePropertyName', 'trip.overrideSecondKodak')
      this.set('tripImageCoverStylePropertyName', 'trip.overrideSecondKodak.coverStyle')
    } else if (this.tripImageType=='third') {
      this.set('urlPropertyName', 'trip.secondRegionImage.originalUrl')
      this.set('tripImagePropertyName', 'trip.overrideThirdKodak')
      this.set('tripImageCoverStylePropertyName', 'trip.overrideThirdKodak.coverStyle')
    }


    // this.set('coverStylePropertyName', 'tripService.overrideStageImageOptions.stage.'+this.tripService.overrideStageImageOptions.imageType+'CoverStyle')
    // this.set('overrideUrlPropertyName', 'tripService.overrideStageImageOptions.stage.override'+capitalizeFirstLetter(this.tripService.overrideStageImageOptions.imageType)+'Url')
    // this.set('overrideCoverStylePropertyName', 'tripService.overrideStageImageOptions.stage.override'+capitalizeFirstLetter(this.tripService.overrideStageImageOptions.imageType)+'CoverStyle')

  },

  // @computed('currentRouteName')
  // dashedRouteName(currentRouteName) {
  //   if (isNone(currentRouteName)) {
  //     return '';
  //   }
  //   return currentRouteName.replace(/\./g, '-');
  // },


  setupImages(){
    let tripCarouselImages = this.tripCarouselImages;

      let mapping = {};

      RSVP.all(this.trip.get('uniqueRegions').mapBy('parentRegion').uniq()).then((parentRegions)=> {
        let regionsForImages=[];

        this.trip.get('uniqueRegions').forEach((region)=> {
          regionsForImages.pushObject(region)
          let parentRegionId = region.belongsTo('parentRegion').id();
          if (parentRegionId) {
            regionsForImages.pushObject(this.store.peekRecord('region', parentRegionId))
          }
        })
        regionsForImages = regionsForImages.uniq();

        let regionIds = regionsForImages.mapBy('id');

        regionIds.forEach((regionId)=> {
          let region = this.store.peekRecord('region', regionId);
          mapping[regionId]={id: regionId, name: region.name, images: []}
        })

        regionsForImages.forEach((region)=> {
          let regionId = region.id
          if (mapping[regionId]) {
            mapping[regionId].images = region.metaImagesAfterQuote.map((experienceImage) => {
              return getTripImageFromRegionalImage(experienceImage, region)
            })
            mapping[regionId].images.unshift(getTripImageFromRegionalImage({
              description: region.name,
              originalUrl: region.heroEntity.kodakOriginalUrl,
              coverStyle: region.heroEntity.coverStyle,
              regionId: region.id
            }, region))
          }
        })

        let loopable = [];
        regionIds.forEach((regionId)=> {
          loopable.pushObject(mapping[regionId])
        })

        this.trip.itinerary.accomStages.forEach((accomStage)=> {
          if (accomStage.lodge) {
            let lodgeMeta={id: accomStage.get('lodge.region.id'), name: accomStage.get('lodge.name'), images:[]}

            let kodakImage = {
              originalUrl: accomStage.get('lodgeKodakImageUrl'),
              coverStyle: accomStage.get('lodgeKodakImageCoverStyle'),
            };
            lodgeMeta.images.pushObject(kodakImage)
            accomStage.lodge.metaLodgeImages.forEach((lodgeImage)=> {
              lodgeMeta.images.pushObject(getTripImageFromEntityMetaImage(lodgeImage, accomStage.get('lodge')))
            })
            loopable.pushObject(lodgeMeta)
          }
        })

        this.set('regionImagesGroups', loopable)
      })

  },

  actions: {
    goChooseImage(options) {
      this.set('choosingImage', true)
      this.set('choosingImageType', options.imageType)
    },
    setCoverStyle(coverStyle) {
      // We replace the whole object because if you assign an object to an ember property, then it doesnt trigger updates when you only change a attribute of that object. Thats because the actual ember property is not changing
      const newImage = Object.assign({}, this.get(this.tripImagePropertyName), { coverStyle })
      console.log(newImage)
      this.set(this.tripImagePropertyName, newImage);
    },

    selectImage(options) {
      if (!options.tripImage.coverStyle) {
        options.tripImage.coverStyle="center center";
      }
      if (this.tripImageType=='kodak') {
        this.set('trip.overrideKodak', options.tripImage);
      } else if (this.tripImageType=='second') {
        this.set('trip.overrideSecondKodak', options.tripImage);
      } else if (this.tripImageType=='third') {
        this.set('trip.overrideThirdKodak', options.tripImage);
      }
      this.set('choosingImage', false)
      this.set('choosingImageType', null)
    }
  }
});
