import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  htmlSafe
} from '@ember/string';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  generateMeta,
  metadataDate
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  scroll: service(),
  ui: service(),
  session: service(),
  settings: service(),
  headData: service(),
  headTagsService: service('head-tags'),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  beforeModel(transition) {
    this._super(...arguments);
  },

  afterModel(model, transition) {

    let asyncModels = RSVP.hash({
      countryInfo: model.get('countryInfo')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.headTagsService.collectHeadTags();
    });

    var breadcrumbList,
      faqs,
      organization;

    model.get('countryInfo').then(() => {

      breadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "http://www.timbuktutravel.com/countries",
            "name": "Countries",
            "image": "https://assets.timbuktutravel.com/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.timbuktutravel.com/country/" + model.get('countryFriendlyId'),
            "name": model.get('name'),
            "image": model.get('kodakOriginalUrl') + "?w=1200&h=630"
          }
        }]
      };

      if (transition.targetName=='country.index.index' && model.get('countryInfo.faqs.length') > 0) {
        faqs = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": []
        }

        model.get('countryInfo.faqs').forEach((faq) => {
          faqs['mainEntity'].push({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": faq.answer
            }
          })
        })
      }

      organization = this.get('settings.organizationSchema');

    }).finally(() => {
      this.set('headData.dataStructures', [
        htmlSafe(JSON.stringify(breadcrumbList)),
        htmlSafe(JSON.stringify(organization))
      ]);
      if (faqs) {
        this.get('headData.dataStructures').pushObject(htmlSafe(JSON.stringify(faqs)));
      }
    });

    // actual models we need to render the country page
    return this.store.query('region', {
      filters: JSON.stringify([{type: 'country', id: model.id}]),
    }).then((regions)=> {
      if (model.get('publishedRegionsCount') == 1) {
        let region = regions.get('firstObject');
        if (region.get('regionAndCountryAreTheSameThing')) {
          let targetRoute = transition.targetName.replace(/country/, 'region').replace(/\.regions/, '').replace(/index\.index/, 'index');
          return this.transitionTo(targetRoute, region);
        }
      }
      this.controllerFor('country').set('regions', regions)
      this.controllerFor('country.index').set('regions', regions)
    })
  },

  headTags() {
    let model = this.currentModel;
    let title = this.whitelabel.isForTimbuktu && model.pageTitle ? model.pageTitle : model.h1Name;
    title +=` | ${this.whitelabel.agencySeoName}`;

    let description = model.metaDescription ? model.metaDescription : model.get('asyncModels.countryInfo.overview')
    model.set('asyncModels', null);

    let robots = model.get('published') && this.whitelabel.isForTimbuktu && !this.config.buster ? 'index, follow' : 'noindex, nofollow';

    if (!this.isFastBoot) {
      document.title = title;
    }

    let meta = {
      description,
      robots,
      'og:title': title,
      'og:image': model.get('kodakOriginalUrl'),
    }
    if (model.get('published')) {
      meta['link'] = `country/${model.get('countryFriendlyId')}`
    }

    return generateMeta(meta);
  },

  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('country', model);

    let links = [{

      name: 'Overview',
      params: {
        routeName: 'country.index.index',
        routeModel: model
      }
    }]
    if (this.whitelabel.showRoutes) {
      links.pushObject({
        name: 'Trips',
        image: 'https://waybird.imgix.net/assets/images/peep/Odzala+Odzala+Discovery+Camps+-+A+beautiful+pair+of+forest+elephants.jpg',
        params: {
          routeName: 'country.routes',
          routeModel: model
        }
      })
    }
    links.push({
      name: 'Places',
      image: 'https://waybird.imgix.net/assets/images/peep/where-to-go.JPG',
      params: {
        routeName: 'country.regions',
        routeModel: model
      }
    }, {
      name: 'Experiences',
      image: 'https://waybird.imgix.net/trips/kodak_images/000/000/060/original/Nottens-Bush-Camp-Game-Drive-Open-Air-4x4-Elephant-Timbuktu-Travel.JPG',
      params: {
        routeName: 'country.experiences',
        routeModel: model
      }
    }, {
      name: 'Properties',
      image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
      params: {
        routeName: 'country.lodges',
        routeModel: model
      }
    });
    let whenToGo = Ember.Object.create({
      name: 'When to go',
      image: 'https://waybird.imgix.net/assets/images/peep/when-to-go.JPG',
      params: {
        routeName: 'country.when-to-go',
        routeModel: model
      },
      hideUntilReady: true
    });
    model.get('destinationInfo').then((destinationInfo)=> {
      if (model.get('destinationInfo.seasonalRating.length') || model.get('destinationInfo.whenToGo')) {
        whenToGo.set('hideUntilReady',false);
      }
    })
    links.pushObject(whenToGo)
    if (this.whitelabel.isForTimbuktu) {
      links.pushObject({
        name: 'Articles',
        image: 'https://waybird.imgix.net/assets/images/peep/Samburu+Saruni+Samburu.JPG',
        params: {
          routeName: 'country.articles',
          routeModel: model
        }
      })
    }

    this.get('session.currentUserPromise').then((user) => {
      if (user && (user.get('isTimbuktu'))) {

        links.pushObject({
          name: 'Tools',
          params: {
            action: 'ui.viewedEntityToolsModal',
            actionProperty: model
          }
        });
      }
      // console.log('setting navData')
      this.get('ui').setProperties({
        entityNavData: {
          entity: model,
          title: model.get('name'),
          titleLink: {
            routeName: 'country',
            routeModel: model
          },
          links: links
        }
      });
    })
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    // reseting scroll when destination changes
    if (isExiting) {
      this.set('ui.entityNavData', null);
    } else {
      // causing issues on mobile when transitioning from mapView to experience - disabling for now
      // this.get('scroll').to(0, this);
    }


  }
});
