import Component from '@ember/component';

export default Component.extend({
  tagName: 'label',
  classNames: ['tw-flex', 'tw-items-center', 'tw-cursor-pointer', 'tw-relative'],
  attributeBindings: ['for'],
  for: null,

  name: null,
  checked: null,
  onChange: null,
  disabled: false,

  actions: {
    onChange(e) {
      this.set('checked', e.target.checked);
      if (this.onChange) {
        this.onChange(e.target.checked);
      }
    }
  }
});
