function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
(function () {
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc = _slicedToArray(possibleDesc, 3),
      target = _possibleDesc[0],
      key = _possibleDesc[1],
      desc = _possibleDesc[2];
    return possibleDesc.length === 3 && _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
    ;
  }
  function computedMacroWithOptionalParams(fn) {
    return function () {
      for (var _len = arguments.length, maybeDesc = new Array(_len), _key = 0; _key < _len; _key++) {
        maybeDesc[_key] = arguments[_key];
      }
      return isFieldDescriptor(maybeDesc) ? Function.apply.call(fn(), undefined, maybeDesc) : Function.apply.call(fn, undefined, maybeDesc);
    };
  }
  var originalRequire = window.require;
  window.require = require = function patchDataDecorators(moduleName) {
    var DS;
    try {
      DS = originalRequire('ember-data').default;
    } catch (e) {
      return originalRequire(moduleName);
    }
    var _DS = DS,
      dataAttr = _DS.attr,
      dataBelongsTo = _DS.belongsTo,
      dataHasMany = _DS.hasMany;
    var attr = computedMacroWithOptionalParams(dataAttr);
    var belongsTo = computedMacroWithOptionalParams(dataBelongsTo);
    var hasMany = computedMacroWithOptionalParams(dataHasMany);
    DS.attr = attr;
    DS.belongsTo = belongsTo;
    DS.hasMany = hasMany;
    if (window.requirejs.entries['@ember-data/model/index']) {
      var newExports = Object.assign({}, window.requirejs.entries['@ember-data/model/index'].module.exports, {
        attr: attr,
        belongsTo: belongsTo,
        hasMany: hasMany
      });
      window.requirejs.entries['@ember-data/model/index'].module.exports = newExports;
    }
    window.require = require = window.requirejs;
    return window.requirejs(moduleName);
  };
})();