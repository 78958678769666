import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';

export default payFormComponent.extend({

  chargeType: 'mobipaid',

  classNames: ['pay-form'],

  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return settlementCurrency;
  },

  didInsertElement() {
    this._super(...arguments);
    this.saveChargeToServer().then((charge)=> {
      return charge.createMobipaidPaymentRequest({amount: this.get('transactionAmount'), currency: this.get('transactionCurrency')}).then((response)=> {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return
        }
        this.set('paymentDue.payment_url', response.payment_url)
        this.set('_payButtonReady', true);
      })
    })
  },
  // not great but this sets a few props as side effects of being computed


  actions: {


  }
});



