import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  trackEvent,
  generateMeta
} from 'b5b/utils';

export default Route.extend({

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('lodge');
  },

  headTags() {
    let model = this.currentModel;
    const title = `Best time to visit ${model.get('name')}, ${model.get('asyncModels.country.name')} | ${this.whitelabel.agencySeoName}`;
    let description = title;

    if (!this.isFastBoot) {
      document.title = title;
    }

    let robots = 'noindex, nofollow';
    let link  = `/region/${model.get('region.friendlyId')}/when-to-go`;

    return generateMeta({
      description,
      robots,
      link
    });
  },



  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('lodge:when-to-go:view');
    controller.set('lodge', model);
  }


});
