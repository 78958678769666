import {
  inject as service
} from '@ember/service';
import Controller, {
  inject as controller
} from '@ember/controller';
import {
  isEmpty
} from '@ember/utils';
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import {
  computed
} from 'ember-decorators/object';
import {
  alias, reads
} from 'ember-decorators/object/computed';
import RSVP from 'rsvp';
import TripSaver from 'b5b/mixins/trip-saver';
import {
  run
} from '@ember/runloop';

import {
  trackEvent,
  capitalizeFirstLetter,
  copyTextToClipboard,
  getErrorsHashFromServerResponse
} from 'b5b/utils';

export default Controller.extend(TripSaver, UseCurrentTrip, {
  router: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),
  session: service(),
  whitelabel: service(),
  messageBus: service(),

  @alias('tripService.userCanManageThisTrip') userCanManageThisTrip: null,
  @alias('tripService.tripBelongsToCurrentUser') tripBelongsToCurrentUser: null,
  @alias('tripService.tripHasConsultant') tripHasConsultant: null,

  includeCover: false,
  includeTripOverview: false,
  includeCountries: false,
  includeRegions: false,
  includeLodges: false,
  includeInclusions: false, // so meta [William @ 2018-08-22]

  includeQuoteIntro: false,
  includePreDeparture: false,
  includePartnersInFront: false,
  includePartnersInBack: false,
  includeDestinationDates: false,
  includeLodgeContacts: false,
  includeStageInfo: false,
  showBrief: false,
  showDetailed: false,

  duplicateName: null, //The value that the user will supply for dupliacte trip name
  duplicateGuestDetails: null,
  useExistingHubspotDealId: null,
  useAutoMarkup: true,
  hubspotDealIdForDuplicate: null,

  userEmail: null,

  states: ['Trip idea', 'Quote', 'Deposit paid', 'Balance due', 'Balance paid'],
  tripViewReportFrequencies: ['once', 'always', 'never', 'cold_lead'],


  @computed('trip.itinerary.state')
  currentState(state) {
    if (isEmpty(state)) {
      return 'Trip idea';
    } else if (state) {
      return capitalizeFirstLetter(state.replace(/(-|_)/g, " "));
    }
  },

  @computed('trip.shareVersionId')
  shareTripUrl(shareVersionId) {
    return window.location.origin + "/trip/" + shareVersionId;
  },



  @computed('userEmail')
  isValidUserEmail(userEmail) {
    return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail));
  },

  generateTripUrl(firstname, email, surname, subRoute) {
    var url = window.location.origin + "/trip/" + this.get('trip.friendlyId') + "/" + subRoute;
    if (email) {
      url = url + "?allocatedEmail=" + email;
    }
    if (firstname) {
      url = url + "&allocatedFirstName=" + firstname;
    }
    if (surname) {
      url = url + "&allocatedLastName=" + surname;
    }
    url = encodeURI(url);

    try {
      copyTextToClipboard(url);
      this.get('flashMessages').success('Link Copied!');
      return true;
    } catch (err) {
      this.get('flashMessages').danger('Couldn\'t copy link');
    }
  },

  addedPartners: [],

  @reads('trip.itinerary.disablePaymentRemindersUntil') readOnlyDisablePaymentRemindersUntil: null,
  @reads('tripService.hideAllPrices') readOnlyHideAllPrices: null,


  tripSavedNewManager() {
    this.set('editingPrimaryManager', false)
    this.get('messageBus').unsubscribe('trip-saved', this, this.tripSavedNewManager);
  },

  actions: {



    toggleHideAllPrices() {
      if (this.tripService.currentTrip.displayPrices=='standard') {
        this.set('tripService.currentTrip.displayPrices', "hide-all")
      } else {
        this.set('tripService.currentTrip.displayPrices', "standard")
      }

    },

    copyShareableVersionToClipboard(path) {
      let url = this.shareTripUrl;
      if (path) {
        url = url +"/"+path
      }
      copyTextToClipboard(url);
      this.get('flashMessages').success('Shareable trip link copied to clipboard');

    },

    setManagingAgency(selectResult) {
      if (selectResult.action=='search:select') {
        this.set('trip.managingAgency', selectResult.result);
        this.set('editingManagingAgency', false)

        this.store.query('user', {agency_id: this.trip.managingAgency.id, is_manager: true}).then((managers)=>{
          if (managers.length > 0) {
            this.get('messageBus').subscribe('trip-saved', this, this.tripSavedNewManager);
            this.set('trip.proposedPrimaryManager', managers.get('firstObject'))
            this.set('trip.proposedNotifyNewPrimaryManager', true)
            this.set('editingPrimaryManager', true)
            this.set('possibleManagers', managers)
          }
        })
      }
    },

    copyTripLinkToClipboard() {
      var url = `https://${this.trip.managingAgency.domain}/trip/${this.trip.friendlyId}`
      try {
        copyTextToClipboard(url);
        this.get('flashMessages').success('Trip link copied to clipboard');
      } catch (err) {
        this.get('flashMessages').danger('Couldn\'t copy link');
      }
    },

    onChangeHubspotDealIdForDuplicate(event) {
      let value = event.target.value;
      if (!Ember.isBlank(value)) {
        this.set('useExistingHubspotDealId', null)
      }
    },
    iWantToUseExistingHubspotDealId() {
      this.set('useExistingHubspotDealId', true)
      this.set('hubspotDealIdForDuplicate', null)
    },

    setDisablePaymentRemindersUntil(date) {
      date = moment(date).format('YYYY-MM-DD');
      return this.get('trip.itinerary').setDisablePaymentRemindersUntil({
        date: date
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.get('flashMessages').success('Payment reminder delay set!');
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      });
    },

    copyTripUrl(subRoute, entity) {
      let name = entity.get('name'),
        surname = entity.get('surname'),
        email = entity.get('email');
      this.generateTripUrl(name, email, surname, subRoute);
    },

    copyTripIdeaUrl(tripIdea) {
      var url = window.location.origin + "/trip/" + this.get('trip.friendlyId') + "?tripIdea=" + tripIdea.tripIdeaType;
      url = encodeURI(url);

      try {
        copyTextToClipboard(url);
        this.get('flashMessages').success('Link Copied!');
        return true;
      } catch (err) {
        this.get('flashMessages').danger('Couldn\'t copy link');
      }
    },

    // @computed('router', 'ui.shareEntityType', 'ui.shareEntity', 'isFastBoot')
    // shareUrl(router, shareEntityType, shareEntity, isFastBoot) {
    //   if (isFastBoot) {
    //     return this.get('fastboot.request.path');
    //   }

    //   return `${window.location.origin}${router.urlFor(shareEntityType, shareEntity)}`;
    // },

    updateItineraryTravelStatus(travelStatus, expectedStartDate) {
      let transformedDate = null;
      const DATE_FORMAT = 'YYYY-MM-DD';
      if (expectedStartDate) {
        transformedDate = moment(expectedStartDate).format(DATE_FORMAT);
      }
      return this.get('trip.itinerary').updateItineraryTravelStatus({
        travelStatus,
        expectedStartDate: transformedDate
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.get('ui').showGeneralMessage('Congrats!', 'Travel status succesfully updated');
      }).catch((response) => {
        let errorsHash = getErrorsHashFromServerResponse(response);
        let errorMessage = 'Problem updating travel status:<br><br>'

        Object.keys(errorsHash).forEach((errorKey) => {
          errorMessage += errorKey + ': ' + errorsHash[errorKey] + '<br>';
        })
        this.get('ui').showGeneralMessage('Oops', errorMessage);
      });
    },


    confirmFlights() {
      return this.get('trip.itinerary').setFlightsConfirmed().then((response) => {
        this.get('store').pushPayload(response);
      });
    },

    confirmItinerary() {
      return this.get('trip.itinerary').setItineraryConfirmed().then((response) => {
        this.get('store').pushPayload(response);
      });
    },

    toggleGiftListOwner(userTrip){

      return this.get('trip').toggleGiftListOwner({
        userTripId: userTrip.id
      }).then((response) => {
        this.flashMessages.success('Gift ownership changed')
        this.get('store').pushPayload(response);
      }, (response) => {
        let errorsHash = getErrorsHashFromServerResponse(response);
        let errorMessage = 'Problem with changing gift ownership'
        errorMessage += '.<br><br>';

        Object.keys(errorsHash).forEach((errorKey) => {
          errorMessage += errorsHash[errorKey] + '<br>';
        })
        this.get('ui').showGeneralMessage('Oops!', errorMessage);
      });
    },

    inviteUser() {
      return this.get('trip').inviteUser({
        email: this.get('userEmail'),
        name: this.get('userName'),
        surname: this.get('userSurname'),
        forceInviteEmail: this.get('forceInviteEmail'),
        forceGiftListOwner: this.get('forceGiftListOwner')
      }).then((response) => {
        this.flashMessages.success(this.get('userEmail') + ' added')
        this.get('store').pushPayload(response);
        this.setProperties({
          userEmail: null,
          userName: null,
          userSurname: null
        });
        // Saving here can have knock-on effects on the itinerary/trip so we just refresh the draft trip to reflect any changes
      }, (response) => {
        let errorsHash = getErrorsHashFromServerResponse(response);
        let errorMessage = 'Problem with inviting this user'
        errorMessage += '.<br><br>';

        Object.keys(errorsHash).forEach((errorKey) => {
          errorMessage += errorsHash[errorKey] + '<br>';
        })
        this.get('ui').showGeneralMessage('Oops!', errorMessage);
      });
    },

    deleteUserInvite(invite) {
      return this.get('trip').deleteUserInvite({
        invite_id: invite.get('id')
      }).then((response) => {
        this.get('flashMessages').success('Invite deleted!');
        this.get('store').pushPayload(response);
      });
    },

    deleteUserTrip(userTrip) {
      return this.get('trip').deleteUserInvite({
        user_trip_id: userTrip.get('id')
      }).then((response) => {
        this.get('store').pushPayload(response);
        this.get('flashMessages').success('User removed!');
        this.send('refreshRoute');
      });
    },

    setTripConsultant() {
      return this.get('trip').setTripConsultant().then((response) => {
        // Annoying.. doing this because it is creating a bug once the user has added themselves to the trip
        location.reload();
        this.get('store').pushPayload(response);
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      });
    },

    addSelfToTrip() {
      return this.get('trip').addSelfToTrip().then((response) => {
        this.get('store').pushPayload(response);
        this.send('refreshRoute');
        this.get('flashMessages').success('You\'ve been added to the trip!');
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      });
    },

    sendUsefulInfoEmail() {
      this.get('trip').sendUsefulInfoEmail();
    },


    duplicateTrip(name) {
      trackEvent('trip-tools:duplicate');
      let clearStateButton = false;
      if (this.duplicateGuestDetails == null || this.duplicateGuestDetails == undefined) {
        this.get('ui').showGeneralMessage('Oops', 'Please choose whether you would like guest details to be duplicated or not');
        clearStateButton = true
      }
      let hubspotDealId = this.hubspotDealIdForDuplicate;
      if (Ember.isBlank(this.hubspotDealIdForDuplicate) && this.useExistingHubspotDealId) {
        hubspotDealId = this.trip.hubspotDealId;
      }

      if (!hubspotDealId && this.session.currentUser.consultant) {
        this.get('ui').showGeneralMessage('Oops', 'Please specify hubspot deal id');
        clearStateButton = true
      }

      if (clearStateButton) {
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      return this.get('trip').duplicateTrip({
        name: name,
        duplicateGuestDetails: this.duplicateGuestDetails,
        useAutoMarkup: this.useAutoMarkup,
        hubspotDealId: hubspotDealId
      }).then((response) => {
        this.set('duplicateGuestDetails', null)
        this.set('useExistingHubspotDealId', null)
        this.set('hubspotDealIdForDuplicate', null)
        this.set('duplicateName', null)
        this.set('useAutoMarkup', true)

        this.get('store').pushPayload(response);
        this.transitionToRoute('trip.index.edit.itinerary', response.trip.friendly_id)
        this.get('ui').showGeneralMessage('Duplicate created', 'You are now editing the duplicate. Please note that guest users need to be invited to this duplicate.');
      }).catch((e) =>{
        let error = getErrorsHashFromServerResponse(e)
        this.get('ui').showGeneralMessage('Oops', 'We had a problem duplicating this trip');
      });
    },

    convertTripToRoute() {
      return this.get('trip').convertTripToRoute().then((response) => {
        this.get('store').pushPayload(response);
        this.set('showRouteConversionConfirmation', false);
        this.transitionToRoute('trip.index.editor', response.trip.friendly_id)
        this.get('ui').showGeneralMessage('Trip converted', 'The user trip has been converted to a route. All users have been removed, and trip ideas with default properties have been allocated. It is not currenlty published.');
        run.later(() => {
          location.reload();
        }, 1000);
      }).catch((e) =>{
        let error = getErrorsHashFromServerResponse(e)
        this.get('ui').showGeneralMessage('Failure', error.convert[0]);
      });
    },

    sendLatestGuestDetails() {
      return this.get('trip.itinerary').sendLatestGuestDetails().then(() => {
        this.get('flashMessages').success('Latest details sent!');
      }).catch(() => {
        this.get('flashMessages').danger('Oh dear! Something went wrong!', {
          timeout: 4000,
          sticky: false
        });
      })
    },

    createInstantQuote() {
      let originalPackage = this.get('trip');
      let userEmail = this.userEmail;
      let userName = this.userName;
      let userSurname = this.userSurname;
      let forceInvite = this.forceInviteEmail;
      let hubspotDealId = this.duplicateHubspotDealId;

      let sourceCountry = this.sourceCountry;
      let settlementCurrency = this.settlementCurrency;

      if (!sourceCountry || !settlementCurrency || !originalPackage.itinerary.startDate || (this.whitelabel.isForTimbuktu && !hubspotDealId)) {
        this.get('flashMessages').danger('Source country, settlement currency, start date are all required');
        return new RSVP.Promise(function(resolve) {
          resolve();
        });
      }

      // we need to use duplicate instead of just saving current trip so that we get inclusions / excluisons from server side
      return this.get('trip').duplicateTrip({
        name: this.duplicateName,
        hubspotDealId: hubspotDealId
      }).then((response) => {
        this.get('store').pushPayload(response);
        return this.transitionToRoute('trip.index.edit.itinerary', response.trip.friendly_id)
      }).then(()=>{

        let quoteTrip = this.tripService.currentTrip;
        this.tripService.ensureTripHasQuote(quoteTrip);

        quoteTrip.set('itinerary.startDate', originalPackage.itinerary.startDate)
        quoteTrip.set('itinerary.quote.sourceCountry', sourceCountry)
        quoteTrip.set('itinerary.quote.settlementCurrency', settlementCurrency)

        return this.saveTrip(quoteTrip, {
          makeCopyOfTrip: false,
          showSuccessFlash: false
        });
      }).then((savedTrip)=> {
        return savedTrip.convertToQuoteUsingSpecialOffer()
      }).then((response)=> {
        this.get('store').pushPayload(response);
        let quoteTrip = this.tripService.currentTrip;
        return quoteTrip;
      }).then((response) => {
        return this.tripService.refreshCurrentTripFromServer()
      }).then(()=> {
        this.setProperties({
          userEmail: null,
          userName: null,
          userSurname: null,
          duplicateHubspotDealId: null
        });
        this.set('showInstantQuoteModal', false)
        this.get('ui').showGeneralMessage('Quote created', 'You are now editing the quote!');
        // Saving here can have knock-on effects on the itinerary/trip so we just refresh the draft trip to reflect any changes

      }, (response) => {
        let errorsHash = getErrorsHashFromServerResponse(response);
        let errorMessage = 'Problem with converting to quote'
        errorMessage += '.<br><br>';

        Object.keys(errorsHash).forEach((errorKey) => {
          errorMessage += errorsHash[errorKey] + '<br>';
        })
        this.get('ui').showGeneralMessage('Oops!', errorMessage);
      })
    }
  }
});
