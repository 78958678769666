import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import sideNav from 'b5b/mixins/side-nav';
import {
  setupTripEntityNav
} from 'b5b/utils';
import {
  alias
} from 'ember-decorators/object/computed';
import tripSubRoute from 'b5b/mixins/trip-sub-route';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

export default Route.extend(tripSubRoute, sideNav, {

  ui: service(),
  scroll: service(),
  session: service(),
  templateManager: service(),
  whitelabel: service(),
  tripService: service('trip'),
  @alias('tripService.currentTrip') trip: null,

  beforeModel(transition) {
    this._super(...arguments);

    return this.get('session.currentUserPromise').then(() => {
      if (!(this.get('session.currentUser.isManager') || this.get('session.currentUser.isTimbuktu') || this.whitelabel.isOnboardingAgency)) {
        transition.abort();
      }
    })
  },

  setupController() {
    this._super(...arguments);

    var trip = this.get('trip');

    this.tripService.ensureAllPartnersLoaded();

    console.log('setting up trip.index.edit route');

    // navs setup
    setupTripEntityNav(trip, this.get('tripService'), this.get('ui'), this.get('session'));

    let userManagingThisTrip = trip.get('userTrip') && this.get('tripService.userCanManageThisTrip');
    let editingPublishedContent = trip.contentManagedTrip && this.get('session.currentUser.isContentTeam');
    let showManagerSideTabs = userManagingThisTrip || this.whitelabel.isOnboardingAgency
    if (this.whitelabel.showManagerTools || editingPublishedContent) {
      this.get('ui').setProperties({
        displayOnSideBar: 'hide-after-tablet',
        sideNavOptions: [{
          route: 'trip.index.edit.itinerary',
          title: 'Itinerary',
          item: trip
        }]
      });
    }

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.guests',
        title: 'Guests',
        item: trip
      }, {
        route: 'trip.index.edit.pricing.line-items',
        title: 'Pricing',
        routeModel: trip,
        subNavOptions: [
          {
            route: 'trip.index.edit.pricing.line-items',
            title: 'Line Items',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.discounts',
            title: 'Discounts',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.payments',
            title: 'Payments',
            routeModel: trip
          },
          {
            route: 'trip.index.edit.pricing.summary',
            title: 'Summary',
            routeModel: trip
          },
        ]
      }]))
    }

    if (showManagerSideTabs || editingPublishedContent) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.images',
        title: 'Images',
        routeModel: trip
      }]))
    }

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.quote-details',
        title: 'Details',
        item: trip
      }]))
    }

    if (showManagerSideTabs || editingPublishedContent) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.video',
        title: 'Video',
        routeModel: trip
      }]))
    }

    if (trip.itinerary.isInstantBookable) {

      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
        route: 'trip.index.edit.rates',
        title: 'Rates',
        item: trip,
        showMainNav: false
      }]))
    }

    this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
      route: 'trip.index.edit.brochure',
      title: 'Brochure',
      routeModel: trip
    }]))

    if (showManagerSideTabs) {
      this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([ {
        route: 'trip.index.edit.analytics',
        title: 'Analytics',
        routeModel: trip
      }]))
    }

    this.set('ui.sideNavOptions', this.ui.sideNavOptions.concat([{
      route: 'trip.index.edit.tools',
      title: 'Tools',
      item: trip
    }]))

  },

  resetController(isExiting) {
    this._super(...arguments);

    this.get('ui').setProperties({
      sideNavOptions: null,
      showMainNav: true
    });


    if (isExiting) {
      this.set('ui.entityNavData', null);
      this.set('templateManager.tripToolsShowTemplateType', null)
    }
  },

  actions: {

    closeRoute() {
      this.transitionTo('trip', this.get('trip'));
    },

    refreshRoute() {
      console.log('refresh route')
      this.setupController();
    }


    // didTransition() {
    //   if ($('.main-pane')) {
    //     this.get('scroll').to(0, this, null, null, $('.main-pane'));
    //   }
    //   return true;
    // },

  }

});
