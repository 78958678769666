import { helper as buildHelper } from '@ember/component/helper';

/*
  Note: doesnt update with array - best for loops, also look at array-contains
*/

export function parseIntHelper([value]) {
  return parseInt(value);
}

export default buildHelper(parseIntHelper);
