import Mixin from '@ember/object/mixin';

import {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';

export default Mixin.create({

  scroll: service(),

  @alias('settings.searchMode') searchMode: false,

  queryParams: [{
    swlat: {},
    swlng: {},
    nelat: {},
    nelng: {},
    zoom: {
      as: 'z'
    }
  }],

  swlat: undefined,
  swlng: undefined,
  nelat: null,
  nelng: null,

  mapSearchEnabled: true,

  zoom: 3,

  entities: null, // this needs to be aliased to lodges or regions etc

  @computed('center', 'isFastBoot')
  readyToShowMap(center, isFastBoot) {
    if (isFastBoot) {
      return;
    }

    return center;
  },

  entitiesLoaded() {
    // this.get('scroll').to(0, this, 0);
    // The only time there won't be a center is when the entities are loaded the first time after route activates
    // We need to set the center so that the map can initiate and then wait for onLoad before mapInstance is ready
    if (!this.get('center')) {

      if (this.get('nelat') && this.get('nelng') && this.get('swlat') && this.get('swlng')) {
        // Set the center if we know the bounding co-ords of the map. The entities should fit within that box

        let sw = L.latLng(this.get('swlat'), this.get('swlng')),
          ne = L.latLng(this.get('nelat'), this.get('nelng'));

        // console.log(`setting center base don bounding box ${this.get('searchMode')} ${this.get('mapInstance')}`)
        this.set('center', L.latLngBounds(sw, ne).getCenter());
      } else {
        // Without bounding co-ords, we need set the center based on entities tht have been retrieved

        var entityBounds = this.setEntityBounds();
        if (entityBounds) {
          this.set('center', L.latLngBounds(entityBounds).getCenter());

        }
      }
    } else {
      // This deals with case where entities have loaded but there is already a center so the mapInstance will be available
      // If the destination change, fit the bouns of the map to new entities
      // If the user was dragging then clear search mode
      // if the seatrch`Mode is undefined, then filters have been changed

      if (this.get('searchMode') === 'destinationChange') {
        run.next(() => {
          this.setEntityBounds();
          this.fitMapToEntityBounds('destinationChange')
        });
      } else if (this.get('searchMode') === 'dragging') {
        // If the user is dragging or zooming without a destination set, then leave the map as is and clear searchMode. Our job is done
        this.set('searchMode', undefined);
      } else if (this.get('searchMode') === 'loading') {
        // Do nothing.
      }
    }
  },

  fitMapToEntityBounds(searchMode) {
    // We have to explicit about the search mode that we are using when fitting bounds, to avoid side effects
    this.set('searchMode', searchMode)
    let entityBounds = this.get('entityBounds');
    // If the search query yielded no results (ie: destination + filters) then we wouldn't be able to calculate entityBounds
    if (entityBounds && this.get('mapInstance')) {
      // console.log('will Fit Bounds')
      this.get('mapInstance').fitBounds(entityBounds);
      // We need to set the zoom so that if we start dragging then zoom is at the correct value
      this.set('zoom', this.get('mapInstance')._zoom);
      // console.log('did Fit Bound')
    }
    // search mode is no longer needed once bounds have been set, but we wait a second for possible zooms to happen
    run.later(() => {
      // console.log('clearing searchMode')
      this.set('searchMode', undefined);
    }, 1000);
  },

  setEntityBounds() {
    let entities = this.get('entities');

    if (entities && entities.get('length') > 0) {
      var bounds = entities.map((entity) => {
        // THis check is important as sometimes there is bad lat/long data and it kills the browser. Can't even close tab
        if (entity.get('isValidMapLocation')) {
          return [entity.get('latitudeWithFallback'), entity.get('longitudeWithFallback')];
        }
      });
      var bounds = entities.reduce(function(result, entity) {
        if (entity && entity.get('latitudeWithFallback') && entity.get('longitudeWithFallback')) {
          let lat = entity.get('latitudeWithFallback'),
          lng = entity.get('longitudeWithFallback');
          result.push([lat, lng]);
        }
        return result;
      }, []);
      if (bounds.get('length') == 1) {
        // Its crazy but true. Ian 30/12/2016. This is needed because otherwise the bounds calculation blows up on initial page load if there is only a single entity in the result set.
        // The bounds seems fine if you change fitlers so there is a single entity but refresh and everythign falls over!
        var lat = parseFloat(bounds[0][0]);
        var lng = parseFloat(bounds[0][1]);
        bounds.pushObject([(lat - 0.25).toString(), (lng - 0.25).toString()])
        bounds.pushObject([(lat + 0.25).toString(), (lng + 0.25).toString()])
      }

      this.set('entityBounds', bounds);
      return bounds;
    }

  },

  mapPositionChanged(e) {
    this._super(...arguments);
    // console.log(`mapPositionChanged`)
    if (!this.get('mapInstance')) {
      return;
    }

    // console.log('setting the coords because there is a map instance')
    let bounds = e.target.getBounds();

    // if (center.lat == this.get('lat') && center.lng == this.get('lng')) {
    //   return;
    // }

    this.setProperties({
      swlat: (bounds._southWest.lat).toString(),
      swlng: (bounds._southWest.lng).toString(),
      nelat: (bounds._northEast.lat).toString(),
      nelng: (bounds._northEast.lng).toString(),
      zoom: e.target.getZoom(),
      page: 1
    });

    this.onMapPositionChanged();

  },

  mapInstance: null,

  resetLatLng() {
    this.setProperties({
      swlat: undefined,
      swlng: undefined,
      nelat: null,
      nelng: null,
      zoom: 3,
      page: 1
    })
  },

  actions: {

    onLoad(e) {
      // if there are already bounds set and there is no bounding box co-ords then the map instance needs to fit thos bounds as soon as its ready
      // console.log('onLoad')
      this.set('mapInstance', e.target);
      if (!this.get('nelat') && !this.get('nelng') && !this.get('swlat') && !this.get('swlng') && this.get('entityBounds')) {
        this.fitMapToEntityBounds('loading');
      } else {
        this.set('searchMode', undefined);
      }
    },

    // Not sure if this might cause issues, since it teardown the map everytime
    // It does however fix another issue where it tries to set positions on a non existing map
    onMapDestroy() {
      // clear to avoid it trying to set bounds when not in dom
      this.set('mapInstance', null);
    },

    onDragStart() {
      if (this.get('searchMode') === 'destinationChange' || this.get('searchMode') === 'loading' || !this.get('mapSearchEnabled')) {
        return;
      }

      if (!this.get('mapInstance')) {
        return;
      }

      this.set('searchMode', 'dragging');
    },

    onDragEnd(e) {
      // console.log('onDragEnd')
      if (this.get('searchMode') === 'destinationChange' || this.get('searchMode') === 'loading' || !this.get('mapSearchEnabled')) {

        return;
      }

      this.mapPositionChanged(e);
    },

    onZoomStart() {
      //zoom start is fired when the user selectes to zoom in`
      //zoom start is fired during map startup before onLoad event (ie. there is no mapInstance yet)
      // zoomm start is fired during mapInstance.fitBounsd IF the new bounds results in zoom being changed
      // console.log('zoom start')

      this.set('_zoomStartAllowed', false);

      if (!this.get('mapInstance') || this.get('searchMode') === 'loading') {
        return;
      }

      this.set('_zoomStartAllowed', true);

    },

    onZoomEnd(e) {
      //zoomEnd is fired when the user selectes to zoom out`
      //zoomEnd is fired during map startup before onLoad event (ie. there is no mapInstance yet)
      // zoommEnd is fired during mapInstance.fitBounsd IF the new bounds results in zoom being changed
      // console.log(`zoom end ${this.get('searchMode')}`)
      //

      if (this.get('searchMode') === 'destinationChange' || this.get('searchMode') === 'loading' || !this.get('mapSearchEnabled') || !this.get('_zoomStartAllowed')) {
        return;
      }

      this.set('zoom', e.target.getZoom());
      this.mapPositionChanged(e);
    }
  }


});
