import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  generateMeta,
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('country');
  },

  headTags() {

    let model = this.currentModel;
    let title = model.get('name');
    if (this.whitelabel.isForTimbuktu) {
      title = `Best Time To`
      if (model.isSafari) {
        title += ` Safari in`;
      } else {
        title += ` Visit`;
      }
      title += ` ${model.name}`;
    }
    title +=` | ${this.whitelabel.agencySeoName}`;

    if (!this.isFastBoot) {
      document.title = title;
    }
    var description = `Discover the best time to ${model.isSafari ? 'safari in' : 'visit'} ${model.name} with our fully customizable trip ideas and our travel experts to give you all the help and advice you need.`;
    let robots = !(model.get('_internalModel.modelName')=='lodge') && model.get('published') && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';
    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;
    return generateMeta({
      description,
      'og:title': title,
      link,
      robots
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('country:when-to-go:view');

    controller.set('country', model);
  }


});
