define("ember-social/templates/components/social-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZCpB24En",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"facebookLike\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"facebook-like\",null,[[\"url\",\"fb-layout\"],[[23,[\"url\"]],\"button\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"facebookShare\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"facebook-share\",null,[[\"url\"],[[23,[\"url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"twitterShare\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"twitter-share\",null,[[\"url\"],[[23,[\"url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"linkedinShare\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"linkedin-share\",null,[[\"url\"],[[23,[\"url\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"emailShare\"]]],null,{\"statements\":[[4,\"email-share\",null,[[\"subject\",\"url\",\"body\"],[[23,[\"emailSubject\"]],[23,[\"url\"]],[23,[\"emailBody\"]]]],{\"statements\":[[0,\"      \"],[1,[21,\"emailLinkText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-social/templates/components/social-widget.hbs"
    }
  });
});