import DS from 'ember-data';

export default DS.Model.extend({
  email: DS.attr(),
  firstName: DS.attr(),
  surname: DS.attr(),
  message: DS.attr(),
  phoneNumber: DS.attr(),
  bookedFlights: DS.attr(),
  budget: DS.attr(),
  startDate: DS.attr('b5bdate'),
  startMonth: DS.attr(),
  startYear: DS.attr(),
  numAdults: DS.attr(),
  numChildren: DS.attr(),
  numInfants: DS.attr(),
  geoCode: DS.attr(),
  enquiryType: DS.attr(),
  moreInfo: DS.attr(),
  // Not used at the moment
  // phoneCountryCode: DS.attr(),
  destination: DS.attr(),

  itinerary: DS.belongsTo('itinerary', {
    async: false
  }),
  user: DS.belongsTo('user', {
    inverse: null
  }),
  wizardSubmission: DS.belongsTo('wizardSubmission', {
    inverse: null
  })

});
