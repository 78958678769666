import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let blockItems = namedArgs.blockItems;
    let printableVersion = namedArgs.printableVersion;
    if (printableVersion)  {
      return blockItems.reduce(function(result, blockItem) {
        if (!blockItem.isDayByDayBlockItem) {
          result.push(blockItem);
        }
        return result;
      }, []);
    } else {
      return blockItems;
    }
  }

});
