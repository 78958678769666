import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  generateMeta,
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  store: service(),
  tripService: service('trip'),
  @alias('tripService.hasBackButton') hasBackButton: null,

  scroll: service(),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('lodge');
  },

  headTags() {
    let model = this.currentModel;
    let title = `${model.get('name')}`
    if (model.get('asyncModels.country.name')) {
      title = `${title}, ${model.get('asyncModels.country.name')}`
    }
    title = `${title} | ${this.whitelabel.agencySeoName}`;

    var description = model.get('asyncModels.lodgeInfo.overview');
    const imageUrl = model.get('kodakOriginalUrl');

    if (!this.get('isFastBoot')) {
      document.title = title;
    }

    let robots = model.get('published') && this.whitelabel.isForTimbuktu && !model.get('isCustom') && !model.get('isJustImage') && !this.config.buster ? 'index, follow' : 'noindex, nofollow';

    model.set('asyncModels', null);

    let meta = {
      description,
      robots,
      'og:title': title,
      'og:image': imageUrl
    }
    if (model.get('published')) {
      meta['link'] = `lodge/${model.get('lodgeFriendlyId')}`
    }

    return generateMeta(meta);
  },

  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('lodge', model);
  }

});
