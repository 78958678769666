define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "whSlt+vZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[22,0,[]],[23,[\"flash\"]],[27,\"action\",[[22,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,[\"flash\",\"message\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"showProgressBar\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"alert-progress\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"alert-progressBar\"],[12,\"style\",[21,\"progressDuration\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
    }
  });
});