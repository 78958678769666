import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';

export default payFormComponent.extend({

  chargeType: 'stripe',

  classNames: ['pay-form'],

  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return STRIPE_CURRENCIES.includes(settlementCurrency) ? settlementCurrency : 'usd';
  },

  init() {
    this._super(...arguments);
    this.set('stripePaymentElementId', `stripePaymentElement${new Date().getTime()}`);
  },

  didInsertElement() {
    this._super(...arguments);

    // Note that currently the paymentsDue collection is re-created when the currency is changed.
    // IF this implementation changes at all, then this will intent will need to be re-created whenever the users preferrred currency changes
    this.saveChargeToServer().then((charge)=> {
      return charge.createStripePaymentIntent({amount: this.get('transactionAmount'), currency: this.get('transactionCurrency')}).then((response)=> {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return
        }
        let stripeOptions = {};
        if (response.stripe.stripe_connect_account) {
          stripeOptions['stripeAccount']=response.stripe.stripe_connect_account;
        }

        this.set('stripe', Stripe(response.stripe.publishable_key, stripeOptions))
        this.set('paymentIntentResponse', response)
        this.set('paymentDue.intentClientSecret', response.stripe.client_secret);

        const appearance = {
          theme: 'stripe',

          variables: {
            colorPrimary: '#474747',
            // colorBackground: '#ffffff',
            // colorText: '#30313d',
            // colorDanger: '#df1b41',
            fontFamily: "\"Gotham SSm A\", \"Gotham SSm B\", Verdana, sans-serif",
            // spacingUnit: '2px',
            // borderRadius: '4px',
          }
        };

        const options = {
          clientSecret: this.paymentDue.intentClientSecret,
          // Fully customizable with appearance API.
          appearance,
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        this.set('stripeElements', this.stripe.elements(options));

        // Create and mount the Payment Element
        const paymentElement = this.stripeElements.create('payment');
        paymentElement.mount(`#${this.stripePaymentElementId}`);

        this.set('_payButtonReady', true);
      })
    })
  },
  // not great but this sets a few props as side effects of being computed


  actions: {

    async submitPayment() {

      if (window.timbuktu.xeDate) {
        this.get('ui').showGeneralMessage('Oops!', 'You are currently using historical exchange rates. Please reload the page with the latest exchange rates before making payment. The url should not include xeDate=xx-xx-xxxx in it.');
        return;
      }

      // check if the terms and conditions have been accepted
      if (!this.get('trip.itinerary.termsAcceptedDateTime') && !this.get('session.currentUser.isManager') && !this.get('trip.isGiftList')) {
        this.get('ui').showGeneralMessage('Oops!', `Please accept ${capitalizeFirstLetter(this.whitelabel.agency.name)}'s terms and conditions before continuing`);
        return;
      }

      trackEvent('pay-button:click');
      this.ui.set('showProcessingModal', true);

      const {error} = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: this.stripeElements,
        confirmParams: {
          return_url: this.paymentIntentResponse.stripe.return_url,
        },
      });
      this.ui.set('showProcessingModal', false);

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.ui.showGeneralMessage('Oops', 'We were unable to process your payment due to the following reason:<br><br> <b>'+error.message+'</b>');
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  }
});



