import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  htmlSafe
} from '@ember/string';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  generateMeta,
  metadataDate
} from 'b5b/utils';

export default Route.extend(FriendlyIdRoute, {

  ui: service(),
  scroll: service(),
  settings: service(),
  session: service(),
  headData: service(),
  headTagsService: service('head-tags'),

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  // afterModel(region) {
  //   return region.get('regionInfo');
  // },

  headTags() {
    let model = this.currentModel;
    let title = model.h1Name;
    title +=` | ${this.whitelabel.agencySeoName}`;

    let description = model.get('asyncModels.regionInfo.overview')
    model.set('asyncModels', null);

    let robots = model.get('published') && this.whitelabel.isForTimbuktu && !this.config.buster ? 'index, follow' : 'noindex, nofollow';

    if (!this.isFastBoot) {
      document.title = title;
    }

    let link  = this.router.currentURL.indexOf('?') > 0 ? this.router.currentURL.substring(0, this.router.currentURL.indexOf('?')) : this.router.currentURL;

    let meta = {
      description,
      robots,
      'og:title': title,
      'og:image': model.get('kodakOriginalUrl'),
    }
    if (model.get('published')) {
      meta['link'] = link
    }

    return generateMeta(meta);
  },

  afterModel(model) {

    var webPage,
      breadcrumbList,
      organization;

    let asyncModels = RSVP.hash({
      regionInfo: model.get('regionInfo')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.headTagsService.collectHeadTags();
    });


    model.get('regionInfo').then(() => {
      webPage = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "headline": "Design your " + model.get('seoName') + " online",
        "image": {
          "@type": "ImageObject",
          "url": model.get('kodakOriginalUrl') + "?w=1400&h=960",
          "height": 960,
          "width": 1400
        },
        "datePublished": metadataDate(model.get('createdAt')),
        "dateModified": metadataDate(model.get('updatedAt')),
        "publisher": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "author": {
          "@type": "Organization",
          "name": "Timbuktu",
          "url": "https://www.timbuktutravel.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://waybird.imgix.net/general/TimbuktuLogoAmp.jpg",
            "width": 600,
            "height": 60
          }
        },
        "description": model.get('regionInfo.overview'),
        "mainEntity": {
          "@type": "State",
          "name": model.get('name'),
          "address": model.get('name') + ', ' + model.get('country.name'),
          "geo": {
            "@type": "GeoCoordinates",
            "address": model.get('name') + ', ' + model.get('country.name'),
            "addressCountry": model.get('country.name'),
            "latitude": model.get('latitude'),
            "longitude": model.get('longitude')
          }
        }
      };

      breadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": "http://www.timbuktutravel.com/country/" + model.get('country.friendlyId') + "/regions",
            "name": "Regions",
            "image": "https://assets.timbuktutravel.com/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": "https://www.timbuktutravel.com/region/" + model.get('friendlyId'),
            "name": model.get('name'),
            "image": model.get('kodakOriginalUrl') + "?w=1200&h=630"
          }
        }]
      };

      organization = this.get('settings.organizationSchema');

    }).finally(() => {
      this.set('headData.dataStructures', [
        htmlSafe(JSON.stringify(webPage)),
        htmlSafe(JSON.stringify(breadcrumbList)),
        htmlSafe(JSON.stringify(organization))
      ]);
    });

  },


  setupController: function(controller, model) {
    this._super(...arguments);
    controller.set('region', model);

    let links = [{
      name: 'Overview',
      params: {
        routeName: 'region.index',
        routeModel: model
      }
    }]
    if (this.whitelabel.showRoutes) {
      links.pushObject({
        name: 'Trips',
        image: 'https://waybird.imgix.net/assets/images/peep/Odzala+Odzala+Discovery+Camps+-+A+beautiful+pair+of+forest+elephants.jpg',
        params: {
          routeName: 'region.routes',
          routeModel: model
        }
      })
    }

    if (model.isGrouping) {
      links.pushObject({
        name: 'Places',
        image: 'https://waybird.imgix.net/assets/images/peep/where-to-go.JPG',
        params: {
          routeName: 'region.regions',
          routeModel: model
        }
      })
    }

    links = links.concat([{
      name: 'Experiences',
      image: 'https://waybird.imgix.net/trips/kodak_images/000/000/060/original/Nottens-Bush-Camp-Game-Drive-Open-Air-4x4-Elephant-Timbuktu-Travel.JPG',
      params: {
        routeName: 'region.experiences',
        routeModel: model
      }
    }, {
      name: 'Properties',
      image: 'https://waybird.imgix.net/assets/images/peep/lodges.JPG',
      params: {
        routeName: 'region.lodges',
        routeModel: model
      }
    }]);
    let whenToGo = Ember.Object.create({
      name: 'When to go',
      image: 'https://waybird.imgix.net/assets/images/peep/when-to-go.JPG',
      params: {
        routeName: 'region.when-to-go',
        routeModel: model
      },
      hideUntilReady: true
    });
    model.get('destinationInfo').then((destinationInfo)=> {
      if (model.get('destinationInfo.seasonalRating.length') || model.get('destinationInfo.whenToGo')) {
        whenToGo.set('hideUntilReady',false);
      }
    })
    links.pushObject(whenToGo)

    if (this.whitelabel.isForTimbuktu) {
      links.pushObject({
        name: 'Articles',
        image: 'https://waybird.imgix.net/assets/images/peep/Samburu+Saruni+Samburu.JPG',
        params: {
          routeName: 'region.articles',
          routeModel: model
        }
      })
    }

    this.get('session.currentUserPromise').then((user) => {
      if (user && (user.get('isTimbuktu'))) {
        links.pushObject({
          name: 'Tools',
          params: {
            action: 'ui.viewedEntityToolsModal',
            actionProperty: model
          }
        });
      }

      this.get('ui').setProperties({
        entityNavData: {
          entity: model,
          title: model.get('displayName'),
          titleLink: {
            routeName: 'region',
            routeModel: model
          },
          links: links
        }
      });
    })

  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (!isExiting) {
      // causing issues on mobile when transitioning from mapView to experience - disabling for now
      // this.get('scroll').to(0, this);
    } else {
      this.set('ui.entityNavData', null);
    }
  }

});
