import {
  computed
} from 'ember-decorators/object';
import {
  equal,
  or
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import Self from 'b5b/models/schedule';

export const SCHEDULE_TYPE_CUSTOM = 'custom';
export const SCHEDULE_TYPE_TEMPLATE = 'template';
export const SCHEDULE_TYPES = [SCHEDULE_TYPE_CUSTOM, SCHEDULE_TYPE_TEMPLATE];


export default DS.Model.extend({
  internalName: DS.attr(),
  internalOverview: DS.attr(),
  scheduleType: DS.attr(),
  polished: DS.attr(),
  isMaster: DS.attr(),
  blocks: DS.hasMany('block', {
    async: false
  }),
  region: DS.belongsTo('region', {inverse: null, async: false}),
  partner: DS.belongsTo('partner', {inverse: null, async: false}),
  lodge: DS.belongsTo('lodge', {inverse: null, async: false}),
  originalUser: DS.belongsTo('user', {inverse: null, async: false}),
  scheduleHeading: DS.attr(),

  stageForCustomSchedule: DS.belongsTo('stage', { async: false, inverse: 'customSchedule' }),
  stageForTemplateSchedule: DS.belongsTo('stage', { async: false, inverse: 'templateSchedule' }),

  @or('stageForCustomSchedule', 'stageForTemplateSchedule') stage: null,

  @equal('scheduleType', SCHEDULE_TYPE_TEMPLATE) isTemplateSchedule: false,
  @equal('scheduleType', SCHEDULE_TYPE_CUSTOM) isCustomSchedule: false,

  @computed('blocks.@each.previewOverview')
  previewOverview(blocks) {
    let previewOverview = null;
    blocks.forEach((block)=> {
      if (block.previewOverview && !previewOverview) {
        previewOverview = block.previewOverview;
      }
    })
    if (this.get('blocks.length')>=2) {
      //First block that isnt intro block
      let nonIntroBlock = this.get('blocks').objectAt(1);
      if (nonIntroBlock.isDay && nonIntroBlock.get('blockItems.length') >=1) {
        let firstDayFirstBlockItem = nonIntroBlock.get('blockItems.firstObject');
        if (!firstDayFirstBlockItem.get('heading') && previewOverview == firstDayFirstBlockItem.get('overview')) {
          //Dont double up the preview overview if it being shown under a day on overview page. If we need this elsewhere, we will have to change this into helper
          return null;
        }
      }
    }

    return previewOverview;
  },

  @computed('blocks.@each.allExperiences.[]')
  allExperiences(blocks) {
    let experiences = []
    blocks.mapBy('allExperiences').forEach((allExperiencesArray)=> {
      experiences=experiences.concat(allExperiencesArray)
    })
    return experiences;
  },

  @computed('blocks.@each.allBlockItems')
  allBlockItems(blocks) {
    let allBlockItems = []
    blocks.mapBy('allBlockItems').forEach((allBlockItemsArray)=> {
      allBlockItems=allBlockItems.concat(allBlockItemsArray)
    })
    return allBlockItems;
  },

  @computed('blocks.@each.nonDayByDayBlockItems')
  blocksWithNonDayByDayBlockItems(blocks) {
    return blocks.reduce(function(result, block) {
      if (block.nonDayByDayBlockItems.length > 0) {
        result.push(block);
      }
      return result;
    }, []);
  },

  @computed('allBlockItems.@each.blockItemType')
  tripSummaryBlockItem(allBlockItems) {
    return allBlockItems.findBy('blockItemType', 'trip-summary')
  },

  @computed('allExperiences')
  coverExperience (allExperiences) {
    if (allExperiences && allExperiences.length > 0) {
      return allExperiences.get("firstObject");
    }
  },

  getCheckinBlockItemIfItExists() {
    let checkinBlockItem = null;
    this.get('blocks').every((block)=> {
      checkinBlockItem = block.get('blockItems').findBy('blockItemType', 'checkin')
      return !checkinBlockItem;
    })
    return checkinBlockItem;
  },

  getBlockItemByTypeIfItExists(blockItemType) {
    let blockItem = null;
    this.get('blocks').every((block)=> {
      blockItem = block.get('blockItems').findBy('blockItemType', blockItemType)
      return !blockItem;
    })
    return blockItem;
  },

  getTripSummaryBlockItemIfItExists() {
    let checkinBlockItem = null;
    this.get('blocks').every((block)=> {
      checkinBlockItem = block.get('blockItems').findBy('blockItemType', 'trip-summary')
      return !checkinBlockItem;
    })
    return checkinBlockItem;
  },

  getCheckinBlockIfItExists() {
    let specialBlockItem = null;
    this.get('blocks').every((block)=> {

      specialBlockItem = block.get('blockItems').find((blockItem)=> {
        return ['checkin', 'about-region', 'todays-transfers', 'todays-preceding-stages'].includes(blockItem.get('blockItemType'))
      })

      return !specialBlockItem;
    })
    if (specialBlockItem) {
      return specialBlockItem.get('block')
    }
  },


  makeCopy() {

    let copy = this.get('store').createRecord('schedule', {scheduleType: 'custom'});

    Self.eachAttribute((name) => {
      copy.set(name, this.get(name));
    })
    copy.set('region', this.get('region'))
    copy.set('scheduleType', SCHEDULE_TYPE_CUSTOM)
    this.get('blocks').forEach((block)=> {
      copy.get('blocks').pushObject(block.makeCopy())
    })
    return copy;
  }


});
