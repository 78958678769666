import {
  reads
} from '@ember/object/computed';
import Service, {
  inject as service
} from '@ember/service';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  not
} from 'ember-decorators/object/computed';

export default Service.extend({

  fastboot: service(),
  ui: service(),
  whitelabel: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  width: null,
  height: null,

  staticHeight: null, // only set once

  initialized: false,
  debounceDelay: 16, // 16ms = 60fps

  resizeCallback: null,

  @computed()
  dpr() {
    if (window && window.devicePixelRatio) {
      return window.devicePixelRatio
    }
    return 1
  },

  setupResizeListener: function() {
    if (this.get('isFastBoot')) {
      // this.set('initialized', true); ?
      this.setProperties({
        isTablet: true,
        width: 1000
      });
      return;
    }

    window.addEventListener('resize', run.bind(this, this.resizeDebounce), false);
    this.set('staticHeight', Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
    this._setupDefaultBreakpoints();
    this.resize();

  }.on('init'),

  resizeDebounce() {
    run.debounce(this, this.resize, this.get('debounceDelay'), true);
  },

  resize() {
    // Will need a fallback for testing

    let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    this.setProperties({
      width: width,
      height: height
    });

    if (this.whitelabel.isForAgency) {
      run.next(()=> {
        this.ui.setAgencyTopNavHeight();
      })
    }
    // FeaturedBanner height is static at the mo
    // run.next(()=> {
    //   this.ui.setFeaturedBannerHeight();
    // })



    if (!this.get('initialized')) {
      this.set('initialized', true);
    }

    if (this.get('resizeCallback')) {
      this.get('resizeCallback')();
    }
  },

  /*
    NOTE:
    These are limited to their boundaries, eg:
    isTablet is only 768px >< 1147px
    They are what we have been using until now
  */
  @not('isTablet') isMobileOnly: false,

  @computed('isMobileOnly', 'isTablet', 'isDesktop')
  isTabletOnly(isMobileOnly, isTablet, isDesktop) {
    return !isMobileOnly && isTablet && !isDesktop;
  },

  @computed('isTabletOnly', 'isDesktop', 'isMonitor')
  isDesktopOnly(isTabletOnly, isDesktop, isMonitor) {
    return !isTabletOnly && isDesktop && !isMonitor;
  },

  @computed('isDesktopOnly', 'isMonitor')
  isMonitorOnly(isDesktopOnly, isMonitor) {
    return !isDesktopOnly && isMonitor;
  },


  /*
    NOTE:
    These behave like CSS, ie: isTablet=true means isDesktop=true also
    Set by matchMedia
   */
  isMobile: true, // always true
  is600: false,
  isTablet: false,
  is1000: false,
  isDesktop: false,
  isMonitor: false,

  /*
    Custom breakpoints are added here via addBreakpoints and will be accessible via theyre respective keys, eg:
    is + [key]:
    isSomeBreakpoint: true,

    Keep in mind they also behave as above, like CSS.
  */
  addBreakpoints(bps) {
    if (window.matchMedia) {
      for (var bp in bps) {
        if (bps.hasOwnProperty(bp)) {
          this._matchMedia(bp, window.matchMedia(bps[bp]));
          window.matchMedia(bps[bp]).addListener(this._matchMedia.bind(this, bp))
        }
      }

    }
  },

  removeBreakpoints(bps) {
    if (window.matchMedia) {
      for (var bp in bps) {
        if (bps.hasOwnProperty(bp)) {
          window.matchMedia(bps[bp]).removeListener(this._matchMedia.bind(this, bp))
        }
      }
    }
  },

  _matchMedia(prop, media) {
    this.set('is' + prop.capitalize(), media.matches);
  },

  _setupDefaultBreakpoints() {
    var mediaList = {
      600: "(min-width: 600px)",
      tablet: "(min-width: 768px)",
      1000: "(min-width: 1000px)",
      desktop: "(min-width: 1147px)",
      monitor: "(min-width: 1500px)"
    }
    if (window.matchMedia) {
      for (var media in mediaList) {
        if (mediaList.hasOwnProperty(media)) {
          this._matchMedia(media, window.matchMedia(mediaList[media]));
          window.matchMedia(mediaList[media]).addListener(this._matchMedia.bind(this, media))
        }
      }
    }
  }

});
