import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import DeviseAuthenticator from 'ember-simple-auth/authenticators/devise';
import config from "b5b/config/environment";

export default DeviseAuthenticator.extend({
  store: service('store'),
  session: service('session'),

  serverTokenEndpoint: null,

  init() {
    this._super(...arguments)
    this.set('serverTokenEndpoint', config.APP.host+'/server/users/sign_in')
  },

  // For direct email and password auth, the parameters will come through as strings
  // For external auth from facebook, the credentials param will be ab object with necessary info
  authenticate(email, credentials) {
    this.set('session.userInitiatedLogin', true);
    if (typeof credentials === 'string') {
      return this._super(email, credentials);
    } else {
      var token = credentials.token;
      var id = credentials.id;
      var promise = new RSVP.Promise(function(resolve) {
        var response = {
          email,
          token,
          id
        };
        run(null, resolve, response);
      });
      return promise;
    }
  },

  invalidate() {
    return this.store
      .createRecord('sign-out').save()
      .catch(() => RSVP.resolve());
  }
});
