import { inject as service } from "@ember/service";
import { reads } from "@ember/object/computed";
import { computed } from "ember-decorators/object";
import { alias } from "ember-decorators/object/computed";
import { htmlSafe } from "@ember/string";
import Component from "@ember/component";

export default Component.extend({
  classNameBindings: [
    ":nav-bar",
    "ui.scrollOffMainNav:scroll-off-main-nav",
    "viewingMapListing",
  ],

  tagName: "nav",

  ui: service(),
  router: service(),
  fastboot: service(),
  isFastBoot: reads("fastboot.isFastBoot"),

  @alias("ui.entityNavData") entityNavData: false,
  @alias("ui.viewingMapListing") viewingMapListing: false,
  @alias("ui.scrollOffMainNav") scrollOffMainNav: false,

  @computed("ui.agencyTopNavHeight")
  navItemStyle(agencyTopNavHeight) {
    return htmlSafe(`top: ${agencyTopNavHeight + 6}px`);
  },
});
