import Controller, { inject as controller } from "@ember/controller";
import { alias } from "ember-decorators/object/computed";
import UseCurrentTrip from 'b5b/mixins/use-current-trip';
import { computed } from "ember-decorators/object";
import {
  addSecondCurrencyHashContentsIntoFirstHash
} from 'b5b/helpers/format-currency';

export default Controller.extend(UseCurrentTrip, {
  tripIndexEditController: controller("trip.index.edit"),

  @alias("tripIndexEditController.recordsChanged") recordsChanged: null,

  @computed("trip.itinerary.quote.actualLineItems.@each.partner", "trip.itinerary.quote.actualLineItems.@each.originalPerGuest", "trip.itinerary.quote.actualLineItems.@each.currency")
  partnerNetMapping(actualLineItems) {
    let partnerNetMapping = {};
    actualLineItems.forEach((lineItem) => {
      if (lineItem.partner) {
        let currencyHash = partnerNetMapping[lineItem.belongsTo("partner").id()]
          ? partnerNetMapping[lineItem.belongsTo("partner").id()]
          : {};
        let amount = 0;

        if (!isNaN(parseFloat(lineItem.originalPerGuest))) {
          amount +=
            parseFloat(lineItem.originalPerGuest) *
            lineItem.applicableGuests.length;
        }
        let lineItemHash = {};
        lineItemHash[lineItem.currency] = amount;
        addSecondCurrencyHashContentsIntoFirstHash(currencyHash, lineItemHash);
        partnerNetMapping[lineItem.belongsTo("partner").id()] = currencyHash;
      }
    });
    return partnerNetMapping;
  },

});
