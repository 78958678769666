import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  isNone,
  isPresent
} from '@ember/utils';
import RSVP from 'rsvp';
import {
  run
} from '@ember/runloop';
import FriendlyIdRoute from 'b5b/mixins/friendly-id-route';
import {
  alias
} from 'ember-decorators/object/computed';
import { later, cancel } from '@ember/runloop';

import {
  trackEvent,
  generateMeta,
  humanReadableList,
  capitalizeFirstLetter,
  continentalJourney,
  getUrlParams
} from 'b5b/utils';
import trip from '../../adapters/trip';

export default Route.extend(FriendlyIdRoute, {

  headTagsService: service('head-tags'),
  scroll: service(),
  ui: service(),
  screen: service(),
  settings: service(),
  router: service(),
  whitelabel: service(),
  historyService: service(),

  tripService: service('trip'),
  @alias('tripService.currentTrip') currentTrip: false,
  @alias('tripService.currentTrip') trip: false,

  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  modelName: 'trip',

  modelQueries(params) {
    let query = {};
    // Because we need this in the model hook we unfortunately have to do this manually
    let urlParams=getUrlParams();
    let parmsToPassToApi = ['allocatedEmail'];
    parmsToPassToApi.forEach((paramKey)=> {
      if (urlParams[paramKey]) {
        query[paramKey]=urlParams[paramKey]
      }
    })

    if (params.selectedTripIdea){
      query.includeSpecifiedTripIdea = params.selectedTripIdea;
    }
    return query;
  },

  headTags() {
    let model = this.currentModel;
    var seoName = model.get('seoName');
    if (!isPresent(seoName)) {
      seoName = model.get('name');
    }
    var title = `${seoName} | ${this.whitelabel.agencySeoName}`;

    var description = model.get('asyncModels.tripInfo.overview');

    var lodgeActivities = humanReadableList(model.get('lodgeActivities'));

    if (!isPresent(description)) {
      description = `${continentalJourney(model)} to ${model.get('countriesList')} travelling to ${model.get('regionsList')}`;
    }

    var imageUrl = model.get('heroEntity.kodakOriginalUrl');
    model.set('asyncModels', null);

    let robots = model.get('published') && !model.get('userTrip') ? 'index, follow' : 'noindex, nofollow';

    if (!this.get('isFastBoot')) {
      document.title = title;
    }

    let link = `trip/${model.get('friendlyId')}`;

    return generateMeta({
      description,
      robots,
      'og:title': model.get('dream') ? seoName : model.get('name'),
      'og:image': imageUrl,
      link
    });
  },

  setupController(controller, model) {
    this._super(...arguments);

    let currentUserPromise = this.get('session.currentUserPromise');
    controller.set('currentUser', currentUserPromise);

    this.ensureDefaultsOnTrip(model);

    // Whenever a user views a trip that is associated with a consultant, set that consultant to be the global consultant
    let consultant = this.get('currentTrip.consultant')
    this.settings.updateConsultant(consultant);

    // NOTE IS IMPORTANT THAT WE DO NOT USE THE MODEL FROM MODEL HOOK.. WE NEED TO GET THE DRAFT TRIP that is set ir trip route
  },

  beforeModel() {
    this._super(...arguments);

    // if transitioning to new trip, but no model, as in case of refresh - we shouldnt allow trips to be saved with this name
    if (!this.get('currentTrip') && arguments[0].params['trip.index'].tripFriendlyId === 'my-new-trip') {

      this.transitionTo('trip.new');
    }
  },

  afterModel(model, transition) {
    this._super(...arguments);

    let trip = model;
    // If the currentTrip id == model.id then the this means this is not the first time coming into this route. We
    // might be returning from setting a ldoge for example

    if (trip.get('id') && this.get('currentTrip.id') !== trip.get('id')) {
      // console.log('setting up draft trip for real trip')
      let filterLodge = transition.queryParams.filters && JSON.parse(transition.queryParams.filters).findBy("type", 'lodge')
      if (filterLodge && !(trip.isPackage || trip.isSpecial )) {
        this.set('tripService.lodgeToApplyOnTripIdea', this.get('store').peekRecord('lodge', filterLodge.id));
      } else {
        // ensure that the lodge to apply to trip when you browse to your next trip
        this.set('tripService.lodgeToApplyOnTripIdea', null);
      }

      // We store a copy of this so that it can be used to restore a route to "chhose your own lodges" option
      this.set('tripService.originalRoute', trip.makeCopy());
      this.set('tripService.currentTrip', trip);

      if (this.tripService.lodgeToApplyOnTripIdea) {
        this.get('tripService.currentTrip.itinerary.nonTransferAndLocationStages').forEach((stage)=> {
          this.tripService.applyLodgeToMatchingStage(stage);
        })
      }

      this.tripService.cleanupQuoteToolsDirtyIndicators();

      if (trip.get('contentManagedTrip')) {
        this.tripService.setDefaultSchedulesOnCurrentTrip()
      }
    } else {
      this.set('tripService.editingEnabled', false)
    }

    // if refreshing unsaved trip, recreate new trip
    if (!this.get('currentTrip')) {
      transition.abort();
      this.transitionTo('trip.new');
    }

    // if no model for some reason, set to currentTrip
    if (this.get('currentTrip') && this.get('currentTrip.isNew') && !model) {
      model = this.get('currentTrip');
    }

    if (this.get('isFastBoot')) {
      return new RSVP.Promise(function(resolve) {
        resolve(model.get('tripInfo'));
      });
    }

    let actualTrip = model;

    // the heroentity is a CP instead of a
    let asyncModels = RSVP.hash({
      tripInfo: model.get('tripInfo')
    });
    asyncModels.then((asyncModels) => {
      model.set('asyncModels', asyncModels);
      this.get('headTagsService').collectHeadTags();
    });

    let loadedTripIdea = null;

    if (transition.queryParams.tripIdea) {
      // this.flashMessages.success('Applying trip idea...');
      loadedTripIdea = this.tripService.applyTripIdeaFromQueryParam({trip: model, transition});
    }

    let tripIdeaIds = actualTrip.hasMany('tripIdeas').ids();

    if (loadedTripIdea && tripIdeaIds) {
      // remove trip idea already loaded
      tripIdeaIds = tripIdeaIds.filter((tripIdeaId)=>{
        return tripIdeaId != loadedTripIdea.id
      });
    }

    if (tripIdeaIds.length==0) {
      model.set('tripIdeasLoaded', true);
      model.set('tripIdeasPromise', new RSVP.Promise(function(resolve) {
        resolve();
      }));
    }

    if (!model.get('tripIdeasLoaded')) {
      let tripIdeasPromise = this.store.query('trip', { ids: tripIdeaIds});
      model.set('tripIdeasPromise', tripIdeasPromise)
      tripIdeasPromise.then(()=> {
        model.set('tripIdeasLoaded', true);
      })
    }

    if (transition.queryParams.startDate) {
      this.actions.setStartDate.bind(this)(moment(transition.queryParams.startDate, "DD-MM-YYYY").toDate());
    }

  },

  ensureDefaultsOnTrip(trip) {
    if (isPresent(trip.get('itinerary'))) {
      if (isNone(trip.get('itinerary.numAdults'))) {
        trip.set('itinerary.numAdults', 2);
      }
      if (isNone(trip.get('itinerary.numYoungAdults'))) {
        trip.set('itinerary.numYoungAdults', 0);
      }
      if (isNone(trip.get('itinerary.numChildren'))) {
        trip.set('itinerary.numChildren', 0);
      }
      if (isNone(trip.get('itinerary.numInfants'))) {
        trip.set('itinerary.numInfants', 0);
      }
      if (isNone(trip.get('itinerary.numRooms'))) {
        trip.set('itinerary.numRooms', 1);
      }
    }
  },

  resetController(controller, isExiting, transition) {
    this._super(...arguments);
    if (transition) {
      controller.set('savingTrip', false);
    }
    if (isExiting) {
      controller.set('showSaveReminderBar', true);
      controller.set('newStageNumNights', 3);

      controller.setProperties({
        ignoreUnsavedChangesOnExit: false,
        unsavedTripChangesTransition: null,
        abortingSuspension: false
      });
    }

    if (!isExiting) {
      this.get('scroll').to(0, this);
    }
  },

  resetScrollToTop() {
    this.scroll.to(0);
  },

  activate() {
    this._super(...arguments)

    this.runVersionChecker()

  },

  deactivate() {
    cancel(this.get('latestVersionChecker'));
  },

  runVersionChecker() {
    if  (this.get('session.currentUser.isManager') && this.whitelabel.isForTimbuktu) {
      this.set('latestVersionChecker', later(this, function() {
        let trip = this.tripService.currentTrip;
        if (trip) {
          trip.getLatestVersion().then((tripInfo)=> {
            // console.log(this.tripService.currentTrip.id.toString(), tripInfo.id.toString(), tripInfo.version.toString(), trip.version.toString(), this.tripService.currentTrip.id === tripInfo.id && tripInfo.version != trip.version)
            if (this.tripService.currentTrip.id.toString() === tripInfo.id.toString() && tripInfo.version.toString() != trip.version.toString()) {
              this.get('ui').showGeneralMessage('Oops!', `A newer version of this trip has been saved by ${tripInfo.latestEditor}. You need to refresh and get the latest version before you can save changes.`);
              cancel(this.get('latestVersionChecker'));
            } else {
              this.runVersionChecker();
            }

          }, ()=>{ //If there is an error then we are not able to check the version so we just keep checking}
            this.runVersionChecker();
          })
        }
      }, 20000));
    }
  },



  actions: {


    getTextSuggestions(modelToUpdate, suggestionField) {
      if (modelToUpdate && suggestionField) {
        this.set('controller.modelToUpdate', modelToUpdate);
        this.set('controller.suggestionField', suggestionField);
        this.set('controller.showTextSuggestionsModal', true);
      } else {
        console.log('error showing suggestions');
      }
    },

    saveTrip(options={}) {

      // This is is called by travel specialists when they save a trip, or by content team when editing routes

      if (this.tripService.notOwnTrip && !(this.trip.contentManagedTrip && this.session.currentUser.isContentTeam)) {
        this.get('ui').showGeneralMessage('Oops!', 'Please add yourself to this trip if you would like to save changes');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      if (this.get('trip.itinerary.quote.actualLineItems.length') == 1) {
        //Deal with possible blank line item created whne going to pricing route
        let lineItem = this.get('trip.itinerary.quote.actualLineItems.firstObject');
        if (lineItem.isNew && !lineItem.originalPerGuest && !lineItem.overridePerGuest) {
          lineItem.deleteRecord();
        }
      }

      let valid = true;
      let validServiceTypes = true;
      let validReaons = true;
      let lineItems = this.get('trip.itinerary.quote.actualLineItems');
      if (lineItems) {
        //If there is no quote then line items will be null
        this.get('trip.itinerary.quote.actualLineItems').forEach((lineItem) => {
          if (!lineItem.belongsTo('partner').id()){
            valid = false;
          }
          if (this.tripService.timbuktuTripOnPricingModel4 && !lineItem.belongsTo('serviceType').id()) {
            validServiceTypes = false
          }
          // console.log(lineItem.reasonForNoAutoMarkup)
          if (this.tripService.timbuktuTripOnPricingModel4 && !lineItem.useTimbuktuAutoMarkup && !lineItem.reasonForNoAutoMarkup) {
            validReaons = false
          }

        });
      }

      if (!valid){
        this.get('ui').showGeneralMessage('Oops!', 'Please ensure that all line items in the pricing tab have partners allocated to them.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }
      if (!validServiceTypes){
        this.get('ui').showGeneralMessage('Oops!', 'Please ensure that all line items in the pricing tab have service types allocated to them.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }
      if (!validReaons){
        this.get('ui').showGeneralMessage('Oops!', 'If the Timbuktu auto markup is disabled on any line items please add a reason.');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      if (valid && this.get('trip.itinerary.quote.actualLineItems.length') > 0 && (!this.get('trip.itinerary.quote.sourceCountry') || !this.get('trip.itinerary.quote.settlementCurrency'))) {
        this.get('ui').showGeneralMessage('Oops', 'Please select source country and settlement currency in pricing tab');
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      let allTransferVehicleTypes = this.get('trip.itinerary.transferStages').mapBy('transfer.vehicleType');
      if (allTransferVehicleTypes.includes(undefined)){
        this.set('controller.showCheckVehicleTypes', true);
        return new RSVP.Promise(function(resolve) {
          resolve(true);
        });
      }

      let args = {showSuccessFlash: true};
      if (options.makeCopyOfTrip !== undefined) {
        args.makeCopyOfTrip = options.makeCopyOfTrip;
      }
      return this.controller.saveTrip(this.get('trip'), args);
    },

    chooseTripIdea(tripIdea){

      trackEvent('trip:change-trip-idea');
      this.set('ui.showTripIdeasModal', false);

      if (this.queryParam){
        this.set('queryParam', tripIdea.tripIdeaType)
      }
      if (this.router.currentRouteName=='trip.index.editor') {
        // When the trip idea is applied the modal will be closed and then we scroll to the top afterwards
        this.set('scroll.onRemoveModal', this.resetScrollToTop.bind(this));
      }
      this.tripService.applyTripIdea({tripIdea});
      if (this.router.currentRouteName=='trip.index.index') {
        this.set('tripService.currentStageIndex', 0);
        this.tripService.focusStage();
      }
      ;
    },


    setStartDate(date) {
      this.set('tripService.currentTrip.itinerary.startDate', date);

      var totalNights = 0,
        startDate = this.get('tripService.currentTrip.itinerary.startDate');

      this.get('tripService.currentTrip.itinerary.stages').forEach((stage) => {
        stage.set('startDate', moment(startDate).add(totalNights, 'days'));
        totalNights += stage.get('numNights');
      });
      this.tripService.madeChanges();
    },

    openTripSorting(){
      this.set('tripService.tripSortingModal', {
        view: 'list'
      });
    },

    closeOptionsModalThen(action){
      action();
      this.set('ui.showStageOptionsModal', false);
    },


    showBankTransferModal(bankTransferPriceHash) {
      if (!this.get('currentTrip.itinerary.termsAcceptedDateTime') && this.get('currentTrip.itinerary.stateQuote') && !this.get('trip.isGiftList')) {
        if  (!this.get('session.currentUser.isManager')) {
          this.get('ui').showGeneralMessage('Oops!', `Please accept ${capitalizeFirstLetter(this.whitelabel.agency.name)}'s terms and conditions before continuing`);
          return;
        }
      }
      this.set('controller.showBankTransferModal', true)
      this.set('controller.bankTransferPriceHash', bankTransferPriceHash)
    },

    didTransition() {
      this._super(...arguments);

      run.bind(this.get('controller'), this.get('controller.trackQuoteViews'))();

      //reset

      this.tripService.set('applyingTripIdeaInTransition', false);

      return true;
    },

    willTransition(transition) {
      this._super(...arguments);

      if (transition.targetName === 'login') {
        transition.abort();
        if (this.whitelabel.isOnboardingAgency) {
          return this.set('ui.showWaybirdSignupModal', true)
        } else {
          this.set('ui.showLoginModal', true);
        }
      }

      if (!this.get('tripService.hasBackButton') && transition.targetName.indexOf('trip.index') < 0) {
        if (!this.whitelabel.isOnboardingAgency && this.get('currentTrip.hasUnsavedChanges') && !this.controller.get('ignoreUnsavedChangesOnExit') && this.get('currentTrip.itinerary.stages.length')>0) {
          transition.abort();

          if (transition.targetName === 'lodges') {
            transition['suspension'] = this.historyService.currentSuspension;
          }

          this.controller.setProperties({
            showUnsavedTripChangesModal: true,
            unsavedTripChangesTransition: transition
          });
        }
      }

      // return true so willTransition bubbles
      return true;
    }
  }

});
