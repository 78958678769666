define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l6sCuWhc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[12,\"id\",[22,0,[\"for\"]]],[12,\"accept\",[22,0,[\"accept\"]]],[12,\"capture\",[22,0,[\"capture\"]]],[12,\"multiple\",[22,0,[\"multiple\"]]],[12,\"disabled\",[22,0,[\"disabled\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[11,\"hidden\",\"\"],[11,\"type\",\"file\"],[9],[10],[14,1,[[22,0,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });
});