import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ["trip-card"],

  entityModals: service(),
  router: service(),
  tripService: service("trip"),
  store: service(),
  screen: service(),
  session: service(),

  tripSummary: null,

  didInsertElement() {
    let regionIds = [];
    if (this.get("tripSummary")) {
      let hasManyRelation = this.get("tripSummary").hasMany("regions");
      if (hasManyRelation) {
        regionIds = hasManyRelation.ids();
      }
    }

    this.store
      .query("region", { ids: regionIds, force_region_ids: true })
      .then((regions) => {
        let filteredRegions = regions.filter(
          (region) => !region.isStopOver && regionIds.includes(region.id)
        );
        this.set("regionsForCard", filteredRegions.slice(0, 3));
      });
  },

  actions: {
    _endLoad(tripSummary) {
    },

    _startLoad(tripSummary) {
    },

    previewTrip() {
      this.set("tripService.tripPreviewModal", {
        trip: this.store.queryRecord("trip", {
          id: this.get("tripSummary.tripFriendlyId"),
        }),
        view: "list",
      });
    },
  },
});
