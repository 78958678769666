define("ember-fn-helper-polyfill/helpers/fn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var HAS_NATIVE_PROXY = typeof Proxy === 'function';
  var INVOKE = 'invoke'; // TODO: this is wrong

  var context = function buildUntouchableThis() {
    var context = null;
    if (true /* DEBUG */ && HAS_NATIVE_PROXY) {
      var assertOnProperty = function assertOnProperty(property) {
        (true && !(false) && Ember.assert("You accessed `this.".concat(String(property), "` from a function passed to the `fn` helper, but the function itself was not bound to a valid `this` context. Consider updating to usage of `@action`.")));
      };
      context = new Proxy({}, {
        get: function get(_target, property) {
          assertOnProperty(property);
        },
        set: function set(_target, property) {
          assertOnProperty(property);
          return false;
        },
        has: function has(_target, property) {
          assertOnProperty(property);
          return false;
        }
      });
    }
    return context;
  }();
  var _default = _exports.default = Ember.Helper.extend({
    init: function init() {
      this._super();
      this._positional = null;
      this._fn = null;
    },
    compute: function compute(positional) {
      var _this = this;
      (true && !(typeof positional[0] === 'function') && Ember.assert("You must pass a function as the `fn` helpers first argument, you passed ".concat(positional[0]), typeof positional[0] === 'function'));
      this._positional = positional;
      if (this._fn === null) {
        this._fn = function () {
          var _this$_positional = _toArray(_this._positional),
            fn = _this$_positional[0],
            args = _this$_positional.slice(1);

          // TODO: fix this branch
          for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
            invocationArgs[_key] = arguments[_key];
          }
          if (typeof fn[INVOKE] === 'function') {
            // references with the INVOKE symbol expect the function behind
            // the symbol to be bound to the reference
            return fn[INVOKE].apply(fn, _toConsumableArray(args).concat(invocationArgs));
          } else {
            return fn.call.apply(fn, [context].concat(_toConsumableArray(args), invocationArgs));
          }
        };
      }
      return this._fn;
    }
  });
});