define("ember-leaflet/components/marker-layer", ["exports", "ember-leaflet/components/interactive-layer", "ember-leaflet/mixins/draggability", "ember-leaflet/mixins/div-overlayable", "ember-leaflet/macros/to-lat-lng"], function (_exports, _interactiveLayer, _draggability, _divOverlayable, _toLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var _default = _exports.default = _interactiveLayer.default.extend(_draggability.default, _divOverlayable.default, {
    leafletRequiredOptions: Object.freeze(['location']),
    leafletOptions: Object.freeze(['icon', 'clickable', 'draggable', 'keyboard', 'title', 'alt', 'zIndexOffset', 'opacity', 'riseOnHover', 'riseOffset']),
    leafletEvents: Object.freeze(['dragstart', 'drag', 'dragend', 'move', 'moveend', 'remove', 'add', 'popupopen', 'popupclose']),
    leafletProperties: Object.freeze(['zIndexOffset', 'opacity', 'location:setLatLng']),
    location: (0, _toLatLng.default)(),
    createLayer: function createLayer() {
      var _this$L;
      return (_this$L = this.L).marker.apply(_this$L, _toConsumableArray(this.get('requiredOptions')).concat([this.get('options')]));
    },
    // icon observer separated from generated (leaflet properties) due to a
    // leaflet bug where draggability is lost on icon change
    // eslint-disable-next-line ember/no-observers
    iconDidChange: Ember.observer('icon', function () {
      this._layer.setIcon(this.get('icon'));
      if (this.get('draggable')) {
        var _this$_layer$dragging;
        (_this$_layer$dragging = this._layer.dragging) === null || _this$_layer$dragging === void 0 || _this$_layer$dragging.enable();
      } else {
        var _this$_layer$dragging2;
        (_this$_layer$dragging2 = this._layer.dragging) === null || _this$_layer$dragging2 === void 0 || _this$_layer$dragging2.disable();
      }
    })
  });
});