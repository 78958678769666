import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  trackEvent
} from 'b5b/utils';
import {
  getProportionalPriceHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  computed,
  observes
} from 'ember-decorators/object';


export default Component.extend({
  tripService: service('trip'),
  router: service(),
  @alias('tripService.currentTrip') trip: null,
  classNames: ['payment-due'],
  customAmountLocked: false,

  @observes('trip.itinerary.quote.balanceDuePriceHash', 'paymentDue.paymentReason', 'userSpecifiedAmount', 'paymentDue.settlementCurrency', 'settings.currentCurrency')
  setPriceHashFromCustomAmount() {

    if (this.get('paymentDue') && ['chooseOwnAmount', 'giftListContribution'].includes(this.get('paymentDue.paymentReason'))) {
      var newPriceHash = {};
      if (this.userSpecifiedAmount) {
        newPriceHash= getProportionalPriceHash(this.userSpecifiedAmount, this.settings.currentCurrency, this.trip.itinerary.quote.balanceDuePriceHash)
      }
      this.set('paymentDue.priceHash', newPriceHash)
    }
  },



  @computed('paymentDue.paymentReason', 'trip.paymentProcessor')
  needsCustomAmountLocked(paymentReason, paymentProcessor) {
    if (['chooseOwnAmount', 'giftListContribution'].includes(paymentReason) && ['stripe','stripe_uk','stripe_us'].includes(paymentProcessor)) {
      return true;
    }
  },

  actions: {
    // THis action is duplicated in flywire component
    paymentMade(paymentType) {
      // we are refreshing the current trip from server because at the moment we are not refreshing the draft trip after a payment is completed.
      //  We are doing that because it causes the array of itinerary.paymentDue to refresh whihc causes the pay-form to be removed from DOM to early

      // Previously we were refreshing the trip because of this below:
      // OK basically we are reloading the trip here so that we can see when a charge that pays for a custom payment has resulted in a payment being marked as completed. THen it dissapears off the list
      // I tried super hard to just sideload the trip and its charges/payments but couldn't seem to get ED to acccept an array of sideloaded charges coming back together with the charge that had just been created
      this.tripService.refreshCurrentTripFromServer().then(()=> {

        trackEvent(`trip:quote:${paymentType}-paid`);

        // This logic is duplicated in charge-processed route
        if (this.get('tripService.currentTrip.isGiftList')) {
          this.router.replaceWith('trip.index.contribute.success', this.tripService.currentTrip);
        } else {
          this.router.replaceWith('trip.index.pay.success', this.tripService.currentTrip);
        }
      });
    }

  }
});
