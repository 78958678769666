import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  computed
} from 'ember-decorators/object';


export default Component.extend({

  classNames: ['x-plorer-pagination'],

  xplorer: service('components/x-plorer'),

  @computed('xplorer.meta.total_pages', 'xplorer.page')
  hasNext(totalPages, currentPage) {
    return currentPage < totalPages;
  },

  @computed('xplorer.meta.total_pages', 'xplorer.page')
  hasPrev(totalPages, currentPage) {
    return currentPage > 1
  }

});
