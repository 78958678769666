import LodgeCard from 'b5b/components/lodge-card/component';
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default LodgeCard.extend({
  classNames: ['tw-relative'],

  screen: service(),

  showAvailability: false,
  canSwipe: true,

  @computed('lodge.teaserText')
  toolTipContent(teaserText) {
    return teaserText;
  },

  @computed('screen.isTablet', 'screen.isDesktop', 'lodge.specialOfferCount')
  maxExperiencesIndex(isTablet, isDesktop, specialOfferCount) {
    let max = isDesktop ? 3 : isTablet ? 2 : 1;
    if (specialOfferCount > 0) {
      max = max - 1;
    }
    return max;
  },

  @computed('lodgeActivityExperiences.length', 'maxExperiencesIndex')
  remaningExperiencesCount(lodgeExperiencesLength, maxExperiencesIndex) {
    let experiencesMinDisplayed = lodgeExperiencesLength - (maxExperiencesIndex + 1);
    return experiencesMinDisplayed > 0 ? experiencesMinDisplayed : 0;
  }

});
