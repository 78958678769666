import Component from '@ember/component';
import { computed } from '@ember/object';
import {
  inject as service
} from '@ember/service';

export default Component.extend({
  tagName: '',

  router: service(),

  // So we can call {{my-smart-link "routeName" model}}
  // just like {{link-to "routeName" model}}
  positionalParams: ['routeName', 'model'],

  // Pass forceRefresh=true when you want a full browser reload.
  forceRefresh: true,

  // Compute the actual URL from the route + optional model
  linkUrl: computed('routeName', 'model', function() {
    let router = this.get('router');
    let routeName = this.get('routeName');
    let model = this.get('model');

    let href = window.location.protocol + '//' + window.location.host;
    if (model) {
      href=href + router.urlFor(routeName, model);
    } else {
      href=href + router.urlFor(routeName);
    }
    return href
  }),
  actions: {
    clickMe() {
      console.log('clickMe')
    }
  }




});
