import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  trackEvent,
  capitalizeFirstLetter,
  getTermsAndConditionsUrlForTrip
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';

import Component from '@ember/component';
import TripSaver from 'b5b/mixins/trip-saver';

export default Component.extend(TripSaver, {

  classNames: ['pay-form'],

  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    throw 'This needs to be implemented by child of timbuktu pay-form component as the number of permissible currencies depends on pricing implementation'
  },

  @computed('paymentDue.priceHash', 'transactionCurrency', 'settings.currentCurrency')
  transactionAmount(priceHash, transactionCurrency, settingsCurrentCurrency) {

    let transactionPriceArgs = {trip: this.tripService.currentTrip, val: priceHash, formatPrice: false, toCurrency: transactionCurrency};
    let preferredCurrencyArgs = {trip: this.tripService.currentTrip, val: priceHash, formatPrice: false, toCurrency: settingsCurrentCurrency};
    let transactionPrice = this.tripService.getTripPrice({}, transactionPriceArgs);
    let preferredCurrencyAmount = this.tripService.getTripPrice({}, preferredCurrencyArgs);

    let xeRates = {};
    if (priceHash) {
      Object.keys(priceHash).forEach((currency) => {
        xeRates[`${currency}-${transactionCurrency}`] = convertCurrencyHash({
          val: 1,
          fromCurrency: currency,
          toCurrency: transactionCurrency,
          roundToClosest: 'skipRounding',
          isText: false,
          truncate: false,
          markPricesUp: false
        })
        xeRates[`${currency}-${settingsCurrentCurrency}`] = convertCurrencyHash({
          val: 1,
          fromCurrency: currency,
          toCurrency: settingsCurrentCurrency,
          roundToClosest: 'skipRounding',
          isText: false,
          truncate: false,
          markPricesUp: false
        })
      })
    }
    return transactionPrice;
  },

  @computed('transactionAmount')
  bankTransferDiscountAmount(transactionAmount) {
    // 1% discount for timbuktu bank transfer settlement
    return parseInt(transactionAmount * 0.01);
  },

  @computed('transactionAmount', 'bankTransferDiscountAmount')
  bankTransferTransactionAmount(transactionAmount, bankTransferDiscountAmount) {
    // 1% discount for timbuktu bank transfer settlement
    return parseInt(transactionAmount - bankTransferDiscountAmount);
  },

  @computed('bankTransferTransactionAmount', 'transactionCurrency')
  bankTransferTransactionAmountPriceHash(bankTransferTransactionAmount, transactionCurrency) {
    let priceHash = {};
    priceHash[transactionCurrency] = bankTransferTransactionAmount;
    return priceHash
  },


  saveChargeToServer(options={}) {

    let trip = this.tripService.currentTrip;
    let payment = this.paymentDue.customPayment;

    let amount = this.transactionAmount;
    let prices = this.paymentDue.priceHash;
    if (options.bankTransferDiscount) {
      amount = this.bankTransferTransactionAmount;
      prices = {}
      Object.keys(this.paymentDue.priceHash).forEach((currency) => {
        prices[currency] = Math.round(this.paymentDue.priceHash[currency] * 0.99 * 100) / 100;
      })
    }


    var charge = this.store.createRecord('charge', {
      state: 'created',
      currency: this.transactionCurrency,
      amount,
      payment: this.paymentDue.customPayment,
      preferredCurrencyAmount: this.preferredCurrencyAmount,
      preferredCurrency: this.settings.currentCurrency,
      stripeEmail: this.session.currentUser.email,
      paymentType: this.paymentDue.paymentType,
      affiliateId: this.affiliate.affiliateId,
      affiliateVisitorId: this.affiliate.affiliateVisitorId,
      chargeType: this.chargeType,
      trip: trip,
      itinerary: trip.itinerary,
      user: this.session.currentUser,
      termsAcceptedUrl: getTermsAndConditionsUrlForTrip(trip),
      giftFrom: this.paymentDue.giftFrom,
      giftMessage: this.paymentDue.giftMessage,
      hideGiftAmount: this.paymentDue.hideGiftAmount,
      transactionInfo: {
        prices,
        preferredCurrencyAmount: this.preferredCurrencyAmount,
        preferredCurrency: this.settings.currentCurrency,
        xeRates: this.xeRates,
        originalAmount: this.transactionAmount,
        bankTransferDiscount: options.bankTransferDiscount && true
      }
    })

    if (options.partnerResponse) {
      charge.transactionInfo.partnerResponse=options.partnerResponse;
    }

    if (options.state) {
      charge.set('state', options.state);
    }

    return charge.save()
  },

  handleFailedPayment(partnerResponse) {
    return this.saveChargeToServer({partnerResponse, state: 'failed'});
  },

  handleSuccesfulPayment(partnerResponse) {

    let trip = this.tripService.currentTrip;
    let currentItinState = trip.get('itinerary.state');
    let context = this;

    trackEvent('pay-button:payment:complete');

    let tripSavePromise = new RSVP.Promise(function(resolve, reject) {
      let promiseContext = this;
      if (trip.itinerary.isInstantBookable) {
        return this.saveTrip(this.tripService.currentTrip, {makeCopyOfTrip: true}).then((dupe)=>{
          this.tripService.set('currentTrip', dupe)

          // let url = this.whitelabel.getFullHostUrl('/api/v1/trips/'+dupe.friendlyId+'.json/convert_to_quote_using_special_offer');
          // let authHeader = `Token token="${this.session.data.authenticated.token}", email="${this.session.data.authenticated.email}"`
          // // headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
          // $.ajax({
          //   url,
          //   type: "PUT",
          //   data: '',
          //   headers: {'Authorization': authHeader},
          //   context: this
          // }).then(function() {
          //   resolve(this)
          // }).catch(function(errors) {
          //   reject(this)
          // });

          // NB there is something weird going on here. As soon as you call this member action convertToQuoteUsingSpecialOffer
          // the this context chnages so you can't call this.get('session.currentUser') but you can call this.session.currentUser
          dupe.convertToQuoteUsingSpecialOffer().then(()=> {
            resolve(null);
          });
        })
      } else {
        resolve(null);
      }
    }.bind(this));


    tripSavePromise.then(()=> {
      // NB there is something weird going on here. As soon as you call this member action convertToQuoteUsingSpecialOffer
      // the this context chnages so you can't call this.get('session.currentUser') but you can call this.session.currentUser
      // This is only an issue for instant bookable trips. As regular quotes go striaght to charge creation

      throw new TypeError('This whole method is not compatible with new solution. Would need to be reworked');
      this.saveChargeToServer({state: 'unvalidated', partnerResponse}).then((charge) => {
        //Charge succesful ...
        if (charge.belongsTo('affiliatePayment').id()) {
          charge.get('affiliatePayment').then((affiliatePayment) => {
            this.affiliate.trackSale(affiliatePayment);
          })
        }

        if (currentItinState == 'quote') {
          trackEvent('trip:deposit:paid');
        } else {
          trackEvent('trip:balance:paid');
        }

        // Only report the trip booking conversion on the first time a payment is made. Otherwise we'll over-report
        if (trip.get('itinerary.charges.length')==1) {
          trackEvent('trip:first-charge:paid');
          let tripPrice = this.tripService.getTripPrice({}, {trip: trip, val: trip.get('itinerary.totalSellingPriceHash'), formatPrice: false})
          // Because the trip price varies according to the target currneyc. We first calculate the price and then convert to gbp
          let tripPriceInGbp = convertCurrencyHash({val: tripPrice, toCurrency: 'gbp', fromCurrency: this.settings.currentCurrency, roundToClosest: 'skipRounding', truncate: false, markPricesUp:false});

          window.timbuktu.reportConversions.reportPayDeposit(tripPriceInGbp);
        }

        // NB Note that right now it is critical that we call the paymentCompleteAction which is paymentMade action currently
        // THis includes refreshing the trip to get the latest version of trip including the payments made

      }, (error) => {
        //charge save has failed!
        trip.set('itinerary.state', currentItinState);
        if (currentItinState == 'quote') {
          trackEvent('trip:deposit:failed-payment');
        } else {
          trackEvent('trip:balance:failed-payment');
        }

        this.ui.showGeneralMessage('Oops!', 'We had a problem completing this payment. Please get in touch so we can help');
        throw error
      }).then(() => {
        // console.log('success');
        if (this.paymentCompleteAction) {
          return this.paymentCompleteAction();
        }
      }, (error) => {
        // console.log('error');
        throw error;
      }).finally(() => {
        this.ui.set('showProcessingModal', false);
      })
    });
  },

  actions: {
  }
});
