import Component from '@ember/component';

export default Component.extend({
  tagName: 'button',
  classNames: ['hamburger-button'],
  variant: 'closed',
  onclick: null,

  click() {
    if (typeof this.onclick === 'function') {
      this.onclick();
    }
  }
});
