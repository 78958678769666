import Service, {
  inject as service
} from '@ember/service';
import {
  maxLodgePriceForRange,
  maxTripPriceForRange,
  ROUTE_DURATIONS,
  trackEvent
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';

import {
  singularize
} from 'ember-inflector';

export default Service.extend({

  store: service(),
  session: service(),
  scroll: service(),
  tripService: service('trip'),

  currentController: null,
  activeType: null,
  isLoading: false,
  showMap: true,

  loadCallback: null,

  trips: [],
  lodges: [],
  countries: [],
  templates: [],
  transfers: [],
  schedules: [],

  @alias('currentController.starRating') starRating: null,
  @alias('currentController.selectedContinentNames') selectedContinentNames: null,
  @alias('currentController.selectedCountryNames') selectedCountryNames: null,
  @alias('currentController.selectedRegionNames') selectedRegionNames: null,
  @alias('currentController.selectedAreaNames') selectedAreaNames: null,
  @alias('currentController.selectedRegionTypeNames') selectedRegionTypeNames: null,
  @alias('currentController.selectedExperienceNames') selectedExperienceNames: null,
  @alias('currentController.selectedLodgeStyleNames') selectedLodgeStyleNames: null,
  @alias('currentController.selectedTripLength') selectedTripLength: null,
  @alias('currentController.selectedTripLengths') selectedTripLengths: null,
  @alias('currentController.selectedLodgeNames') selectedLodgeNames: null,
  @alias('currentController.selectedMonthNames') selectedMonthNames: null,
  @alias('currentController.selectedBudget') selectedBudget: null,
  @alias('currentController.recurringDateRange') recurringDateRange: null,
  @alias('currentController.startDateRange') startDateRange: null,
  @alias('currentController.selectedAutoBudgetNames') selectedAutoBudgetNames: null,
  @alias('currentController.selectedMonth') selectedMonth: null,
  @alias('currentController.bookingState') bookingState: 'all',
  @alias('currentController.searchValue') searchValue: '',
  @alias('currentController.polishLevel') polishLevel: 'published_lodges',
  @alias('currentController.polishLevel') polishLevel: '',
  @alias('currentController.sort') sort: 'priority_asc',
  @alias('currentController.showOnlyAvailable') showOnlyAvailable: null,
  @alias('currentController.showOnlyWithOffers') showOnlyWithOffers: null,
  @alias('currentController.searchTemplateTrips') searchTemplateTrips: null,
  @alias('currentController.searchPackages') searchPackages: null,
  @alias('currentController.page') page: 1,
  @alias('currentController.per_page') per_page: null,
  @alias('currentController.buster') buster: true,
  @alias('currentController.isPopular') isPopular: null,
  @alias('currentController.swlat') swlat: null,
  @alias('currentController.swlng') swlng: null,
  @alias('currentController.nelat') nelat: null,
  @alias('currentController.nelng') nelng: null,
  @alias('currentController.user_id') user_id: null,
  @alias('currentController.userTripsOnly') userTripsOnly: null,
  @alias('currentController.allAccountTrips') allAccountTrips: null,
  @alias('currentController.allowAlternateSuggestions') allowAlternateSuggestions: null,
  @alias('currentController.startDate') startDate: null,
  @alias('currentController.endDate') endDate: null,
  @alias('currentController.curentRegionIds') curentRegionIds: null,
  @alias('currentController.exclusiveUseOnly') exclusiveUseOnly: null,
  @alias('currentController.isRegional') isRegional: null,
  @alias('currentController.paginationScrollHeight') paginationScrollHeight: 0,
  @alias('currentController.template') template: null,
  @alias('currentController.templateType') templateType: null,
  @alias('currentController.templateOrigin') templateOrigin: null,
  @alias('currentController.filters') filters: null,
  @alias('currentController.additionalFilter') additionalFilter: null,
  @alias('currentController.tripId') tripId: null,
  @alias('currentController.eventType') eventType: null,
  @alias('currentController.orRegionIds') orRegionIds: null,

  /*
    Maybe in some cases we want to load records without a currentController?
    In such cases we'll need logic to support either a static property or alias

    @or('currentController.selectedCountryName', 'selectedCountryName') selectedCountryName: null,
    @or('currentController.selectedRegionName', 'selectedRegionName') selectedRegionName: null,
    @or('currentController.selectedExperienceNames', 'selectedExperienceNames') selectedExperienceNames: null,
    @or('currentController.selectedLodgeNames', 'selectedLodgeNames') selectedLodgeNames: null,
    @or('currentController.selectedBudget', 'selectedBudget') selectedBudget: null,
    @or('currentController.selectedMonth', 'selectedMonth') selectedMonth: null,
    @or('currentController.bookingState', 'bookingState') bookingState: 'saved',
    @or('currentController.searchValue', 'searchValue') searchValue: '',
    @or('currentController.sort', 'sort') sort: 'priority_asc',
    @or('currentController.page', 'page') page: 1,
    @or('currentController.buster', 'buster') buster: null,
    @or('currentController.swlat', 'swlat') swlat: null,
    @or('currentController.swlng', 'swlng') swlng: null,
    @or('currentController.nelat', 'nelat') nelat: null,
    @or('currentController.nelng', 'nelng') nelng: null,

  */

  setupController(controller, cb) {
    // console.log('setup current contrller', arguments)
    this.setProperties({
      currentController: controller,
      loadCallback: cb
    });
  },

  teardownController() {
    this.setProperties({
      currentController: null,
      loadCallback: null
    });
  },

  isFilterPresent(model) {

    let existingFilter = this.get('filters').find((filter)=> {
      if (model.get) {
        return filter.id==model.get('id') && filter.type == model.get('_internalModel.modelName');
      } else {
        // Some of the pills are not real models. Like continents for example
        return filter.id==model.id && filter.type == model.type;
      }

    })
    return existingFilter

  },

  getFilterForModel(model) {
    // Continents arent real models, the just represent a model so you need to use model.type. We check if real moel by checking for presence of model.get function
    if (model.get) {
      return {id: model.get('id'), name: model.get('name'), type: model.get('_internalModel.modelName')}
    } else {
      return {id: model.id, name: model.name, type: model.type}
    }
  },

  addFilter(model) {
    console.log('adding new filter to filters array', this.currentController)
    if (!this.isFilterPresent(model)) {
      let filter = this.getFilterForModel(model)
      this.get('filters').pushObject(filter)
    }
  },


  loadRecords(recordsType, additionalQueryParams) {
    this.set('isLoading', true);
    this.set('activeType', recordsType);
    this.set(recordsType, null);

    if (additionalQueryParams && (!additionalQueryParams.skipSettingsRecords || Object.keys(additionalQueryParams).length > 1)) {
      console.log('WARNING>> YOU CAN USE additional query params like this on paginated lists as the additional query params fall away when you click to a different page')
    }

    var queryParams = this.getProperties(
      'selectedContinentNames',
      'selectedCountryNames',
      'selectedRegionNames',
      'selectedAreaNames',
      'selectedRegionTypeNames',
      'selectedLodgeNames',
      'selectedExperienceNames',
      'selectedLodgeStyleNames',
      'selectedTripLengths',
      'selectedMonthNames',
      'selectedBudget',
      'recurringDateRange',
      'startDateRange',
      'selectedAutoBudgetNames',
      'selectedMonth',
      'bookingState',
      'searchValue',
      'polishLevel',
      'showOnlyAvailable',
      'showOnlyWithOffers',
      'sort',
      'page',
      'per_page',
      'swlat',
      'swlng',
      'nelat',
      'nelng',
      'user_id',
      'allowAlternateSuggestions',
      'buster',
      'startDate',
      'endDate',
      'isPopular',
      'curentRegionIds',
      'exclusiveUseOnly',
      'isRegional',
      'template',
      'templateType',
      'templateOrigin',
      'filters',
      'userTripsOnly',
      'allAccountTrips',
      'tripId',
      'eventType',
      'orRegionIds'
    );

    // if (queryParams.filters && Array.isArray(queryParams.filters)) {
      if (this.additionalFilter) {
        queryParams.filters = queryParams.filters.concat(this.additionalFilter);
      }

      queryParams.filters = JSON.stringify(queryParams.filters);
    // }

    if (queryParams.selectedBudget) {
      queryParams.selectedBudget = this.getBudgetForServer(queryParams.selectedBudget);
    }

    if (queryParams.startDate || queryParams.endDate || ['custom', 'personal', 'personal_archived', 'archived'].includes(queryParams.polishLevel)) {
      queryParams.buster = true;
    }

    if (queryParams.selectedTripLengths && queryParams.selectedTripLengths.length > 0) {
      queryParams.selectedTripLength = this.getTripsLengthForServer(queryParams.selectedTripLengths);
    }

    if (queryParams.selectedLodgeStyleNames) {
      queryParams.selectedLodgeStyleNames = queryParams.selectedLodgeStyleNames.map((lodgeStyle) => {
        return lodgeStyle == 'Mobile safari' ? 'Mobile' : lodgeStyle;
      })
    }

    if (this.get('searchTemplateTrips')) {
      queryParams.is_template_trip = 'true';
    }

    if (this.get('searchPackages')) {
      queryParams.is_package = 'true';
    }

    if (recordsType=='feefoServiceReviews') {
      queryParams.has_trip = 'true';
      queryParams.star_rating = this.starRating;
    }

    let modelName = singularize(recordsType);
    return this.get('store').query(modelName,
      queryParams
    ).then((results) => {
      if (additionalQueryParams && additionalQueryParams.skipSettingsRecords) {
        // IN this case we return the results. Ideally we should change this to be the standard and use tasks everywhere
        //We do this because we want the tasks to be cancellable and restartable so the result should only be set in the
        // calling location instead of here in xplorer.
      } else {
        this.set(recordsType, results);
      }
      this.set('isLoading', false);

      if (results.meta && results.meta.totalCount === 0) {
        trackEvent(modelName + '-list:no-results');
      }

      if (this.get('loadCallback')) {
        this.get('loadCallback')();
      }
      return results;
    });
  },

  setAdditionalFilter(filterValue) {
    this.set('additionalFilter', {
      name: filterValue,
      type: "text",
    })
  },
  clearAdditionalFilter(filterValue) {
    this.set('additionalFilter', null)
  },

  getBudgetForServer(selectedBudget) {
    let budget = [selectedBudget[0], selectedBudget[1]];

    var maxPriceForRange = this.get('activeType') === 'lodges' ? maxLodgePriceForRange() : maxTripPriceForRange();
    if (!isNaN(selectedBudget[1]) && parseInt(selectedBudget[1]) >= maxPriceForRange) {
      budget[1] = 'max';
    }
    return budget;
  },

  getTripsLengthForServer(selectedTripLengths) {

    var minNights;
    var maxNights;

    // Find the smallest possible minNights of selected options
    for (var i = ROUTE_DURATIONS.length - 1; i >= 0; i--) {
      let routeDuration = ROUTE_DURATIONS.objectAt(i);
      if (selectedTripLengths.includes(routeDuration.key)) {
        minNights = routeDuration.minNights;
      }
    }

    // Find the largest possible maxNights of selected options
    ROUTE_DURATIONS.forEach((routeDuration) => {
      if (selectedTripLengths.includes(routeDuration.key)) {
        maxNights = routeDuration.maxNights;
      }
    });

    return `${minNights}-${maxNights}`;
  },

  addExperience() {},
  removeExperience() {},
  setMonth() {},
  clearMonth() {},

  _cachedMeta: null,

  @computed('activeType', 'isLoading', 'page')
  meta(activeType, isLoading) {
    if (isLoading) {
      return this.get('_cachedMeta');
    }

    if (this.get(activeType) && this.get(activeType).meta) {
      // Object {total_pages: 1, totalCount: 17, noMatch: false}
      this.set('_cachedMeta', this.get(activeType).meta);
    }

    return this.get('_cachedMeta');
  },

  @computed('meta')
  metaNoMatch(meta) {
    if (meta) {
      return meta.noMatch;
    }
  },

  @computed('meta')
  metaTotalItems(meta) {
    if (meta) {
      return meta.totalCount;
    }
  },

  @computed('meta', 'page', 'per_page')
  metaCurrentRange(meta, page, per_page) {
    if (meta) {
      return page > 1 ? ((page * per_page) - per_page) + 1 + ' - ' + Math.min(page * per_page, meta.totalCount) : '1 - ' + Math.min(per_page, meta.totalCount);
    }
  },

  reloadRecords() {
    this.loadRecords(this.get('activeType'));
  },

  actions: {
    nextPage() {
      this.set('page', this.get('page') + 1);
      this.loadRecords(this.get('activeType'));
      this.get('scroll').to(this.paginationScrollHeight || 0);
    },

    prevPage() {
      this.set('page', this.get('page') - 1);
      this.loadRecords(this.get('activeType'));
      this.get('scroll').to(this.paginationScrollHeight || 0);
    },

    toPage(page) {
      this.set('page', page);
      this.loadRecords(this.get('activeType'));
      this.get('scroll').to(this.paginationScrollHeight || 0);
    },

    reloadRecords() {
      this.loadRecords(this.get('activeType'));
    }
  }

});
