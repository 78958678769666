import xItineraryStageEdit from "b5b/components/x-itinerary/stage/edit/component";
import {
  computed
} from 'ember-decorators/object';
import {
  inject as service
} from '@ember/service';

export default xItineraryStageEdit.extend({
  classNameBindings: [':x-itinerary-stage-schedule'],

  isComponentForRealStage: false, //make the focus stage logic skip the schedule

  ui: service(),
  templateManager: service(),
  entityModals: service(),

  tripService: service('trip'),

  possibleLayouts: ['what-to-do', 'day', 'general'],

  actions: {

  }

});
