// app/components/nav-bar.js
import Component from "@ember/component";
import { computed } from "ember-decorators/object";
import { alias } from "ember-decorators/object/computed";

import { trackEvent } from "b5b/utils";

export default Component.extend({
  tagName: "",
  classNames: ["tw-bg-white", "tw-text-black", "tw-shadow-md"],

  isMobileMenuOpen: false,
  isActive: false,

  navItems: [
    { label: "Trips", route: "trips" },
    { label: "Countries", route: "countries" },
    { label: "Trip Wizard", route: "trip-wizard" },
    { label: "Reviews", route: "reviews" },
    { label: "About Us", route: "about" },
    { label: "Blogs", route: "blogs" },
    { label: "My Trips", route: "my-trips" },
  ],

  // init() {
  //   this._super(...arguments);
  //   console.log("navItems:", this.get("navItems")); // Debug
  // },

  actions: {
    closeNav() {
      this.set("isActive", false);
    },

    closeChat() {
      window.HubSpotConversations.widget.remove();
      this.set("isActive", false);
    },

    toggleNav() {
      this.toggleProperty("isActive");
      trackEvent("link:click:mobile-nav-toggle-nav");
    },

    logout() {
      this.get("router").transitionTo("index");
      return this.get("session").invalidate();
    },
  },
});
