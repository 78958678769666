import payFormComponent from 'b5b/components/pay-form/component';

import {
  inject as service
} from '@ember/service';
import {
  convertCurrency,
  convertCurrencyHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';
import {
  STRIPE_CURRENCIES,
  trackEvent,
  capitalizeFirstLetter
} from 'b5b/utils';
import {
  computed
} from 'ember-decorators/object';
import {
  observer
} from '@ember/object';
import RSVP from 'rsvp';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import config from 'b5b/config/environment';

export default payFormComponent.extend({

  chargeType: 'flywire',

  classNames: ['pay-form'],

  router: service(),
  session: service(),
  affiliate: service(),
  whitelabel: service(),
  settings: service(),
  tripService: service('trip'),
  store: service(),
  ui: service(),

  @alias('tripService.currentTrip') trip: false,

  _processing: false, // flag to control multiple instances
  _payButtonReady :false,

  didInsertElement() {
    this._super(...arguments);
    addAsyncScript('https://checkout.flywire.com/flywire-payment.js', null, function() {
      this.set('_payButtonReady', true)
    }.bind(this));
  },
  // not great but this sets a few props as side effects of being computed

  @computed('paymentDue.settlementCurrency')
  transactionCurrency(settlementCurrency) {
    return settlementCurrency;
  },

  actions: {

// https://solutions.flywire.com/en/checkout/implementation
// https://solutions.flywire.com/en/checkout/implementation#cookbook
// https://solutions.flywire.com/en/webhooks/notifications-v2
// https://solutions.flywire.com/en/portal-configuration


    openPayModal(options={}) {
      // Dont allow guest to pay with historical rates
      if (window.timbuktu.xeDate) {
        this.get('ui').showGeneralMessage('Oops!', 'You are currently using historical exchange rates. Please reload the page with the latest exchange rates before making payment. The url should not include xeDate=xx-xx-xxxx in it.');
        return;
      }

      // check if the terms and conditions have been accepted
      if (!this.get('trip.itinerary.termsAcceptedDateTime') && !this.get('session.currentUser.isManager') && !this.get('trip.isGiftList')) {
        this.get('ui').showGeneralMessage('Oops!', `Please accept ${capitalizeFirstLetter(this.whitelabel.agency.name)}'s terms and conditions before continuing`);
        return;
      }

      //console.log(`FLYWIRE_${config.APP.flywireEnv.toUpperCase()}_PORTAL_${this.get('paymentDue.settlementCurrency').toUpperCase()}`)
      let flywirePortal = this.get(`trip.contractingEntity.meta.properties.FLYWIRE_${config.APP.flywireEnv.toUpperCase()}_PORTAL_${this.get('paymentDue.settlementCurrency').toUpperCase()}`);
      //console.log(this.get(`trip.contractingEntity.meta.properties`))
      if (!flywirePortal) {
        let message = `We've had a problem preparing a ${this.get('paymentDue.settlementCurrency')} payment. Please contact us`;
        if (this.whitelabel.contactEmail) {
          message = message + ` on ${this.whitelabel.contactEmail}`
        }
        this.get('ui').showGeneralMessage('Oops!', message);
        return;
      }

      trackEvent('pay-button:click');

      this.saveChargeToServer(options).then((charge)=> {

        let callbackUrl = config.APP.flywireCallbackUrl;

        var flywireConfig = {
          // Set the target environment (demo, prod)
          env: config.APP.flywireEnv,

          // Set your unique code (may differ between demo and prod)
          recipientCode: flywirePortal,

          // Set the value of the payment to be processed
          amount: charge.amount,

          // paymentOptionsConfig: {
          //   // Only allow local card and online payments
          //   filters: {
          //       type: ['credit_card']
          //   }
          // },
          paymentOptionsConfig: {
            filters: {
                type: []
            }
          },

          // Recommended (not required) validation error handler
          onInvalidInput: function(errors) {
            var errors = ""
            errors.forEach(function(error) {
              errors = errors + error.msg + "\r\n";
            });
            this.get('ui').showGeneralMessage('Oops!', errors);
          },

          firstName: this.get('session.currentUser.name'),
          lastName: this.get('session.currentUser.surname'),
          email: this.get('session.currentUser.email'),

          // phone: "+44 1234 567 890",
          // address: "my addr",
          // city: "new york",
          // state: "ny",
          // zip: "12345",
          // country: 'US',

          recipientFields: {
            booking_reference: this.trip.id+"_"+charge.id
          },

          callbackUrl: callbackUrl,
          callbackId: charge.id,
          nonce: charge.id,
          callbackVersion: "2",

          // Display payer and custom field input boxes
          requestPayerInfo: true,
          requestRecipientInfo: true,
          skipCompletedSteps: true,

          onCompleteCallback: function(flywireResponse) {
            if (!this.get('isDestroying') && !this.get('isDestroyed')) {
              this.set('_payButtonReady', false)
            }

            return charge.updateFlywireState(flywireResponse).then((response)=> {
              let baseRoute = this.get('tripService.currentTrip.isGiftList') ? 'trip.index.contribute' : 'trip.index.pay'
              if (response.signature==flywireResponse.sig && flywireResponse.status=='success') {
                // This logic is duplicated in payment-due component and charge-processed component
                this.tripService.refreshCurrentTripFromServer().then(()=> {
                  if (this.get('tripService.currentTrip.isGiftList')) {
                    trackEvent(`trip:quote:contribution-paid`);
                  } else {
                    trackEvent(`trip:quote:${this.get('paymentDue.paymentType')}-paid`);
                  }
                  this.router.transitionTo(`${baseRoute}.success`);
                });
              } else if (response.signature==flywireResponse.sig && flywireResponse.status=='pending') {
                this.router.transitionTo(`${baseRoute}.processing`);
              } else {
                this.router.transitionTo(`${baseRoute}.error`);
              }
            }, (failedResponse)=> {
              this.get('ui').showGeneralMessage('Oops!', 'There has been an error processing this payment. Please contact your travel specialist');
            })
          }.bind(this),
        };

        if (this.whitelabel.agency.allowBankTransferPayments) {
          flywireConfig.paymentOptionsConfig.filters.type.pushObject('bank_transfer')
        }
        if (this.whitelabel.agency.allowCreditCardPayments && !options.bankTransferDiscount) {
          flywireConfig.paymentOptionsConfig.filters.type.pushObject('credit_card')
        }
        if (!this.whitelabel.agency.allowBankTransferPayments && !this.whitelabel.agency.allowCreditCardPayments ) {
          return this.get('ui').showGeneralMessage('Oops!', 'Please contact your travel specialist. Payments need to be enabled.');
        }

        if (this.whitelabel.agency.waybirdFeePercentage && this.whitelabel.agency.waybirdFeePercentage > 0) {

          // This
          let waybirdPortalCode = config.APP.flywireEnv.toUpperCase()=='DEMO' ? 'WYR' : 'WYR';
          let amount = (this.whitelabel.agency.waybirdFeePercentage / 100) * charge.amount;
          amount = parseFloat(amount.toFixed(2))
          flywireConfig.payables = [
            {
                amount,
                recipient: waybirdPortalCode,
                description: `${this.whitelabel.agency.waybirdFeePercentage}% fee from trip: ${this.trip.id}, ${this.whitelabel.agency.name}, ${flywirePortal}`
            }]
            // console.log(flywireConfig.payables)


        }

        // console.log(flywireConfig.paymentOptionsConfig)
        // console.log(flywireConfig)
        var modal = window.FlywirePayment.initiate(flywireConfig);
        modal.render();
      })

    }
  }
});



