import Controller, {
  inject as controller
} from '@ember/controller';

import {
  task
} from 'ember-concurrency';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  singularize
} from 'ember-inflector';
import {
  inject as service
} from '@ember/service';
import {
  trackEvent,
  captureEmail,
  getErrorsHashFromServerResponse,
  getCookieValue
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';

export default Controller.extend({

  router: service(),
  screen: service(),
  cache: service(),
  wizardController: controller('wizard'),

  @alias('wizardController.finalStepAndLoggedIn') finalStepAndLoggedIn: false,
  @alias('wizardController.nextStep') nextStep: false,
  @alias('wizardController.loadingRoutes') loadingRoutes: null,
  @alias('wizardController.wizardSubmission') wizardSubmission: null,
  @alias('wizardController.fixedButtonBar') fixedButtonBar: null,


  // Stored on persisting service
  goAnytime: false,
  preferedPartner: null,
  planningStage: null,
  expertShouldContact: null,

  preferedYear: "2025",

  allCountries: null,
  experiencesCompilation: null,

  selectedContinentName: undefined, // note this is singular, allowing only one to be selected at a time
  selectedCountryName: undefined, // note this is singular, allowing only one to be selected at a time
  selectedExperienceNames: [],
  selectedMonthName: undefined,

  wizardRules: {},

  maxResults: 3,

  possibleMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

  @computed()
  possibleYears() {
    let currentYear = new Date().getFullYear(),
      years = [];

    for (var i = currentYear; i <= (currentYear + 2); i++) {
      years.pushObject(i);
    }

    return years;
  },


  @computed('allCountries', 'showAllCountries')
  limitedCountries(allCountries, showAllCountries) {
    return showAllCountries ? allCountries : allCountries.slice(0, 6);
  },

  @computed('screen.isTablet')
  fixedImageSquareSize(isTablet) {
    if (isTablet) {
      return 300;
    } else {
      return 128;
    }
  },

  @computed('screen.isTablet')
  fixedImageWidth(isTablet) {
    if (isTablet) {
      return 390;
    } else {
      return 154;
    }
  },

  @computed('screen.isTablet')
  fixedImageHeight(isTablet) {
    if (isTablet) {
      return 200;
    } else {
      return 85;
    }
  },

  @computed('selectedExperienceNames.@each')
  selectedExperiences(selectedExperienceNames) {
    let selectedExperiences = [];
    this.store.peekAll('experience').forEach((experience)=> {
      if (selectedExperienceNames && selectedExperienceNames.includes(experience.name)) {
        selectedExperiences.pushObject(experience)
      }
    })

    return selectedExperiences;
  },

  showAllCountries: true,

  cachedSelectedMonthName: null,
  cachedSelectedCountryName: null,

  matchingRoutes: null,
  completing: false,

  NEXT_STEP_TIMEOUT: 0,

  loadSuggestions(type) {
    this.set('compilationType', type);
    this.loadSuggestionsTask.perform();
  },

  loadSuggestionsTask: task(function*() {
    let params = {};

    switch (this.compilationType) {
      case 'countries':
        break;
      case 'experiences':
        params['selectedMonthNames'] = this.get('selectedMonthName') ? [this.get('selectedMonthName')] : [];
        if (this.get('selectedContinentName')!='All') {
          params['selectedContinentNames'] = this.get('selectedContinentName') ? [this.get('selectedContinentName')] : [];
        }
        params['selectedCountryNames'] = this.get('selectedCountryName') ? [this.get('selectedCountryName')] : [];
        params['selectedExperienceNames'] = this.get('selectedExperienceNames');
        params['suggestionTypes'] = ['routes'];
        break;
      default:
        break;
    }

    let result = yield this.get('store').queryRecord('suggestion', params);

    this.setCompilation(result);
  }).keepLatest(),


  setCompilation(result) {

    let compilationName = this.compilationType + 'Compilation';

    this.set(compilationName, result.get('compilation').filterBy('type', singularize(this.compilationType)).map((record) => {
      return this.store.peekRecord(record.type, record.id);
    }));

    // if no results, and no valid options already selected, try less specific options
    if (Ember.isEmpty(this.get(compilationName)) && Ember.isEmpty(this.get('selectedExperiences'))) {
      this.retryAlternativeExperiencesQuery(result);
      console.log('retryAlternativeExperiencesQuery')
    } else {
      // succesful query, with results
      this.resetCachedValues();
    }
  },

  resetCachedValues() {
    // reset params
    if (this.get('cachedSelectedMonthName')) {
      this.set('selectedMonthName', this.get('cachedSelectedMonthName'));
    }

    // reset params
    if (this.get('cachedSelectedCountryName')) {
      this.set('selectedCountryName', this.get('cachedSelectedCountryName'));
    }
  },

  retryAlternativeExperiencesQuery(results) {

    /*
      Sometimes our selection returns no suggestions, in such a case we basically look for experiences in only the countries, or
      else only in the specified months
    */
    if (this.compilationType === 'experiences' && this.selectedExperiences.size <= 0) {
      // first we check if there is a country and months, and if so, empty the months to get results, but cache the current value
      if (this.get('selectedCountryName') && this.get('selectedMonthName')) {
        this.set('cachedSelectedMonthName', this.get('selectedMonthName'));
        this.set('selectedMonthName', undefined);
        return this.loadSuggestionsTask.perform();
      }

      // if we come back here because we still dont have results, try the same but with months
      if (this.get('cachedSelectedMonthName')) {
        this.set('cachedSelectedCountryName', this.get('selectedCountryName'));
        this.set('selectedCountryName', undefined);
        this.set('selectedMonthName', this.get('cachedSelectedMonthName'));
        this.set('cachedSelectedMonthName', null);
        return this.loadSuggestionsTask.perform();
      }

      // if all else fails, just query with empty params and hope for the best
      this.set('selectedCountryName', undefined);
      this.set('selectedMonthName', undefined);
      return this.loadSuggestionsTask.perform();

    }

    // if influencing params have changed which now causes means these experiences
    // arent available, then reset all experiences and fetch them again
    if (this.compilationType === 'experiences' && !results.length && this.selectedExperiences.size > 0) {
      this.set('selectedExperienceNames', []);
      return this.loadSuggestionsTask.perform();
    }
  },

  retryLoadRoutesAndComplete(params) {

    if (params['selectedMonthNames'].length) {
      params['selectedMonthNames'].popObject();
      return this.loadRoutesAndComplete(params);
    }

    if (params['selectedExperienceNames'].length) {
      params['selectedExperienceNames'].popObject();
      return this.loadRoutesAndComplete(params);
    }

    if (params['selectedCountryNames'].length) {
      params['selectedCountryNames'].popObject();
      return this.loadRoutesAndComplete(params);
    }

    return this.loadRoutesAndComplete(params);

  },

  loadRoutesAndComplete(queryParams) {

    this.set('matchingRoutes', null);

    if (!queryParams) {
      queryParams = {};

      // for some reason if a continent is nt selected it passes through a query with [null] as selectedContinentNames which breaks server logic
      if (this.selectedContinentName && !(this.selectedContinentName=='All')) {
        queryParams['selectedContinentNames'] = [this.get('selectedContinentName')];
      }
      queryParams['selectedCountryNames'] = [this.get('selectedCountryName')];
      queryParams['selectedMonthNames'] = [this.get('selectedMonthName')];
      queryParams['selectedExperienceNames'] = this.get('selectedExperienceNames');
    }

    this.set('loadingRoutes', true);

    return this.get('store').query('tripSummary',
      queryParams
    ).then((results) => {

      if (Ember.isEmpty(results)) {
        return this.retryLoadRoutesAndComplete(queryParams);
      }

      this.set('matchingRoutes', results);
      this.set('loadingRoutes', false);
      return this.completeWizard();

    });

  },

  randomRoutes(routesList) {
    let randomRoutes = [];
    let i = 0;

    if (routesList.length < this.maxResults + 1) {
      return routesList
    }

    while (i < this.maxResults) {
      let randomRoute = routesList.objectAt(Math.floor(Math.random() * routesList.length));
      if (randomRoutes.indexOf(randomRoute) === -1) {
        randomRoutes.pushObject(randomRoute);
        i++;
      }
    }
    return randomRoutes;
  },

  @computed('matchingRoutes.[]', 'selectedExperienceNames')
  electedRoutes(matchingRoutes, selectedExperienceNames) {
    if (!Ember.isEmpty(matchingRoutes)) {
      let filteredRoutes = [];

      if (selectedExperienceNames && selectedExperienceNames.includes('Off the beaten track')) {
        // all trips
        filteredRoutes = matchingRoutes;
      } else {
        // filter out otbt trips for inexperienced users
        matchingRoutes.forEach((route) => {
          if (!route.get('metaExperiences').mapBy('name').includes("Off the beaten track")) {
            filteredRoutes.pushObject(route);
          }
        });
        // however if removing the OTBT trips means we don't have enough results then just inlcude the OTBT trips
        if (filteredRoutes.length < this.maxResults - 1) {
          filteredRoutes = matchingRoutes;
        }
      }

      return this.randomRoutes(filteredRoutes);
    }
  },

  completeWizard() {
    trackEvent('wizard:completing');
    captureEmail(this.email, this.router.get('currentRouteName'), 'wizard', this.firstName);

    let trip_ids = this.electedRoutes.map((route) => {
      return route.id
    });


    let submissionParams = {
      goAnytime: this.goAnytime,
      expertShouldContact: this.expertShouldContact,
      preferedPartner: this.preferedPartner,
      planningStage: this.planningStage,
      expertShouldContact: this.expertShouldContact,
      selectedCountryNames: [this.selectedCountryName],
      // We want to ensure that there is selectedExperienceName so code that depends on it later doesn't fall over
      selectedExperienceNames: this.selectedExperienceNames || [],
      selectedMonthNames: [this.selectedMonthName],
      preferedYear: this.preferedYear,
      trip_ids: trip_ids, //This is duplicated on the wizard model
      wizardRules: {
        personalisedFirstTrip: false,
        hasMoreResults: (this.matchingRoutes.length > 3)
      }
    }

    if (this.selectedContinentName != 'All') {
      submissionParams['selectedContinentNames'] = [this.selectedContinentName];
    }

    let submission = this.store.createRecord('wizard-submission', {
      email: this.email,
      firstName: this.firstName,
      trips: this.get('electedRoutes'), //Please note this is doubled up into the submissions.. just to make it easy to track
      submissions: submissionParams
    });
    this.set('wizardSubmission', submission);

    this.get('wizardSubmission').save().then((savedWizard) => {
      trackEvent('wizard:completed');
      window.timbuktu.reportConversions.reportWizardLead();

      if (!this.wizardSubmission.get('trips')) {
        this.get('flashMessages').danger("Sorry, we couldn't find any matching trips based on your selection.");
        trackEvent('wizard:noResult');
      }

      if (this.expertShouldContact) {
        let enquiry = this.setUpEnquiry(savedWizard);

        enquiry.save().then(() => {
          trackEvent('wizard:enquire:complete', {location: 'wizard-flow'});
        })

        this.set('expertShouldContact', null);
        this.set('enquiryMessage', null);
        this.set('enquiryPhoneNumber', null);
      }

      this.get('router').transitionTo('wizard.success', this.get('wizardSubmission'), {
        queryParams: {
          fromFinalStep: true
        }
      });
      this.set('completing', false);
    }).catch((serverResponse) => {
      this.set('completing', false);
      this.set('wizardSubmission', null);
      this.set('errors', getErrorsHashFromServerResponse(serverResponse));
    });
  },

  setUpEnquiry(savedWizard){
    var email = this.email ? this.email : this.session.get('currentUser.email');
    var firstName = this.firstName ? this.firstName : this.session.get('currentUser.name');

    var enquiry = this.store.createRecord('enquiry', {
      email: email,
      firstName: firstName,
      phoneNumber: this.enquiryPhoneNumber,
      startMonth: this.selectedMonthName,
      startYear: this.preferedYear,
      user: this.session.get('currentUser'),
      message: this.enquiryMessage,
      destination: this.destination,
      geoCode: getCookieValue('Timbuktu-Country-Code'),
      enquiryType: 'wizard',
      wizardSubmission: savedWizard,
      moreInfo: this.moreInfo
    });

    return enquiry;
  },

  calledByCompleteWizardAction() {
    if (this.get('completing')) {
      return;
    }

    this.set('completing', true);

    this.loadRoutesAndComplete();
  },
  setCountries() {
    // This array is duplicated in trip/index/editor
    let excludedCountries = [
      'Lesotho',
      'Sao Tome and Principe Island',
      'Swaziland',
      'Central African Republic',
      'Chad',
      'Republic of the Congo',
      'Democratic Republic of Congo'
    ];
    // DRC excluded because of unrest
    // pick country based on continent selected in step 1
    let filteredCountries = this.cache.getAllPublishedCountries().reject((item) => {
      return excludedCountries.includes(item.name) || (this.selectedContinentName && this.selectedContinentName!='All' && item.continentName != this.selectedContinentName);
    })

    this.set('allCountries', filteredCountries)

  },

  actions: {
    goToNextStep(options = {} /* skipNextStep, complete=show routes clear=property to reset*/ ) {
      if (options.clear) {
        switch (options.clear) {
          case 'months':
            this.set('selectedMonthName', undefined);
            break;
          case 'country':
            this.set('selectedCountryName', undefined);
            break;
          case 'goAnytime':
            this.set('goAnytime', null);
            break;
          default:
            break;
        }
      }

      if (options.complete) {
        if (this.get('completing')) {
          return;
        }
        this.set('completing', true);

        this.loadRoutesAndComplete();
        return;
      }

      if (!this.finalStepAndLoggedIn && this.nextStep) {
        run.later(() => {
          this.router.transitionTo('wizard.step', options.skipNextStep ? (this.nextStep + 1) : this.nextStep);
        }, this.NEXT_STEP_TIMEOUT);
      }
    },

    clearMonths() {
      this.set('selectedMonthName', undefined);
      this.send('goToNextStep')
    },
    selectMonth() {
      this.set('goAnytime', null);
      this.send('goToNextStep')
    },

    clearCountry() {
      this.set('selectedCountryName', undefined);
    },

    completeWizard() {
      return this.calledByCompleteWizardAction();
    },
    setContinent(tag) {
      this.set('selectedContinentNames', [tag.name]);
      this.set('selectedContinentName', tag.name);
      this.setCountries();
    },
  }
});
