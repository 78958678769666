import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import Mixin from '@ember/object/mixin';

var transitionOverrides = Mixin.create({

  locationScrollHistory: service(),
  entityModals: service(),

  afterModel(model,transition) {
    this._super(...arguments);
    let staticRoutes = ['country.index.index', 'region.index.index', 'experience.index', 'lodge.index']
    let anonRoutesAllowed = ['index', 'join', 'login']
    let transitionTarget = transition.targetName;
    console.log(transitionTarget)
    if (this.whitelabel.agency.useStaticPages && staticRoutes.includes(transitionTarget)) {
      // transition.abort();
      // location.reload();
    } else if (!(this.whitelabel.agency.anonContent || anonRoutesAllowed.includes(transitionTarget) || (transitionTarget.indexOf('trip.') > -1))) {
      return this.session.currentUserPromise.then(()=> {
        if (!this.get('session.currentUser.isAgent')) {
          // transition.abort();
        }
      })
    }
  },

  actions: {
    willTransition(transition) {
      // Call willTransition on super so all mixins get a chance at willTransition
      this._super(...arguments);
      this.locationScrollHistory.onWillTransition(transition);
      this.entityModals.closeModal();
      // return true so willTransition bubbles
      return true;

    },

    didTransition() {
      // console.log(`diTrans: ${this.fullRouteName}`)
      this._super(...arguments);
      this.locationScrollHistory.onDidTransition(this.fullRouteName);
      return true; // Bubble the didTransition event!
    }
  }

});

export function initialize() {

  if (typeof FastBoot !== 'undefined') {
    return;
  }

  window.onpopstate = function(event) {
    if (event.state) {
      window.timbuktu.popStateTarget = event.state.path;
    }
  };
  Route.reopen(transitionOverrides);
}

export default {
  name: 'transition-actions',
  initialize: initialize
};
