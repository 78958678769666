import {
  alias
} from '@ember/object/computed';

import DS from 'ember-data';

export default DS.Model.extend({
  name: alias('country.name'),
  note: DS.attr(),
  noteTitle: DS.attr(),
  highlights: DS.attr(),
  wildlifeIntro: DS.attr(),
  seasonalRating: DS.attr(),
  bestTimeToGo: DS.attr(),
  gettingThere: DS.attr(),
  health: DS.attr(),
  visas: DS.attr(),
  overview: DS.attr(),
  overviewWhyLove: DS.attr(),
  overviewOffBeatenTrack: DS.attr(),
  overviewThinkAbout: DS.attr(),
  overviewLuxury: DS.attr(),
  whatToPack: DS.attr(),
  faqMoney: DS.attr(),
  faqTravelInsurance: DS.attr(),
  whenToGo: DS.attr(),

  overviewTravelRequirements: DS.attr(),
  covidLinks: DS.attr(),
  faqs: DS.attr(),

  countriesWildlives: DS.hasMany('countriesWildlives', {
    async: false
  }),
  country: DS.belongsTo('country', {
    async: false
  }),
  countryImages: DS.attr(),
  images: alias('countryImages'),

  wildlives: alias('countriesWildlives')
});
