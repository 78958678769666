import DS from 'ember-data';
import {
  memberAction
} from 'ember-api-actions';
import {
  not
} from 'ember-decorators/object/computed';

export default DS.Model.extend({

// NB!!!! Remember that you need to add these attributes to the code on the server that injects the relevant agency base don requst domain.
// You can't just add to the serializer and model

  name: DS.attr('string'),
  profileImageUrl: DS.attr('string'),
  darkProfileImageUrl: DS.attr('string'),
  primaryColour: DS.attr('string'),
  termsAndConditionsUrl: DS.attr('string'),
  usesMargin: DS.attr('boolean'),
  needsOnboarding: DS.attr('boolean'),
  disabled: DS.attr('boolean'),
  navBgColour: DS.attr('string'),
  navFontColour: DS.attr('string'),
  paymentPlatform: DS.attr('string'),
  waybirdFeePercentage: DS.attr('string'),
  profileImageWidth: DS.attr(),
  meta: DS.attr(),
  isSupplierAccount: DS.attr(),
  agentRegistered: DS.attr(),
  scheduleHeadingTripIdeas: DS.attr(),
  scheduleHeadingQuotes: DS.attr(),
  defaultLayout: DS.attr(),
  disableDefaultPredeparture: DS.attr(),

  anonContent: DS.attr(),
  @not('anonContent') anonInModals: null,
  useStaticPages: true,

  users: DS.hasMany('user'),
  contractingEntities: DS.hasMany('contractingEntity'),
  domain: DS.attr(), //The domain is not actually supplied when you retrieve a url. It is just supplied when creating an agency for the first time
  generalEmail: DS.attr('string'),

  requestOnboarding: memberAction({
    path: 'request_onboarding'
  }),

  allowDirectBankTransferPayments: DS.attr('boolean'),
  allowBankTransferPayments: DS.attr('boolean'),
  allowCreditCardPayments: DS.attr('boolean'),



});
