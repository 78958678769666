import MapListing from "b5b/components/map-listing/component";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency";
import { run } from "@ember/runloop";
import { or } from "ember-decorators/object/computed";
import { getSortTitle } from "b5b/utils";
import { alias } from "@ember/object/computed";

export default MapListing.extend({
  tagName: "",

  ui: service(),
  screen: service(),
  scroll: service(),
  messageBus: service(),
  xplorer: service("components/x-plorer"),
  pillsService: service("pills"),

  mapOnlyViewTitle: "Experiences",

  showMap: false,
  selectedPills: alias("pillsService.selectedPills"),

  @or("xplorer.isLoading", "loadExperiencesTask.isRunning")
  experiencesLoading: false,

  init() {
    this._super(...arguments);
    this.get("messageBus").subscribe("pill-added", this, this.reload);
    this.get("messageBus").subscribe("pill-removed", this, this.reload);
    this.get("messageBus").subscribe("suggestions-reset", this, this.reload);
    this.get("messageBus").subscribe("suggestions-refresh", this, this.reload);
  },

  didInsertElement() {
    // this._super(...arguments);
    this.setupResizeListener();
    this.setupScrollSync();
  },

  reload() {
    this.get("loadExperiencesTask").perform();
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.loader);
    this.teardownScrollSync();
    this.teardownResizeListener();
  },

  setupScrollSync() {
    const mapContainer = document.getElementById("map-container");
    const listContainer = document.getElementById("experiences-list-container");

    if (mapContainer && listContainer) {
      this._scrollHandler = (event) => {
        if (mapContainer.matches(":hover")) {
          const atTop = listContainer.scrollTop === 0;
          const atBottom =
            listContainer.scrollTop + listContainer.clientHeight >=
            listContainer.scrollHeight;

          if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
            return;
          }
          listContainer.scrollTop += event.deltaY;
          event.preventDefault();
        }
      };

      mapContainer.addEventListener("wheel", this._scrollHandler, {
        passive: false,
      });
    }
  },

  teardownScrollSync() {
    const mapContainer = document.getElementById("map-container");
    if (mapContainer && this._scrollHandler) {
      mapContainer.removeEventListener("wheel", this._scrollHandler);
    }
  },

  setupResizeListener() {
    this._resizeHandler = () => {
      if (window.innerWidth >= 1024) {
        this.set("showMap", false);
      }
    };
    window.addEventListener("resize", this._resizeHandler);
  },

  teardownResizeListener() {
    if (this._resizeHandler) {
      window.removeEventListener("resize", this._resizeHandler);
    }
  },

  actions: {
    scrollToMap() {
      run.next(() => {
        if (this.get('scrollToMap')) {
          this.get('scrollToMap')();
        }
      });
    },

    setPolishLevel(val) {
      this.set("polishLevel", val);
      if (val === "custom" || val === "personal") {
        this.set("sort", "created_at_desc");
      } else {
        this.set("polishLevel", null);
        this.set("sort", "priority_asc");
      }
      this.set("settings.searchMode", "destinationChange");
      this.get("loadExperiencesTask").perform();
    },

    setSort(sort) {
      this.set("sort", sort);
      this.set("page", 1);
      this.messageBus.publish("suggestions-refresh");
    },

    toggleView() {
      this.set("showMap", !this.get("showMap"));

      if (this.get("showMap")) {
        run.next(() => {
          if (this.get('refreshMap')) {
            this.get('refreshMap')();
          }
        });
      }
    },
  },

  sortTitle: Ember.computed("sort", function () {
    return getSortTitle(this.get("sort"));
  }),
});
