import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({

  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('region');
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('region:when-to-go:view');

    controller.set('region', model);
  }


});
