import { inject as service } from "@ember/service";
import Component from "@ember/component";
import { computed } from "ember-decorators/object";
import { set } from '@ember/object';

export default Component.extend({
  classNames: ["no-results-block"],

  ui: service(),
  router: service(),

  routesHint: null,
  mapsHint: null,
  store: service(),

  init() {
    this._super(...arguments);
    this.set("experts", []);
    this.fetchExperts();
  },

  async fetchExperts() {
    try {
      const experts = await this.store.query("user", {
        is_travel_specialist: true,
        buster: true,
        agency_id: 7,
      });
      const firstThreeExperts = experts.slice(0, 3);
      set(this, "experts", firstThreeExperts);
    } catch (error) {
      console.error("Error fetching experts:", error);
    }
  },

  // THis is not integrated with custom-trip-params
  @computed(
    "router.currentURL",
    "selectedRegionNames.[]",
    "selectedLodgeNames.[]"
  )
  customTripParams(currentURL, selectedRegionNames, selectedLodgeNames) {
    let params = {};
    let routeName = "trip.new";

    if (selectedRegionNames) {
      params["selectedRegionNames"] = selectedRegionNames;
    }

    if (selectedLodgeNames) {
      params["selectedLodgeNames"] = selectedLodgeNames;
    }

    return [
      routeName,
      {
        isQueryParams: true,
        values: params,
      },
    ];
  },
});
