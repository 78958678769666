import Route from '@ember/routing/route';
import basicNav from 'b5b/mixins/basic-nav';
import {
  trackEvent
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';


export default Route.extend(basicNav, {

  tripService: service('trip'),

  setupController() {
    this._super(...arguments);
    trackEvent('ask:route');
    // set current trip to null or it causes double rendeing of the enquire component if coming via trip page
    this.set('tripService.currentTrip', null)
  },
  beforeModel(transition) {
    this._super(...arguments);

    if (this.get('whitelabel.isForAgency')) {
      transition.abort();
    }
  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      controller.set('destination', null)
    }


  }

});
