import {
  isEmpty
} from '@ember/utils';
import {
  inject as controller
} from '@ember/controller';
import Mixin from '@ember/object/mixin';
import MapSearchingControllerMixin from 'b5b/mixins/map-searching/controller';
import {
  task
} from 'ember-concurrency';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';
import {
  mapBy,
  alias,
  reads
} from 'ember-decorators/object/computed';
import {
  trackEvent,
  maxLodgePriceForRange
} from 'b5b/utils';
import {
  run
} from '@ember/runloop';

export default Mixin.create(MapSearchingControllerMixin, {

  store: service(),
  scroll: service(),
  entityModals: service(),
  messageBus: service(),
  ui: service(),

  mapSearchEnabled: true,

  fastboot: service(),
  @reads('fastboot.isFastBoot') isFastBoot: false,

  tripService: service('trip'),
  @alias('tripService.hasBackButton') hasBackButton: false,
  @alias('tripService.currentTrip') trip: false,
  @alias('tripService.currentStageIndex') stageIndex: false,
  @alias('tripService.currentStage') currentStage: false,

  @alias('tripService.lodgeStartDate') startDate: null,
  @alias('tripService.lodgeEndDate') endDate: null,

  xplorer: service('components/x-plorer'),
  // if you don;t alias the entities like this then pagination wont work
  @alias('xplorer.lodges') lodges: null,


  applicationController: controller('application'),
  @alias('applicationController.mapOnlyView') mapOnlyView: null,
  @alias('applicationController.filterOnlyView') filterOnlyView: null,

  searchValue: '',
  selectedContinentNames: [],
  selectedCountryNames: [],
  selectedRegionNames: [],
  selectedAreaNames: [],
  selectedMonthNames: [],
  selectedExperienceNames: [],
  selectedLodgeStyleNames: [],
  selectedLodgeNames: [],
  filters: [],
  additionalFilter: null,
  selectedBudget: [0, maxLodgePriceForRange()],
  selectedAutoBudgetNames: [],
  sort: 'priority_asc',
  showOnlyAvailable: false,
  polishLevel: 'default',
  showOnlyWithOffers: false,
  exclusiveUseOnly: false,

  unexplored: undefined,
  mouseOverLodge: undefined,
  changingLodge: false,

  page: 1,
  per_page: 18,
  paginationPageCount: 3,

  firstTimeLoad: true,
  clearValue: false,

  queryParams: [{
    searchValue: {
      as: 'q'
    },
    selectedCountryNames: {
      as: 'countries'
    },
    selectedRegionNames: {
      as: 'regions'
    },
    selectedAreaNames: {
      as: 'areas'
    },
    selectedExperienceNames: {
      as: 'experiences'
    },
    selectedLodgeStyleNames: {
      as: 'styles'
    },
    selectedBudget: {
      as: 'budget'
    },
    selectedLodgeNames: {
      as: 'lodges'
    },
    selectedAutoBudgetNames: {
      as: 'autoBudget'
    },
    page: {},
    changingLodge: {},
    unexplored: {},
    showOnlyAvailable: {},
    showOnlyWithOffers: {},
    sort: {},
    startDate: {},
    endDate: {},
    exclusiveUseOnly: {},
    filters: {},
    polishLevel: {}
  }],

  @mapBy('trip.itinerary.stages', 'lodge') selectedLodges: null,

  @computed('trip.itinerary.stages.[]')
  stages(stages) {
    if (!isEmpty(stages)) {
      return stages.rejectBy('stageType', 'add-on');
    }
    return [];
  },

  @computed('lodges.[]', 'currentStage')
  lodgesToShowOnMap(lodges, currentStage) {
    if (currentStage && lodges) {
      lodges = lodges.toArray().concat(currentStage.get('lodge'))
    }
    return lodges
  },

  @computed('trip.itinerary.nonTransferStages.@each.entity')
  polyLineLocations(stages) {
    if (!stages){
      return [];
    }

    stages = stages.filter(function(stage) {
      if (stage.mapLocation) {
        return true;
      }
    })

    var lines = [];
    for (var i = 0; i < stages.length - 1; i += 1) {
      lines.push(
        [stages.objectAt(i).get('mapLocation'), stages.objectAt(i + 1).get('mapLocation')]
      );
    }
    return lines;
  },

  /*
    Map searching below
  */

  @alias('lodges') entities: null, // for map searching

  onMapPositionChanged() {
    this.loadLodgesTask.perform();
    this.get('messageBus').publish('listing-map-position-changed');
  },

  loadLodgesTask: task(function*() {
    this.loader = run.next(() => {
      // this.get('scroll').to(0);
    });

    if (this.searchMode == 'destinationChange') {
      this.resetLatLng();
    }
    let lodges = yield this.get('xplorer').loadRecords('lodges', {skipSettingsRecords: true});
    this.set('lodges', lodges)
    this.entitiesLoaded();
  }).keepLatest(),

  refreshMapTask: task(function* () {
    yield new Promise((resolve) => run.next(resolve));
    const mapInstance = this.get("mapInstance");
    if (mapInstance) {
      mapInstance.invalidateSize(true);

      yield new Promise((resolve) => run.next(resolve));
      if (this.get("entities.length") && this.get("entityBounds")) {
        this.fitMapToEntityBounds("loading");
      } else if (this.get("zoom") && this.get("center")) {
        mapInstance.setView(this.get("center"), this.get("zoom"));
      } else {
        const defaultCenter = L.latLng(0, 0);
        const defaultZoom = 2;
        mapInstance.setView(defaultCenter, defaultZoom);
      }
    }
  }).keepLatest(),

  scrollToMapTask: task(function*() {
    yield new Promise(resolve => run.next(resolve));

    const mapContainer = document.getElementById("map-container");

    if (mapContainer) {
      const mapOffsetTop = mapContainer.getBoundingClientRect().top + window.pageYOffset;
      const viewportHeight = window.innerHeight;
      const mapHeight = mapContainer.offsetHeight;

      let targetScrollPosition;

      if (mapHeight > viewportHeight * 0.8) {
        targetScrollPosition = mapOffsetTop - 120;
      } else {
        targetScrollPosition = mapOffsetTop - (viewportHeight - mapHeight) / 2;
        if (viewportHeight < 800) {
          targetScrollPosition -= 100;
        } else {
          targetScrollPosition -= 50;
        }
      }

      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    } else {
      this.get("scroll").to(0);
    }
  }).drop(),


  actions: {

    openMapPopup(marker) {
      trackEvent('lodges-list-map:open-map-popup');
      this.set('popupOpen', true);

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(0);
    },

    closeMapPopup(marker) {
      this.set('popupOpen', false);

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(1);
    },

    mouseOverStage(lodge) {
      this.set('mouseOverLodge', lodge)
    },

    refreshMap() {
      return this.get("refreshMapTask").perform();
    },

    scrollToMap() {
      return this.get("scrollToMapTask").perform();
    },
  }

});
