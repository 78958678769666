import Component from '@ember/component';
import { run } from '@ember/runloop';

export default Component.extend({
  didInsertElement() {
    this._super(...arguments);
    run.scheduleOnce('afterRender', this, this.setupClickHandler);
  },

  willDestroyElement() {
    this._super(...arguments);
    const linkElement = this.element.querySelector('a');
    if (linkElement) {
      linkElement.removeEventListener('click', this.handleClick);
    }
  },

  setupClickHandler() {
    const linkElement = this.element.querySelector('a');
    if (linkElement) {
      this.handleClick = this.handleClick.bind(this);
      linkElement.addEventListener('click', this.handleClick);
    }
  },

  handleClick(event) {
    event.preventDefault();

    const href = event.currentTarget.getAttribute('href');
    if (!href || !href.startsWith('#')) return;

    const targetId = href.substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      });
    }
  }

});
