import Service, {
  inject as service
} from '@ember/service';
import {
  formatCurrency
} from 'b5b/helpers/format-currency';
import {
  getDurationForKey,
  TRIP_IDEAS_BUDGET_MAPPING,
  monthNameFromNumber,
  yearNameFromNumber
} from 'b5b/utils';


import {
  alias
} from 'ember-decorators/object/computed';

export default Service.extend({

  selectedPills: [], // global cache!
  suggestionsCompilation: null, // global cache - records backing pills

  activeType: null, // set on route - entity type

  settings: service(),
  messageBus: service(),
  tripService: service('trip'),
  xplorer: service('components/x-plorer'),

  @alias('xplorer.selectedContinentNames') selectedContinentNames: null,
  @alias('xplorer.selectedCountryNames') selectedCountryNames: null,
  @alias('xplorer.selectedAreaNames') selectedAreaNames: null,
  @alias('xplorer.selectedRegionTypeNames') selectedRegionTypeNames: null,
  @alias('xplorer.selectedExperienceNames') selectedExperienceNames: null,
  @alias('xplorer.selectedLodgeStyleNames') selectedLodgeStyleNames: null,
  @alias('xplorer.selectedTripLength') selectedTripLength: null,
  @alias('xplorer.selectedTripLengths') selectedTripLengths: null,
  @alias('xplorer.selectedLodgeNames') selectedLodgeNames: null,
  @alias('xplorer.selectedMonthNames') selectedMonthNames: null,
  @alias('xplorer.selectedBudget') selectedBudget: null,
  @alias('xplorer.recurringDateRange') recurringDateRange: null,
  @alias('xplorer.startDateRange') startDateRange: null,
  @alias('xplorer.selectedAutoBudgetNames') selectedAutoBudgetNames: null,
  @alias('xplorer.selectedMonth') selectedMonth: null,
  @alias('xplorer.showOnlyAvailable') showOnlyAvailable: null,
  @alias('xplorer.showOnlyWithOffers') showOnlyWithOffers: null,
  @alias('xplorer.exclusiveUseOnly') exclusiveUseOnly: null,
  @alias('xplorer.isPopular') isPopular: null,
  @alias('xplorer.startDate') startDate: null,
  @alias('xplorer.endDate') endDate: null,
  @alias('xplorer.polishLevel') polishLevel: null,
  @alias('xplorer.filters') filters: null,

  minPriceForRange: null, // set on controller, eg lodges/trips
  maxPriceForRange: null,

  init() {
    this._super(...arguments);
    this.messageBus.subscribe('suggestion-added', this, this.selectResult);
    this.messageBus.subscribe('suggestion-removed', this, this.removeSelection);
    this.messageBus.subscribe('suggestions-reset', this, this.externalReset);
    this.messageBus.subscribe('suggestions-refresh', this, this.populatePills);
  },


  /*
    These two functions are shared and used as a funnel for common logic, like checking the primary suggestion
  */
  _pushPill(values = {}) {

    let newPill = {
      name: values.name,
      type: values.type,
      id: values.id
    }

    if (values.key) {
      newPill['key'] = values.key;
    }
    console.log('_pushPill', newPill)

    this.selectedPills.pushObject(newPill);
  },

  _popPill(suggestion) {
    this.selectedPills.removeObject(suggestion);
  },

  populatePills() {
    this._super(...arguments);
    // console.log('pills service populatePills')

    this.set('selectedPills', []);

    if (this.filters) {
      this.filters.forEach((filter) => {
        console.log(filter)
        this._pushPill(filter);
      });
    }

    if (this.selectedLodgeStyleNames) {
      this.selectedLodgeStyleNames.forEach((lodgeStyleName) => {
        this._pushPill({
          name: lodgeStyleName,
          type: 'lodge-style'
        });
      });
    }

    if (this.selectedRegionTypeNames) {
      this.selectedRegionTypeNames.forEach((regionType) => {
        this._pushPill({
          name: regionType,
          type: 'region-type'
        });
      });
    }

    if (this.selectedMonthNames) {
      this.selectedMonthNames.forEach((monthName) => {
        this._pushPill({
          name: monthName,
          type: 'month'
        });
      });
    }

    if (this.selectedTripLengths && (this.get('selectedTripLengths.length') > 0)) {
      this.selectedTripLengths.forEach((durationKey) => {
        let duration = getDurationForKey(durationKey);
        this._pushPill({
          name: duration.name,
          type: 'duration',
          key: duration.key
        });
      });
    }

    if (this.selectedBudget && (this.get('selectedBudget.length') > 0) && (!(parseInt(this.selectedBudget[0]) <= parseInt(this.minPriceForRange())) || this.selectedBudget[1].toString() !== this.maxPriceForRange().toString())) {
      let name = this.getBudgetPillNameForPriceRange(this.selectedBudget);
      this._pushPill({
        name: name,
        type: 'budget',
        key: this.selectedBudget
      });
    }

    if (this.recurringDateRange && (this.get('recurringDateRange.length') > 0) && (this.recurringDateRange[0] != 'any' || this.recurringDateRange[1] != 'any' || this.recurringDateRange[2] != 'any' || this.recurringDateRange[3] != 'any')) {
      let name = this.getPillNameForRecurringDateRange(this.recurringDateRange);
      this._pushPill({
        name: name,
        type: 'recurring-date-range',
        key: this.recurringDateRange
      });
    }
    console.log(this.startDateRange)
    if (this.startDateRange && (this.get('startDateRange.length') > 0) && (this.startDateRange[0] != 'any' || this.startDateRange[1] != 'any')) {
      let name = this.getPillNameForStartDateRange(this.startDateRange);
      this._pushPill({
        name: name,
        type: 'start-date-range',
        key: this.startDateRange
      });
    }


    if (this.primarySpecialist) {
      let name = this.primarySpecialist.name;
      this._pushPill({
        name: name,
        type: 'primary-specialist',
        key: this.primarySpecialist.id
      });
    }

    if (this.selectedAutoBudgetNames && (this.get('selectedAutoBudgetNames.length') > 0)) {
      this.selectedAutoBudgetNames.forEach((autoBudget) => {
        this._pushPill({
          name: this.autoBudgetMapping[autoBudget],
          type: 'auto-budget'
        });
      });
    }

    // Disabled for now - just not showing it
    if (this.showOnlyAvailable) {
      this._pushPill({
        name: 'Available only',
        type: 'availability'
      });
    }

    if (this.showOnlyWithOffers) {
      this._pushPill({
        name: 'Special offers',
        type: 'special-offers'
      });
    }

    if (this.exclusiveUseOnly) {
      this._pushPill({
        name: 'Exclusive use',
        type: 'exclusive-use'
      });
    }

    if (this.isPopular) {
      this._pushPill({
        name: 'Popular',
        type: 'popular'
      });
    }

    if (this.startDate && this.endDate) {
      this.set('tripService.startDateForPricingAndAvailability', new Date(this.startDate));
      this.set('tripService.endDateForPricingAndAvailability', new Date(this.endDate));

      this._pushPill({
        name: moment(this.startDate).format('D MMM') + ' - ' + moment(this.endDate).format('D MMM'),
        type: 'dates'
      });
    }

  },

  selectResult(result, type, key) {
    this._super(...arguments);
    console.log('selectResult', arguments)

    if (result) {
      let name = !!result.get && !!result.get('name') ? result.get('name') : result.name ? result.name : result;

      let includeSuggestion = true;

      if (['region', 'country', 'lodge', 'experience', 'continent', 'area', 'text', 'primary-specialist'].includes(type)) {
        console.log('will add filter', arguments)
        this.set('settings.searchMode', 'destinationChange');
        this.xplorer.addFilter(result)
      } else if (type === 'region-type') {
        if (this.selectedRegionTypeNames.indexOf(name) === -1) {
          this.selectedRegionTypeNames.pushObject(name);
        }
      } else if (type === 'lodge-style') {
        if (this.selectedLodgeStyleNames.indexOf(name) === -1) {
          this.selectedLodgeStyleNames.pushObject(name);
        }
      } else if (type === 'month') {
        if (this.selectedMonthNames.indexOf(name) === -1) {
          this.selectedMonthNames.pushObject(name);
        }
      } else if (type === 'duration') {
        let duration = getDurationForKey(key);
        name = duration.name;
        this.selectedTripLengths.pushObject(key);
      } else if (type === 'budget') {
        this.set('selectedPills', this.selectedPills.rejectBy('type', 'budget'));
        // We need to pass through the key here as the selectedBudget query param hasn't always updated by the time this is called
        name = this.getBudgetPillNameForPriceRange(result);
        if (result[0].toString() === this.minPriceForRange().toString() && result[1].toString() === this.maxPriceForRange().toString()) {
          includeSuggestion = false;
        }
      } else if (type === 'recurring-date-range') {
        this.set('selectedPills', this.selectedPills.rejectBy('type', 'recurring-date-range'));
        // We need to pass through the key here as the selectedBudget query param hasn't always updated by the time this is called
        name = this.getPillNameForRecurringDateRange(result);
        if (result[0]=='any' && result[1]=='any' && result[2]=='any' && result[3]=='any') {
          includeSuggestion = false;
        }
      } else if (type === 'start-date-range') {
        this.set('selectedPills', this.selectedPills.rejectBy('type', 'start-date-range'));
        // We need to pass through the key here as the selectedBudget query param hasn't always updated by the time this is called
        name = this.getPillNameForStartDateRange(result);
        if (result[0]=='any' && result[1]=='any') {
          includeSuggestion = false;
        }
      } else if (type === 'auto-budget') {
        if (this.selectedAutoBudgetNames.indexOf(name) === -1) {
          this.selectedAutoBudgetNames.pushObject(this.autoBudgetMapping[name]);
        }
      } else if (type === 'availability') {
        this.set('showOnlyAvailable', true);
      } else if (type === 'special-offers') {
        this.set('showOnlyWithOffers', true);
      } else if (type === 'exclusive-use') {
        this.set('exclusiveUseOnly', true);
      } else if (type === 'popular') {
        this.set('isPopular', true);
      } else if (type === 'dates') {
        this.set('selectedPills', this.selectedPills.rejectBy('type', 'dates'));

        this.set('tripService.startDateForPricingAndAvailability', new Date(result.start));
        this.set('tripService.endDateForPricingAndAvailability', new Date(result.end));

        name = moment(result.start).format('D MMM') + ' - ' + moment(result.end).format('D MMM');
        this._pushPill({
          name: name,
          type: 'dates'
        });
      }

      if (includeSuggestion && !this.selectedPills.findBy('name', name)) {
        this._pushPill({
          name: name,
          type: type,
          key: key,
          id: result.id
        });
      }
    }

    this.messageBus.publish('pill-added');
  },




  removeSelection(pill) {
    this._super(...arguments);

    if (['region', 'country', 'lodge', 'experience', 'continent', 'area', 'text', 'primary-specialist'].includes(pill.type)) {
      console.log(pill, this.filters)
      let matchingFilter = this.get('filters').find((filter)=> {
        return pill.id==filter.id && pill.type == filter.type;
      })
      this.filters.removeObject(matchingFilter);
      this.set('settings.searchMode', 'destinationChange')

      // Sadly all of our landing pages still use this old format when linking to our trips / lodges / experiences
      if (pill.type === 'continent' && this.selectedContinentNames && this.selectedContinentNames.includes(pill.name)) {
        this.selectedContinentNames.removeObject(pill.name);
      } else if (pill.type === 'country' && this.selectedCountryNames && this.selectedCountryNames.includes(pill.name)) {
        this.selectedCountryNames.removeObject(pill.name);
      } else if (pill.type === 'region' && this.selectedRegionNames && this.selectedRegionNames.includes(pill.name)) {
        this.selectedRegionNames.removeObject(pill.name);
      } else if (pill.type === 'area' && this.selectedAreaNames && this.selectedAreaNames.includes(pill.name)) {
        this.selectedAreaNames.removeObject(pill.name);
      } else if (pill.type === 'experience' && this.selectedExperienceNames && this.selectedExperienceNames.includes(pill.name)) {
        this.selectedExperienceNames.removeObject(pill.name);
      } else if (pill.type === 'lodge' && this.selectedLodgeNames && this.selectedLodgeNames.includes(pill.name)) {
        this.selectedLodgeNames.removeObject(pill.name);
      }

    } else if (pill.type === 'region-type') {
      this.selectedRegionTypeNames.removeObject(pill.name);
    } else if (pill.type === 'lodge-style') {
      this.selectedLodgeStyleNames.removeObject(pill.name);
    } else if (pill.type === 'month') {
      this.selectedMonthNames.removeObject(pill.name);
    } else if (pill.type === 'duration') {
      this.set('selectedTripLengths', this.selectedTripLengths.without(pill.key));
    } else if (pill.type === 'budget') {
      this.set('selectedBudget', [this.minPriceForRange(), this.maxPriceForRange()]);
    } else if (pill.type === 'recurring-date-range') {
      this.set('recurringDateRange', ['any', 'any', 'any', 'any']);
    } else if (pill.type === 'start-date-range') {
      this.set('startDateRange', ['any', 'any']);
    } else if (pill.type === 'auto-budget') {
      this.selectedAutoBudgetNames.removeObject(this.autoBudgetMapping[pill.name]);
    } else if (pill.type === 'availability') {
      this.set('showOnlyAvailable', false);
    } else if (pill.type === 'special-offers') {
      this.set('showOnlyWithOffers', false);
    } else if (pill.type === 'exclusive-use') {
      this.set('exclusiveUseOnly', false);
    } else if (pill.type === 'popular') {
      this.set('isPopular', false);
    } else if (pill.type === 'dates') {
      this.setProperties({
        startDate: null,
        endDate: null
      })
    }

    this.selectedPills.forEach((suggestion) => {

      if ((suggestion.name === pill.name && suggestion.type === pill.type && !suggestion.id) || (suggestion.id === pill.id && suggestion.type === pill.type)) {
        this._popPill(suggestion);
      }
    });

    this.messageBus.publish('pill-removed', pill);
  },

  getBudgetPillNameForPriceRange(priceRange) {
    // var minPrice = formatCurrency(priceRange[0], this.get('settings.currentCurrency'), 'usd', 10, true, true);
    // var maxPrice = formatCurrency(priceRange[1], this.get('settings.currentCurrency'), 'usd', 10, true, true);

    // not marking prices up for now, lodges only
    var minPrice = formatCurrency(priceRange[0], this.get('settings.currentCurrency'), 'usd', 10, true, false, false);
    var maxPrice = formatCurrency(priceRange[1], this.get('settings.currentCurrency'), 'usd', 10, true, false, false);
    var name = `${minPrice} - ${maxPrice}`;
    return name;
  },


  getPillNameForRecurringDateRange(recurringDateRange) {

    let name = `${monthNameFromNumber(recurringDateRange[0])}`
    if (recurringDateRange[1]!='any') {
      name = `${name} - ${monthNameFromNumber(recurringDateRange[1])}`
    }
    name = ` ${name} - ${yearNameFromNumber(recurringDateRange[2])}`
    if (recurringDateRange[3]!='any') {
      name = `${name} - ${yearNameFromNumber(recurringDateRange[3])}`
    }

    return name;
  },
  getPillNameForStartDateRange(startDateRange) {

    let name = ''
    if (startDateRange[0]!='any') {
      name = `From ${moment(startDateRange[0], 'YYYYMMDD').format('D MMM YYYY')}`
    }
    if (startDateRange[1]!='any') {
      name = name + ` ${startDateRange[0]!='any' ? 'until': 'Until'} ${moment(startDateRange[1], 'YYYYMMDD').format('D MMM YYYY')}`
    }

    return name;
  },



  autoBudgetMapping: TRIP_IDEAS_BUDGET_MAPPING,

  externalReset() {
    this._super(...arguments);
    this.setProperties({
      selectedPills: []
    });
  },

  actions: {
    removeSelection(pill) {
      this.removeSelection(pill);
    },

    selectResult(result, type) {
      this.selectResult(result, type);
    }
  }

});
