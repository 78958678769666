import {
  alias,
  or,
  equal
} from 'ember-decorators/object/computed';
import DS from 'ember-data';
import HasKodakImage from 'b5b/mixins/has-kodak-image';
import {
  computed
} from 'ember-decorators/object';
import {
  friendlyIdToSeoName
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';

export default DS.Model.extend(HasKodakImage, {
  friendlyId: DS.attr(),
  countryCode: DS.attr(),
  name: DS.attr('string'),
  continentName: DS.attr('string'),
  published: DS.attr(),
  publishedForWaybird: DS.attr(),
  sequence: DS.attr(),
  shortName: DS.attr('string'),
  stylisedMapUrl: DS.attr(),
  teaserText: DS.attr(),
  teaserDescription: DS.attr(),
  longitude: DS.attr('string'),
  latitude: DS.attr('string'),
  countryInfo: DS.belongsTo('countryInfo'),
  createdAt: DS.attr('b5bdate'),
  updatedAt: DS.attr('b5bdate'),
  publishedRegionsCount: DS.attr(),
  category: DS.attr(),

  defaultLocation: DS.belongsTo('location', {
    async: false,
    inverse: null
  }),

  isBordersOpen: DS.attr('boolean'),
  isQuarantineRequired: DS.attr('boolean'),


  headingWhyLove: DS.attr(),
  headingOffBeatenTrack: DS.attr(),
  headingThinkAbout: DS.attr(),
  headingLuxury: DS.attr(),
  headingWildlifeIntro: DS.attr(),
  headingH1: DS.attr(),
  pageTitle: DS.attr(),
  headingGettingThere: DS.attr(),
  headingHealth: DS.attr(),
  headingVisas: DS.attr(),
  headingWhatToPack: DS.attr(),
  headingFaqMoney: DS.attr(),
  headingFaqTravelInsurance: DS.attr(),
  metaDescription: DS.attr(),


  whitelabel: service(),

  @alias('countryInfo') destinationInfo: null,
  @alias('friendlyId') countryFriendlyId: null,
  @alias('latitude') latitudeWithFallback: null,
  @alias('longitude') longitudeWithFallback: null,
  @or('shortName', 'name') displayName: true,
  @or('published', 'publishedForWaybird') publishedForEither: true,
  // Note Safari is lowercase for countries but capitalised for regions
  @equal('category', 'safari') isSafari: true,

  @computed('friendlyId')
  seoName(friendlyId) {
    return friendlyIdToSeoName(friendlyId);
  },

  @computed('isSafari', 'name', 'headingH1')
  h1Name(isSafari, countryName, headingH1) {
    let title = countryName;
    if (this.whitelabel.isForTimbuktu && headingH1) {
      title = headingH1;
    } else if (this.whitelabel.isForTimbuktu) {
      if (isSafari) {
        title += ' Safari';
      }
      title += ' Trips & Tours';
    }
    return title;
  },

  @computed('latitude', 'longitude')
  mapMarker(latitude, longitude) {
    if (!latitude || !longitude) {
      return;
    }

    return [
      [parseFloat(longitude).toFixed(6), parseFloat(latitude).toFixed(6)]
    ];
  }

});
