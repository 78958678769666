import Component from '@ember/component';
import {
  inject as service
} from '@ember/service';
import {
  computed
} from 'ember-decorators/object';

export default Component.extend({

  // we re-generate this when preferred currency changes as stripe payment intents need to be re-calculated, also for instant book
  // We can just iterate over trip.itinerary.paymentsDue as when we create new charges, it causes trip.itinerary.paymentsDue to recalculate

  @computed('settings.currentCurrency')
  stablePaymentsList(currentCurrency) {
    return [...this.trip.itinerary.paymentsDue]
  },

});
