import DS from "ember-data";
import { computed } from "ember-decorators/object";
import { uniqBy } from "@ember/object/computed";
import { alias } from "ember-decorators/object/computed";
import moment from "moment";
import { getBrowserPriceHash } from "b5b/helpers/format-currency";
import ItineraryStates from "b5b/mixins/itineraries/itinerary-states";
import ItineraryProcessPath from "b5b/mixins/itineraries/itinerary-process-path";
import PricingModel from "b5b/mixins/itineraries/pricing-model";
import TripCarouselImages from "b5b/mixins/trip-carousel-images";
import { getSortedTripIdeas } from "b5b/utils";
import EmberObject from "@ember/object";

export default DS.Model.extend(
  ItineraryStates,
  ItineraryProcessPath,
  PricingModel,
  TripCarouselImages,
  {
    name: DS.attr(),
    teaserText: DS.attr(),
    teaserDescription: DS.attr(),
    isPopular: DS.attr(),
    friendlyId: DS.attr(),
    specialOfferTag: DS.attr(),
    isArchived: DS.attr(),
    userIds: DS.attr(),

    master: DS.attr("boolean"),
    isRoute: DS.attr("boolean"),
    isPackage: DS.attr("boolean"),
    isSpecial: DS.attr("boolean"),

    metaKodakOriginalUrl: DS.attr(),

    metaCoverStyle: DS.attr(),
    metaTripLength: DS.attr(),
    metaStageMapLocations: DS.attr(),
    metaRouteComboName: DS.attr(),
    metaNumNights: DS.attr(),
    metaNumGuests: DS.attr(),
    metaSpecialOfferInfo: DS.attr(),
    managingAgencyName: DS.attr(),

    regions: DS.hasMany("region"),
    uniqueRegions: uniqBy("regions", "id"),

    overrideKodak: DS.attr(),
    overrideSecondKodak: DS.attr(),
    overrideThirdKodak: DS.attr(),

    primaryManager: DS.belongsTo("user", {
      inverse: null,
    }),

    @computed("regions.[]")
    countries(regions) {
      return regions.mapBy("country").uniq();
    },

    @computed("managingAgencyName")
    fakeManagingAgency(managingAgencyName) {
      return { name: managingAgencyName };
    },

    @computed("regions.@each", "overrideKodak")
    heroEntity(regions, overrideKodak) {
      // NB: THIS IS DUPLICATED in the trip model
      // Please keep this in sync with the logic on server that allocates
      if (overrideKodak) {
        return EmberObject.create({
          kodakOriginalUrl: this.get("overrideKodak.originalUrl"),
          description: this.get("overrideKodak.description"),
          coverStyle: this.get("overrideKodak.coverStyle"),
        });
      }
      if (this.get("metaKodakOriginalUrl")) {
        return EmberObject.create({
          kodakOriginalUrl: this.get("metaKodakOriginalUrl"),
          coverStyle: this.get("metaCoverStyle"),
        });
      }

      if (regions.length > 0) {
        let secondDestinationRegion = regions.objectAt(1);
        let firstDestinationRegion = regions.objectAt(0);

        if (
          secondDestinationRegion &&
          secondDestinationRegion.get("kodakOriginalUrl")
        ) {
          return secondDestinationRegion;
        }
        return firstDestinationRegion.get("heroEntity");
      } else {
        return EmberObject.create({
          kodakOriginalUrl:
            "https://timbuktutravel.imgix.net/assets/images/destination-fallback/Desert_1.jpg",
          coverStyle: "center center",
        });
      }
    },

    metaExperiences: DS.attr(), // hash

    trip: DS.belongsTo("trip", { inverse: null }),

    metaTripIdeasSummary: DS.attr(),
    tripIdeaType: DS.attr(),

    @computed("metaTripIdeasSummary")
    sortedTripIdeasSummary(metaTripIdeasSummary) {
      return getSortedTripIdeas(metaTripIdeasSummary, "trip_idea_type", false);
    },

    @computed('sortedTripIdeasSummary.@each.estimate')
    fromPrice(sortedTripIdeasSummary) {
      let summary = sortedTripIdeasSummary.objectAt(0);
      if (summary) {
        return summary.estimate.from_prices;
      }
    },

    @computed("friendlyId", "id")
    tripFriendlyId(friendlyId, id) {
      return friendlyId || id;
    },

    /* Required for my-trip cards
  --------*/

    // References/Overrides for pricing-model
    @alias("metaPricingModel") pricingModel: null,

    // References/Overrides for itinerary-states
    @alias("metaItineraryState") state: null,

    // References/Overrides for process-path
    @alias("metaProcessPath") processPath: null,
    @alias("metaFlightsSentByGuest") flightsSentByGuest: null,
    @alias("metaAllGuestsPersonalDetailsComplete")
    allGuestsPersonalDetailsComplete: null,
    @alias("metaAllGuestsInsuranceDetailsComplete")
    allGuestsInsuranceDetailsComplete: null,
    @alias("metaItineraryConfirmed") itineraryConfirmed: null,
    @alias("metaBalanceSentByGuest") balanceSentByGuest: null,

    metaProcessPath: DS.attr(),
    metaFlightsSentByGuest: DS.attr(),
    metaAllGuestsPersonalDetailsComplete: DS.attr(),
    metaAllGuestsInsuranceDetailsComplete: DS.attr(),
    metaItineraryConfirmed: DS.attr(),
    metaBalanceSentByGuest: DS.attr(),

    metaItineraryState: DS.attr(),
    metaItineraryQuoteBill: DS.attr("object"),
    metaPricingModel: DS.attr(),

    metaQuoteValidUntil: DS.attr("b5bdate"),
    metaQuoteBalanceDueBy: DS.attr("b5bdate"),

    metaEstimate: DS.attr(),

    metaNumInfants: DS.attr(),
    metaNumChildren: DS.attr(),
    metaNumYoungAdults: DS.attr(),
    metaNumAdults: DS.attr(),
    metaNumTripViews: DS.attr(),
    metaLastViewed: DS.attr("b5bdate-time"),

    metaItineraryStartDate: DS.attr("b5bdate"),
    metaItineraryEndDate: DS.attr("b5bdate"),

    updatedAt: DS.attr("b5bdate"),

    @computed("metaItineraryEndDate")
    isPast(endDate) {
      if (endDate) {
        return moment(endDate).isBefore(moment.now());
      }
    },

    @computed("metaItineraryQuoteBill.prices.selling")
    totalSellingPriceHash(metaItineraryQuoteBill_prices_selling) {
      return getBrowserPriceHash(metaItineraryQuoteBill_prices_selling);
    },
  }
);
