import Component from '@ember/component';

export default Component.extend({
  classNames: ['hero-section'],

  // Properties passed in from the parent component
  imageUrl: null,
  title: null,
  subtitle: null,
  description: null,
  buttonText: null,

  actions: {
    onButtonClick() {
      this.onButtonClick();
    }
  }
});
