import {
  inject as service
} from '@ember/service';
import Component from '@ember/component';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  buildImgXUrl,
  convertUrlToAltText
} from 'b5b/utils';

export default Component.extend({

  tagName: 'img',

  classNameBindings: [':img-x', 'isActive', 'crop'],
  attributeBindings: ['src', 'srcset', '_sizes:sizes', 'altWithFallback:alt', 'style', 'width', 'height', 'loading'],

  screen: service(),
  rendering: service(),
  loading: 'lazy',

  didInsertElement() {
    this._super(...arguments);

    if (this.get('onInsert')) {
      this.get('onInsert')(this);
    }

    if (this.customBreakpoints) {
      this.set('breakpoints', this.customBreakpoints);
    } else {
      this.set('breakpoints', [1420, 1147, 900, 768, 550, 320, 100])
    }

    // If the image is smaller than the biggest breakpoint, we should skip those
    if (this.dimensions) {
      let dimensionsArray  = this.dimensions.split('x');
      this.set('dimensionsX',parseInt(dimensionsArray[0]))
      this.set('dimensionsY',parseInt(dimensionsArray[1]))
      if (this.dimensionsX <= this.breakpoints[0]) {
        let acceptableIndex = 0;
        this.breakpoints.every((size, index)=> {
          if (this.dimensionsX <= size) {
            // console.log(size, index)
            acceptableIndex = index
            return true
          } else {
            return false
          }
        })
        this.set('breakpoints', this.breakpoints.slice(acceptableIndex+1, this.breakpoints.length))
        this.breakpoints.unshift(this.dimensionsX)
      }

    }

    if (this.get('onLoad')) {
      this.set('isLoaded', false);
      this.set('_loadedboundLoaded', run.bind(this, '_loaded', this));
      this.element.addEventListener('load', this.get('_loadedboundLoaded'));
    }

    if (this.config.trackImageLoads && this.src) {
    // if (true) {
      // console.log('inserting image', this.src)
      this.rendering.imageRequested(this.src);
      let context = this;
      this.element.addEventListener('load', ()=> {
        context.rendering.imageLoaded(this.src)
      });
    }

  },

  isActive: false,

  path: null,

  w: null,
  h: null,

  auto: null,
  crop: null,
  fit: null,
  q: null, //%
  blend: null, // hex, eg. 000000
  balph: null, // %
  bm: null,
  mask: null,
  style: null,

  @alias('w') width: 0,
  @alias('h') height: 0,

  @computed('alt', 'path')
  altWithFallback(alt, path) {
    return alt || convertUrlToAltText(path);
  },


  // @computed('dimensionsX')
  // style(dimensionsX) {
  //   // return ''
  //   let maxWidth=null;
  //   if (dimensionsX) {
  //     maxWidth=`${dimensionsX}px;`
  //   // } else {
  //   //   maxWidth="100%"
  //   }
  //   if (maxWidth) {
  //     return Ember.String.htmlSafe(`max-width: ${maxWidth}`);
  //   }

  // },


  /*
    NOTES:
    - Could potentially setup some commonly used sets, eg. grid layouts
    - Chrome wont load lower res images as you downsize
  */

  breakpoints: null,

  isGridItem: false,

  sizes: null,

  @computed('isGridItem', 'sizes', 'w', 'h')
  _sizes(isGridItem, sizes, w, h) {
    if (sizes || w || h) {
      return sizes;
    }

    if (isGridItem) {
      /*
        - !Don't let this confuse you! -

        We have multiple determining factors here:
          1) window width
          2) dpr
          3) srcset breakpoints (w value)
          4) sizes media queries with default
          5) imgix size params
          6) css

        For example, with the following:
          sizes: (min-width: 1147px) 400px, (min-width: 768px) 100px, 250px
          breakpoints: [1420, 1147, 900, 768, 550, 320, 100]

        We set the imgix width the same as the breakpoint, so thats simple.
        The browser however looks at the window width, and screen dpr, then finds the matching
        sizes media query, and looks for the smallest, but highest res quality image.

        The sizes media query works backwards, top down, ending with a default.

        It works pretty straightfoward when images upsize fluidly, but in the case of a grid layout, the image
        changes from fullwidth on mobile, to halfish on tablet, to 3rdish on desktop.

        So if the sizes query matches mobile for example (ie. the default 250px) then it will look for the best
        matching breakpoint. In the options above it will be 550px, since the screen is dpr 2, it doubles the 250px to 500px
        before looking for a match.

      */

      return "(min-width: 1147px) 400px, (min-width: 768px) 450px, 400px";
    }
  },

  /*
    Used to calculate the height
    width * 0.6666  =  3 / 2 ratio
  */
  ratio: null,
  defaultRatio: 0.6666666, // set to 0 to mix portrait/landscape

  @computed('breakpoints', 'ratio', 'path', 'auto', 'crop', 'fit', 'q', 'blend', 'balph', 'bm', 'px', 'blur', 'w', 'h', 'defaultRatio', 'mask')
  src(breakpoints, ratio, path, auto, crop, fit, q, blend, balph, bm, px, blur, w, h, defaultRatio, mask) {


    if (!path) {
      // return console.log('Image without a path: ', this.get('alt'));
      return;
    }

    // If specific sizes are passed in, just return that size, eg. x-hero
    if (w || h) {
      return buildImgXUrl(path, w || null, h || null, auto, crop, fit, this.screen.dpr, q, blend, balph, bm, px, blur, mask);
    }

    var width = breakpoints && breakpoints[0] || null;

    return buildImgXUrl(path, width || null, Math.round(width * (ratio || defaultRatio)) || null, auto, crop, fit, 1, q, blend, balph, bm, px, blur, mask);
  },

  @computed('breakpoints', 'ratio', 'path', 'auto', 'crop', 'fit', 'q', 'blend', 'balph', 'bm', 'px', 'blur', 'defaultRatio', 'mask')
  srcset(breakpoints, ratio, path, auto, crop, fit, q, blend, balph, bm, px, blur, defaultRatio, mask) {

    if (!path) {
      // return console.log('Image without a path: ', this.get('alt'));
      return;
    }

    if (!breakpoints || this.get('w') || this.get('h')) {
      return;
    }

    var string = '';
// console.log(this.dimensions)
    breakpoints.forEach((size, index) => {
      let height = Math.round(size * (ratio || defaultRatio));
      // if (this.dimensions) {
      //   let dimensionsArray  = this.dimensions.split('x');

      //   if (parseInt(dimensionsArray[0]) < size) {
      //     return
      //   }
      // }
      string += buildImgXUrl(path, size, height, auto, crop, fit, 1, q, blend, balph, bm, px, blur, mask) + ' ' + size + 'w' + (index < breakpoints.length - 1 ? ',' : '');
    });

    return string;
  },

  onInsert: null,
  onLoad: null,
  isLoaded: false,

  _loaded(item) {

    if (this.get('isDestroying') || this.get('isDestroyed')) {
      return;
    }

    if (this.get('onLoad')) {
      this.get('onLoad')(item);
      this.set('isLoaded', true);
    }
  },

  willDestroyElement() {
    if (this.config.trackImageLoads && this.src) {
      this.rendering.imageLoaded(this.src);
    }
    if (this.get('onLoad')) {
      this.element.removeEventListener('load', this.get('_loadedboundLoaded'));
    }
  }

});
