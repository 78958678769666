import DS from 'ember-data';
import {
  mapBy,
  uniq,
  alias
} from 'ember-decorators/object/computed';
import {
  computed
} from 'ember-decorators/object';
import {
  addSecondCurrencyHashContentsIntoFirstHash,
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash,
  getBrowserPriceHash
} from 'b5b/helpers/format-currency';
import {
  inject as service
} from '@ember/service';

export default DS.Model.extend({

  settings: service(),
  tripService: service('trip'),

  bill: DS.attr('object'),
  markupPercentage: DS.attr(),
  marginPercentage: DS.attr(),
  depositPercentage: DS.attr(),
  projectedMargin: DS.attr(),
  updatedAt: DS.attr(),
  settlementCurrency: DS.attr(),
  sourceCountry: DS.attr(),
  sourceState: DS.attr(),
  useTimbuktuAutoMarkup: DS.attr('boolean'),
  overrideDepositPercentage: DS.attr(),

  itinerary: DS.belongsTo('itinerary', {
    async: false
  }),

  actualLineItems: DS.hasMany('actualLineItem', {
    async: false,
  }),

  discounts: DS.hasMany('discount', {async: false}),

  @mapBy('actualLineItems', 'currency') underlyingCurrencies: null,
  @uniq('underlyingCurrencies') uniqueCurrencies: null,
  @alias('itinerary.useNewPricingModel') useNewPricingModel: null,


  @computed('bill.discounts')
  hasDiscounts(bill_discounts) {
    if (bill_discounts) {
      return bill_discounts.length > 0;
    }
  },

  // THis is what still needs to be paid by guest EXCLUDING transaction fees. Contributes to the quote underlying currencies.
  @computed('bill')
  balanceDuePriceHash(bill) {
    if (bill) {
      let amountHash = getBrowserPriceHash(bill.get('prices.balanceDue'));
      return amountHash;
    }
  },

  @computed('itinerary.validCharges.@each.amount')
  totalChargesCurrencyHash(charges) {
    var hash = {}
    charges.forEach((charge) => {
      hash[charge.get('currency')] = (hash[charge.get('currency')] || 0) + charge.get('amount')
    });
    return hash;
  },

  @computed('bill.discounts')
  totalDiscountsCurrencyHash(bill_discounts) {
    if (bill_discounts) {
      var hash = {}
      bill_discounts.forEach((discount) => {
        if (discount.amount > 0) {
          hash[discount.currency] = (hash[discount.currency] || 0) + discount.amount
        }
      });
      return hash;
    }
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is the total WITHOUT discounts
  // WARNING - IT IS UNLIKELY YOU NEED TO USE THIS VALUE ANYWHERE. TYPICALLY WE SHOW GUEST PRICE INCL. TRNASACTIONS and discounts etc
  @computed('bill')
  totalPriceHash(bill) {
    return getBrowserPriceHash(bill.get('prices.total'));
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('bill.actualQuote.total')
  actualQuoteCurrencyHash(bill_actualQuote_total) {
    if (bill_actualQuote_total) {
      let amountHash = getBrowserPriceHash(bill_actualQuote_total);
      return amountHash;
    }
  },

    // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This is what the guest actually needs to pay in total, including discounts
  @computed('bill.guestSummaries')
  billGuestSummaries(bill_guestSummaries) {
      return bill_guestSummaries;
  },

  // By making the hash dependant on updated time stamp, this will change whenever a new version of the bill CP comes back from server.
  // This cant depend on bill.discounts.. cause its not an ember prop. it wouldnt update even if bill.x was updated
  @computed('bill', 'settings.currentCurrency')
  perGuestPriceRange(bill, currentCurrency) {
    if (bill && bill.guestSummaries) {
      let cheapest=null;
      let dearest=null;

      bill.guestSummaries.forEach((guestSummary)=> {
        let priceHash = guestSummary.price.selling;
        let preferredCurrencyArgs = {trip: this.tripService.get('currentTrip'), val: priceHash, formatPrice: false, toCurrency: currentCurrency, isText: false};
        let price = this.tripService.getTripPrice({}, preferredCurrencyArgs)
        if (cheapest==null || price < cheapest) {
          cheapest=price;
        }
        if (dearest==null || price > dearest) {
          dearest=price;
        }
      });
      let range = [];
      range.pushObject(cheapest);
      if (cheapest != dearest) {
        range.pushObject(dearest)
      }
      return range;
    }
  },

  @computed('perGuestPriceRange.[]')
  cheapestGuestPrice(perGuestPriceRange) {
    console.log('generating cheapestGuestPrice')
    return perGuestPriceRange.firstObject;
  },

  @computed('perGuestPriceRange.[]')
  dearestGuestPrice(perGuestPriceRange) {
    return perGuestPriceRange.lastObject;
  }


});
