import Route from '@ember/routing/route';
import {
  trackEvent,
  generateMeta,
  getCategoryExperiencesPromise
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';
import {
  isArray
} from '@ember/array';


export default Route.extend({

  wizardService: service('wizard'),


  model(params) {
    return this.store.peekRecord('step', params.step_id)
  },

  afterModel(model, transition) {
    console.log(model)
    let continent = this.controllerFor('wizard.step').get('selectedContinentName')
    if (model && model.id == "2" && (!continent || continent=='All')) {
      transition.abort()
      return this.transitionTo('wizard.step', "1");
    }
    this._super(...arguments);
    this.controllerFor('wizard').setStep(parseInt(model.id));
  },

  headTags() {

    let robots = 'noindex, nofollow';

    return generateMeta({
      robots
    });
  },

  catExperiencesLoaded: false,

  setupWizardArgs(controller) {
    controller.setProperties({
      goAnytime: false,
      countriesCompilation: null,
      experiencesCompilation: null,
      selectedCountryName: undefined,
      selectedMonthName: undefined,
      selectedExperienceNames: [],
      cachedSelectedMonthName: null,
      cachedSelectedCountryName: null,
      selectedContinentName: 'All',
// This plural version of continent name is needed for the continet pills component on step 2
      selectedContinentNames: ['All'],
      showAllCountries: true,
      matchingRoutes: null,
      preferedPartner: null,
      preferedYear: '2025',
    });
  },

  setupController(controller, model) {
    this._super(...arguments);

    if (this.get('wizardService.currentController') !== controller){
      this.get('wizardService').setupController({wizardStepController: controller, wizardController: this.controllerFor('wizard')});
    }

    controller.set('step', model);
    controller.set('wizardSubmission', null);

    if (!this.catExperiencesLoaded) {
      // We need to ensure cat experiences have been loaded otherwise the suggestions wont have entities to point at
      // Not doing this in the switch statement below as we need to ensure they are loaded in advance
      getCategoryExperiencesPromise(this.get('store'));
      this.set('catExperiencesLoaded', true);
    }

    switch (model.id) {
      case '1':
        this.setupWizardArgs(controller)

        break;
      case '2':

        controller.setCountries()
        break;

      case '3': // months
        break;

      case '4':
        // experiences
        controller.loadSuggestions('experiences');
        break;
      case '5':
        // with who?
        break;
      case '6':
        // form
        trackEvent('wizard:login:view');
        break;
      default:
        break;
    }
    trackEvent('wizard:step:' + model.id);

  }

});
