
import DayByDaySection from 'b5b/components/day-by-day-section/component';
import {
  computed
} from 'ember-decorators/object';
import {
isEmpty
} from '@ember/utils';
import stage from '../../../models/stage';

export default DayByDaySection.extend({
  @computed('stage', 'trip.itinerary.stages.@each.entity', 'trip.itinerary.stages.@each.dayNumberStart')
  todaysPrecedingStages(currentStage, stages) {
    let precedingStages = [];
    let currentStageFound = false;
    stages.forEach(function (stage, index) {
      currentStageFound = currentStageFound || stage == currentStage;
      // Deal with region/lodge stages that don't have any nights
      if (!currentStageFound && stage != currentStage && stage.dayNumberStart == currentStage.dayNumberStart && stage.isLodgeOrRegionStage) {
        precedingStages.clear();
      } else if (!currentStageFound && (stage.dayNumberStart + stage.numNights) == currentStage.dayNumberStart && (!stage.schedule || (stage.schedule && stage == currentStage))) {
        precedingStages.pushObject(stage);
      } else if (stage == currentStage && index == stages.length - 1) {
        // The one exception if the last location stage of the itinerary where we need to add the last location
        precedingStages.pushObject(stage);
      }

    })
    return precedingStages;

  },

});
