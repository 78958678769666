import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import tripSubRoute from 'b5b/mixins/trip-sub-route';

export default Route.extend(tripSubRoute, {

  store: service(),
  router: service(),

  model(params) {
    return params.charge_id;
  },

  afterModel(chargeOrChargeId, transition) {
    transition.abort();
    let charge = typeof chargeOrChargeId == 'string' ? this.store.peekRecord('charge', chargeOrChargeId) : chargeOrChargeId;
    if (charge && charge.state==='completed') {
      // This logic is duplicated in payment-due component and flywire-button component
      if (this.get('tripService.currentTrip.isGiftList')) {
        this.router.replaceWith('trip.index.contribute.success', this.tripService.currentTrip);
      } else {
        this.router.replaceWith('trip.index.pay.success', this.tripService.currentTrip);
      }
    } else {
      this.router.replaceWith('trip.index.pay.error', this.tripService.currentTrip);
      console.log(charge);
    }

  },

  // setupController(controller, chargeOrChargeId) {
  //   let charge = typeof chargeOrChargeId == 'string' ? this.store.peekRecord('charge', chargeOrChargeId) : chargeOrChargeId;
  //   // NB don't set trip here as it is injected into the controller.
  //   controller.setProperties({
  //     charge
  //   });
  //   trackEvent('trip:dashboard:charge-processed');

  // }


});
