import {
  inject as service
} from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({

  tripService: service('trip'),
  settings: service(),

  compute(params, namedArgs) {
    let blocks = namedArgs.blocks;
    let printableVersion = namedArgs.printableVersion;
    if (printableVersion)  {
      return blocks.reduce(function(result, block) {
        if (block.nonDayByDayBlockItems.length > 0) {
          result.push(block);
        }
        return result;
      }, []);
    } else {
      return blocks;
    }
  }

});
