import MapListing from 'b5b/components/map-listing/component';
import {
  maxTripPriceForRange,
  minTripPriceForRange,
  trackEvent
} from 'b5b/utils';


import {
  inject as service
} from '@ember/service';
import {
  task
} from 'ember-concurrency';
import {
  run
} from '@ember/runloop';
import {
  computed
} from 'ember-decorators/object';
import {
  or
} from 'ember-decorators/object/computed';

export default MapListing.extend({
  tagName: '',

  mapOnlyViewTitle: 'Trips',

  ui: service(),
  scroll: service(),
  screen: service(),
  messageBus: service(),
  fastboot: service(),
  whitelabel: service(),
  router: service(),

  xplorer: service('components/x-plorer'),

  init() {
    this._super(...arguments);

    this.get('messageBus').subscribe('pill-added', this, this.reload);
    this.get('messageBus').subscribe('pill-removed', this, this.reload);
    this.get('messageBus').subscribe('suggestions-reset', this, this.reload);
    this.get('messageBus').subscribe('suggestions-refresh', this, this.reload);
    this.get('messageBus').subscribe('my-trips-reload', this, this.reload);
  },

  reload() {
    this.get('loadTripsTask').perform();
  },

  @or('xplorer.isLoading', 'loadTripsTask.isRunning') tripsLoading: false,

  actions: {

    deleteTrip() {
      let tripToDelete = this.get('tripToDelete');
      tripToDelete.destroyRecord();
      this.set('showingShouldDeleteModal', false);
      this.set('tripToDelete', null);
    },

    showDeleteTripModal(trip) {
      this.set('showingShouldDeleteModal', true);
      this.set('tripToDelete', trip);
    },


    showTrip(tripSummary) {
      this.router.transitionTo('trip.index', tripSummary.get('friendlyId'));
    },

    setSort(sort) {
      this.set('sort', sort);
      this.set('page', 1);
      this.messageBus.publish('suggestions-refresh');
    },
  }


});
