import $ from 'jquery';
import Component from '@ember/component';
import {
  observer
} from '@ember/object';


/*
  !NOTE:

  Opening this component manually should be done via an `onclick=` event as you need to e.stopPropagation in the action,
  otherwise it closes immediately.

  eg:

  onclick={{action 'openPicker'}}

  openPicker(e) {
    e.stopPropagation();
    this.get('datePickerInstance').open();
  }

*/

export default Component.extend({
  date: null,
  tagName: 'input',
  attributeBindings: ['placeholder'],
  placeholder: "Select a date",
  format: 'dd mmm yyyy',
  momentFormat: 'DD MMM YYYY',
  disablePastDates: false,
  instance: null,

  holderContainer: '#date-picker-container',

  dateWatcher: observer('date', function() {
    let date = this.date;
    if (date) {
      this._picker.set('select', date);
    } else {
      this._picker.clear();
    }
  }),

  didInsertElement() {
    var options = {
      format: this.format,
      container: this.holderContainer,
      containerHidden: this.holderContainer,
      klass: {
        holder: 'picker__holder ' + this.holderClasses
      },
      onClose: this.onClose,
      onSet: (event) => {
        if (event.clear === null) {
          this.set('date', null)
          if (this.attrs.clearAction) {
            this.attrs.clearAction(null);
          }
        } else if (event.select) {
          var newDate = new Date(event.select);
          var previousDate = new Date(this.date);
          if (!previousDate || newDate.getTime() !== previousDate.getTime()) {
            this.set('date', newDate);
            if (this.attrs.setAction) {
              this.attrs.setAction(newDate);
            }
          }
        }
      }
    };
    if (this.disablePastDates) {
      options.disable = [{
        from: [1970, 0, 1],
        to: true
      }];
    }
    if (this.disablePastDates &&  moment(this.date).isBefore(moment())) {
      // In this situtation the date picker displays todays day instead of the actual date which is in the past.
      // The input value isnt' set so if you save, it doesn't corrupt the data but the incorrect date is shown.
      // So for now disable the input and invoke pickadate. Another possible solution might be set disablePastDates to false if the actaul date is in the past?
      this.$().val(moment(this.date).format(this.momentFormat));
      this.$().prop('disabled', true)
    } else {
      let input = this.$().pickadate(options);
      this._picker = input.pickadate('picker');
      this._picker.set('select', this.date);
      this.set('instance', this._picker);
    }
  },

  disabledWatcher: observer('disabled', function() {
    if (this.disabled) {
      this.$().prop('disabled', true)
    } else {
      this.$().prop('disabled', false)
    }
  }).on('didInsertElement'),

  onClose: function() {
    // Prevent pickadate from re-opening on focus
    $(document.activeElement).blur();
  },

  willDestroyElement() {
    if (this._picker) {
      this._picker.stop();
    }
    this._super(...arguments);
  }

});
